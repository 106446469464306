import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchReportsList = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_REPORTS`,
    });

    fetchJson(`${baseURL}/api/report/list/${moduleId}`)
      .then(data => {
        dispatch(fetchReportsSuccess(data.item));
      })
      .catch(error => {
        dispatch(fetchReportsFailure(error));
      });
  };
};

export const fetchReportsSuccess = data => {
  return {
    type: `FETCH_REPORTS_SUCCESS`,
    data,
  };
};

export const fetchReportsFailure = error => {
  return {
    type: `FETCH_REPORTS_FAILURE`,
    error,
  };
};

export const fetchReportsReset = () => {
  return {
    type: `FETCH_REPORTS_RESET`,
  };
};

export const addReportMetric = (data, moduleId) => {
  return {
    type: `ADD_REPORT_METRIC`,
    data,
    moduleId,
  };
};

export const deleteReportMetric = data => {
  return {
    type: `DELETE_REPORT_METRIC`,
    data,
  };
};

export const updateReportMetric = data => {
  return {
    type: `UPDATE_REPORT_METRIC`,
    data,
  };
};

/** MODULE */

export const addMetric = (dataItem, moduleId) => {
  return {
    type: `ADD_METRIC`,
    dataItem,
    moduleId,
  };
};

export const updateMetric = (dataItem, moduleId) => {
  return {
    type: `UPDATE_METRIC`,
    dataItem,
    moduleId,
  };
};

export const deleteMetric = (itemId, moduleId) => {
  return {
    type: `DELETE_METRIC`,
    itemId,
    moduleId,
  };
};

export const updateReportLayout = (dataItem, moduleId) => {
  return {
    type: `UPDATE_REPORT_LAYOUT`,
    dataItem,
    moduleId,
  };
};

export const updateMetricLayout = (dataItem, moduleId) => {
  return {
    type: `UPDATE_METRIC_LAYOUT`,
    dataItem,
    moduleId,
  };
};
