import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../settings';
import { useLocation } from 'react-router-dom';

import Routes from './index';
import { fetchType } from '../actions/higherorder';
import { profileGet } from '../actions/profile';

import { RootContext } from './RootContext';
import LoadingOverlay from '../components/LoadingOverlay';

const LandingPage = props => {
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    props.profileGet();
    props.fetchType('PORTALS', `/api/membership/list-entry-modules`);
    //props.fetchType('PROFILE', `/api/profile/currentuser`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.isAuthenticated) {
    history.replace(location.pathname);
  }

  const { loading } = props.profile;

  if (loading) {
    return <LoadingOverlay />;
  }

  return <Routes />;
};

const mapStateToProps = state => {
  return {
    portals: state.portals,
    projects: state.projects,
    profile: state.session.profile,
    isAuthenticated: state.session.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
      profileGet,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
