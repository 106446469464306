import React, { useEffect, useState } from 'react';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../../settings';
import _ from 'lodash';

import { fetchModule } from '../../components/Module/actions';
import { updateCustomField, createCustomField } from '../../components/CustomField/actions';

import { Text, Box, Loader, Icon, Button, Checkbox } from '@ies-ds/react-ui';

import { FileUpload, FileDelete } from '../../components/Vault/File';
import { ReferenceAdd } from '../../components/Vault/Reference';

import { getFileURL } from '../../utils';
import Chat from './components/Chat';

import Files from './components/Files';
import Links from './components/Links';
import LinkList from './components/LinkList';
import ModalViewer from './components/ModalViewer';

const SplitLinks = ({ data }) => {
  let count = {};

  data.map(obj => {
    var key = obj.resourceType;
    count[key] = (count[key] || 0) + 1;
  });

  return (
    <Box justifyContent="flex-start" alignItems="center" gap={8} style={{ flex: 'initial' }}>
      {count['VIDEO'] > 0 && (
        <Text
          as="span"
          weight="bold"
          size="small"
          color="#0f54e8"
          style={{ display: 'flex', gap: '3px', alignItems: 'center' }}
        >
          <Icon icon="video" size="xsmall" />
          {count['VIDEO']}
        </Text>
      )}

      {count['LINK'] > 0 && (
        <Text as="span" weight="bold" size="small" color="#0f54e8">
          <Icon icon="link" size="xsmall" />
          {count['LINK'] + count['DOCUMENT'] + count['FILE']}
        </Text>
      )}
    </Box>
  );
};

const Task = ({ title, module, ...props }) => {
  const { stepId } = useParams();
  const [open, setOpen] = useState(false);
  const { path, url } = useRouteMatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.fetchModule(module.id);

    return () => {
      {
        /*clean*/
      }
    };
  }, [module.id]);

  function checkStatus(module) {
    if (module.isFetching) {
      return;
    }

    let { values } = module.data.customFields.checklist_status;
    let status = false;

    if (values.length == 0) {
      return status;
    } else if (values[0].values.stringval == 'completed') {
      status = true;
    } else {
      status = false;
    }

    return status;
  }

  function updateCheckStatus(module) {
    if (module.isFetching) {
      return;
    }

    setLoading(true);

    let { checklist_status } = module.data.customFields;

    const apiURL =
      checklist_status.links.length != 0
        ? checklist_status.links.add_checklist_status_value.url
        : checklist_status.values[0].links.edit.url;

    const request = new Request(`${baseURL}${apiURL}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        updateStatus(data);
        console.log(data);
      })
      .catch(function(error) {});
  }

  const updateStatus = data => {
    setLoading(true);

    const formData = new FormData();
    formData.append('_method', data.elements[0].value);
    formData.append(
      'option',
      data.elements[1].options[0].selected ? data.elements[1].options[1].value : data.elements[1].options[0].value,
    );
    formData.append('_token', data.elements[2].value);

    const request = new Request(`${baseURL}${data.action}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        props.updateCustomField(data.item);
        setLoading(false);
      })
      .catch(function(error) {});
  };

  return (
    <>
      <div
        style={{
          padding: '8px',
          margin: '4px 0 0 0',
          background: '#e8edf3',

          //borderTop: '1px solid #e6e6e6',
        }}
      >
        <Box gap="8">
          <div>
            {props.isFetching || loading ? (
              <Loader size="xsmall" type="dots" />
            ) : (
              <Checkbox checked={checkStatus(props)} onClick={() => updateCheckStatus(props)} />
            )}
          </div>

          <div style={{ width: '100%' }}>
            <Box
              justifyContent="space-between"
              gap={16}
              alignItems="center"
              onClick={() => (open ? setOpen(false) : setOpen(true))}
            >
              <Text
                as="span"
                weight="bold"
                size="xsmall"
                style={checkStatus(props) ? { textDecoration: 'line-through' } : null}
              >
                {module.name}{' '}
              </Text>
            </Box>
            <Text as="div" color="light" size="xsmall">
              {module.description}
            </Text>
          </div>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { module } = ownProps;

  const { entities } = state;
  const { isFetching, data } = entities.modules[module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    entities,
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      updateCustomField,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Task);
