import React, { useEffect } from 'react';
import { useParams, Switch, useRouteMatch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchType } from '../actions/higherorder';
import { ThemeContainer, BaseTheme, Layout, Menu, Heading, Text, Box, Icon, Loader } from '@ies-ds/react-ui';
import ListMenu from '../components/ListMenu';
import IconButton from '../ies-ui/IconButton';
import ModuleAdd from '../components/Module/ModuleAdd';
import Project from './project';
import AuthenticatedRoute from '../routes/AuthenticatedRoute';

import { fetchPlatformSkeleton, fetchPlatformSkeletonReset } from '../components/Module/actions';

const EmptyProject = () => {
  return (
    <>
      <Text>Please select an option from the menu</Text>
    </>
  );
};

const Projects = props => {
  const { id } = useParams();
  let { path } = useRouteMatch();

  useEffect(() => {
    props.fetchPlatformSkeleton(id);
    return () => {
      props.fetchPlatformSkeletonReset(id);
    };
  }, [id]);

  return (
    <>
        <Layout.Side style={{background: '#fff'}}>
          <Menu.Container style={{ padding: 0 }}>
            <div style={{ padding: '0px' }}>
              <ListMenu moduleId={id} />
            </div>
          </Menu.Container>
        </Layout.Side>
        <Layout.Content>
          {props.platform.loading ? (
            <>
              <Loader type="dots"></Loader> Loading Platform...
            </>
          ) : (
            <Switch>
              <Route exact path={`${path}`}>
                <EmptyProject />
              </Route>
              <Route render={({ match }) => <Project match={match} />} path={`${path}/:pid`} />
            </Switch>
          )}
        </Layout.Content>
    </>
  );
};

const mapStateToProps = state => {
  return {
    projects: state.projects,
    portal: state.portal.data.item,
    platform: state.session.platform,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
