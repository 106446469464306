import React, { useState } from 'react';
import { Button, Modal, Loader, Text, Heading, Popover } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteTypeFile, fetchJson, checkStatus } from '../../../actions/higherorder';
import { baseURL } from '../../../settings';
import styled from 'styled-components';

import { deleteVaultItem } from '../actions';

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: blue;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const VaultDelete = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const { id } = module;

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
    setSending(false);
    setSuccess(true);
    setMessage('');
  };
  const sendHandler = () => {
    setMessage('');
    setSending(true);
    fetchJson(`${baseURL}${item.links.delete.url}`, {
      method: 'DELETE',
    })
      .then(data => {
        closeHandler();
        props.deleteVaultItem(item.id, id, 'files');
      })
      .catch(error => {
        console.log(error);
        setSending(false);
        setSuccess(false);
        setLoading(false);
        setMessage('There has been error deleting the file. Please try again');
      });
  };

  if (true) {
    return (
      <>
        {module.locked ? (
          <Popover trigger={trigger}>
            <p>File is locked </p>
          </Popover>
        ) : (
          <div onClick={() => openHandler()}>{trigger}</div>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Delete File</h2>

          {loading ? (
            <Loader />
          ) : (
            <>
              <Text>Do you want to delete the following file?</Text>
              <Heading size="4">{item.fileName}</Heading>
              {sending && (
                <Text style={{ color: 'green' }}>
                  <Loader type="dots" /> Deleting File{' '}
                </Text>
              )}
              {success ? <StyledLabel>{message}</StyledLabel> : <StyledErrorLabel>{message}</StyledErrorLabel>}

              <br />
              <br />
              <Button color="primary" type="submit" size="small" onClick={() => sendHandler()}>
                Yes, Delete
              </Button>
              <Button size="small" onClick={() => closeHandler()}>
                No, Cancel
              </Button>
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteVaultItem,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VaultDelete);
