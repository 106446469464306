import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomField } from './actions';

import BuildForm from '../BuildForm';
import { checkNodeExists } from '../../utils';

import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const EditCustomField = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateCustomField(data.item, module.id);
    closeHandler();
  };

  if (checkNodeExists(item.links) && typeof item.links != 'undefined') {
    if (item.links.edit) {
      return (
        <>
          {trigger ? (
            <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
              {trigger}
            </span>
          ) : (
            <StyledLink size="small" onClick={() => openHandler()}>
              {item.links.edit.label}
            </StyledLink>
          )}
          <Modal isOpen={modalOpen}>
            <a
              style={{ position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', color: '#a7a7a7' }}
              onClick={() => closeHandler()}
            >
              x
            </a>
            <h2> Edit [{item.links.edit.label}]</h2>
            {error}
            {loading ? (
              <Loader />
            ) : (
              <>
                <BuildForm
                  apiURL={`${item.links.edit.url}`}
                  onSuccess={onSuccess}
                  completeAction={() => closeHandler()}
                  submitBtnLabel={'Update'}
                  completeBtnLabel={'Close'}
                  formDataMethodFix={false}
                />
              </>
            )}
          </Modal>
        </>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCustomField,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomField);
