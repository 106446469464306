import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateModule } from '../components/Module/actions';
import BuildForm from '../components/BuildForm';

import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const GetLink = ({ link, module, data, trigger, children, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { id } = module;

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateModule(id);
    closeHandler();
  };

  if (module.links.edit) {
    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <StyledLink size="small" onClick={() => openHandler()}>
            {data.label}
          </StyledLink>
        )}
        <Modal isOpen={modalOpen}>
          <a
            style={{ position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', color: '#a7a7a7' }}
            onClick={() => closeHandler()}
          >
            x
          </a>
          <h2> Edit [{module.links.edit.label}]</h2>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`${data.url}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Update'}
                completeBtnLabel={'Close'}
                formDataMethodFix={false}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GetLink);
