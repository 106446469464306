import React from 'react';
import styled from 'styled-components';
import Radar from './democharts/radar';
import { Heading } from '@ies-ds/react-ui';

const CFCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 25px;
  padding: 16px;
  h1 {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ReportMetricTypeText = ({ data, module }) => {
  const dataArry = [];

  data.groupedValues.map(d => dataArry.push({ name: d.moduleName, cost: d.total }));

  return (
    <>
      <SimpleCard item={data} module={module} size={3} />
    </>
  );
};

export default ReportMetricTypeText;

const SimpleCard = ({ item, module, size }) => {
  return (
    <CFCard size={size}>
      <div>
        {item.values.map(item => {
          if (item.module_id == module.id)
            return (
              <Heading className="link" size={1}>
                {item.stringval}
              </Heading>
            );
        })}
      </div>
    </CFCard>
  );
};
