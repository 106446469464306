export const createMembership = (dataItem, moduleId) => {
  return {
    type: `CREATE_MEMBERSHIP`,
    dataItem,
    moduleId,
  };
};

export const updateMembership = (dataItem, moduleId) => {
  return {
    type: `UPDATE_MEMBERSHIP`,
    dataItem,
    moduleId,
  };
};

export const deleteMembership = (itemId, moduleId) => {
  return {
    type: `DELETE_MEMBERSHIP`,
    itemId,
    moduleId,
  };
};
