import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchCommentsList = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_COMMENTS`,
    });

    fetchJson(`${baseURL}/api/discussion/list/${moduleId}`)
      .then(data => {
        dispatch(fetchCommentSuccess(data.list));
      })
      .catch(error => {
        dispatch(fetchCommentFailure(error));
      });
  };
};

export const refeshCommentsList = moduleId => {
  return dispatch => {
    dispatch({
      type: `REFESH_COMMENTS`,
    });

    fetchJson(`${baseURL}/api/discussion/list/${moduleId}`)
      .then(data => {
        dispatch(fetchCommentSuccess(data.list));
      })
      .catch(error => {
        dispatch(fetchCommentFailure(error));
      });
  };
};

export const fetchCommentSuccess = data => {
  return {
    type: `FETCH_COMMENTS_SUCCESS`,
    data,
  };
};

export const fetchCommentFailure = error => {
  return {
    type: `FETCH_COMMENTS_FAILURE`,
    error,
  };
};

export const fetchCommentReset = () => {
  return {
    type: `FETCH_COMMENTS_RESET`,
  };
};

/**** REDUCE TO MODULES */

export const insertComment = (dataItem, moduleId) => {
  return {
    type: `CREATE_COMMENT`,
    dataItem,
    moduleId,
  };
};

export const updateComment = (dataItem, moduleId) => {
  return {
    type: `UPDATE_COMMENT`,
    dataItem,
    moduleId,
  };
};

export const deleteComment = (itemId, moduleId) => {
  return {
    type: `DELETE_COMMENT`,
    itemId,
    moduleId,
  };
};

/**** */
export const updateCommentList = data => {
  return {
    type: `UPDATE_COMMENT_LIST`,
    data,
  };
};
