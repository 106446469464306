import Axios from 'axios';
import { baseURL } from '../settings';

const configHeader = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
};

export function fetchJson(url, options) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };

  if (options && options.headers) {
    headers = { ...options.headers, ...headers };
    delete options.headers;
  }
  return fetch(
    `${url}`,
    Object.assign(
      {
        credentials: 'include',
        headers: headers,
      },
      options,
    ),
  )
    .then(checkStatus)
    .then(r => r.json());
}

export function postForm(url, method = 'GET', formdata = null) {
  return fetch(`${url}`, {
    method: method,
    body: formdata,
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    credentials: 'include',
  })
    .then(checkStatus)
    .then(r => r.json());
}

/*
export function fetchJson(url, options) {
  return fetch(url, Object.assign({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: "Bearer " + localStorage.getItem('token')
    },
    credentials: 'include',
  }, options))
      .then(checkStatus)
      .then(r=>r.json()
      )

}
*/

export function imageUrlFetch(url) {
  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
    .then(r => r.json())
    .then(data => {
      alert(r.url);
      if (r.statusText === 'OK') {
        return r.url;
      }
    });
}

export function checkStatus(response) {
  if (response.status === 302) {
  }
  if (response.status >= 200 && response.status < 400 && response.status !== 302) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export const fetchType = (typeConstant, apiURL) => {
  return dispatch => {
    dispatch({
      type: `FETCH_${typeConstant}_REQUESTED`,
    });

    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        dispatch(fetchTypeSuccess(typeConstant, data));
      })
      .catch(error => {
        dispatch(fetchTypeFailure(typeConstant, error));
      });
  };
};

export const fetchTypeSuccess = (typeConstant, data) => {
  return {
    type: `FETCH_${typeConstant}_SUCCESS`,
    data,
  };
};

export const fetchTypeFailure = (typeConstant, error) => {
  return {
    type: `FETCH_${typeConstant}_FAILURE`,
    error,
  };
};

export const fetchTypeReset = typeConstant => {
  return {
    type: `FETCH_${typeConstant}_RESET`,
  };
};

export const fetchTypeAppend = (typeConstant, data) => {
  return {
    type: `FETCH_${typeConstant}_APPEND`,
    data,
  };
};

/// V2
export const fetchTypeDelete = (typeConstant, data) => {
  return {
    type: `FETCH_${typeConstant.toUpperCase()}_DELETE`,
    data,
  };
};
/// V2
export const fetchTypeInsert = (typeConstant, data) => {
  return {
    type: `FETCH_${typeConstant.toUpperCase()}_INSERT`,
    data,
  };
};

export const fetchTypeEdit = (typeConstant, data) => {
  return {
    type: `EDIT_${typeConstant}`,
    data,
  };
};

export const fetchTypeEditList = (typeConstant, data) => {
  return {
    type: `EDIT_${typeConstant}_LISTITEM`,
    data,
  };
};

export const addDeliverable = (typeConstant, data, moduleid) => {
  const dataClean = typeConstant.toUpperCase();
  const dataClean2 = `${dataClean}S`;

  return {
    type: `UPDATE_${dataClean2}_DELVIERABLE`,
    data,
    moduleid,
  };
};

export const addBrandLogo = (typeConstant, data, moduleid) => {
  const dataClean = typeConstant.toUpperCase();
  const dataClean2 = `${dataClean}S`;

  return {
    type: `UPDATE_${dataClean2}_LOGO`,
    data,
    moduleid,
  };
};

/**
 * Commments
 *
 */

export const insertTypeComment = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_COMMENT`,
    data,
  };
};

export const deleteTypeComment = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_COMMENT`,
    data,
  };
};

export const updateTypeComment = (typeConstant, data, commentId) => {
  return {
    type: `UPDATE_${typeConstant.toUpperCase()}_COMMENT`,
    data,
    commentId,
  };
};

/**
 * Articles
 *
 */

export const insertTypeArticle = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_ARTICLE`,
    data,
  };
};

export const deleteTypeArticle = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_ARTICLE`,
    data,
  };
};

export const updateTypeArticle = (typeConstant, data, articleId) => {
  return {
    type: `UPDATE_${typeConstant.toUpperCase()}_ARTICLE`,
    data,
    articleId,
  };
};

/*
 * FILE
 *
 */

export const insertTypeFile = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_FILE`,
    data,
  };
};

export const deleteTypeFile = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_FILE`,
    data,
  };
};

export const updateTypeFile = (typeConstant, data, fileId) => {
  return {
    type: `UPDATE_${typeConstant.toUpperCase()}_FILE`,
    data,
    fileId,
  };
};

/*
 * DELIVERABLE
 *
 */

export const insertTypeDeliverable = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_DELIVERABLE`,
    data,
  };
};

export const deleteTypeDeliverable = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_DELIVERABLE`,
    data,
  };
};

export const insertTypeListDeliverables = (typeConstant, data, moduleid) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_LISTITEM_DELIVERABLE`,
    data,
    moduleid,
  };
};

/*
 * MEMBERSHIP
 *
 */

export const insertTypeMembership = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const updateTypeMembership = (typeConstant, data) => {
  return {
    type: `UPDATE_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const deleteTypeeMembership = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const insertTypeListeMembership = (typeConstant, data, moduleid) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_LISTITEM_MEMBERSHIP`,
    data,
    moduleid,
  };
};

/** ******************TESTS********************************* */

export const test = typeConstant => {
  return {
    type: `TEST_${typeConstant}_TEST`,
  };
};
