import React from 'react';
import { Icon } from '@ies-ds/react-ui';
import styled from 'styled-components';
import { string, number } from 'prop-types';

const StyledIconButton = styled.div`
  border-radius: 3px;
  background: none;
  padding: 8px;
  display: inline-flex;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const IconButton = ({ icon, size, ...props }) => {
  return (
    <StyledIconButton {...props}>
      <Icon size={size} icon={icon} />
    </StyledIconButton>
  );
};

IconButton.propTypes = {
  icon: string,
  size: string,
};

IconButton.defaultProps = {
  icon: 'default',
  size: '24',
};

export default IconButton;
