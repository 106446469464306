import React, { useState } from 'react';
import { Modal, Loader } from '@ies-ds/react-ui';
import BuildForm from '../../../components/BuildForm';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMetric } from '../actions';

const MetricAdd = ({ module, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.addMetric(data.item, module.id);
    closeHandler(false);
  };

  if (true) {
    return (
      <>
        {trigger ? (
          <span onClick={() => openHandler()}>{trigger}</span>
        ) : (
          <span size="small" onClick={() => openHandler()}>
            Add Metric
          </span>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Add new metric</h2>
          <p>Complete the form below to add a new metric. </p>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`/api/report/metric/add/${module.id}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={`Add Metric`}
                completeBtnLabel={'Close'}
                formDataMethodFix={true}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addMetric,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MetricAdd);
