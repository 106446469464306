import React, { useState, useEffect } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { baseURL } from '../../settings';
import { Text, Loader, Heading, Button, Box } from '@ies-ds/react-ui';
import IconButton from '../../ies-ui/IconButton';
import moment from 'moment';

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const content = {
  entityMap: {},
  blocks: [
    {
      key: '637gr',
      text: 'Initialized from content state.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};

const ArticleView = ({ returnURL, moduleId }) => {
  let { path, url } = useRouteMatch();
  const { articleId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    fetchArticle(`${baseURL}/api/article/view/${moduleId}/${articleId}`);

    return () => {
      /*cleanup*/
    };
  }, [articleId]);

  const fetchArticle = apiURL => {
    fetch(`${apiURL}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setLoading(false);
        updateContent(data.item.content);
        setData(data.item);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const updateContent = data => {
    const output = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
    setEditorState(output);
  };

  return (
    <>
      <p>
        <Link to={`${returnURL}`}>
          <Button size={'xsmall'}>Back to Knowledge</Button>
        </Link>
      </p>
      {loading ? (
        <Loader type="dots" />
      ) : (
        <>
          <Box>
            <Heading size={'3'} appearance="h2" noMargin>
              {data.title}
            </Heading>
            <Link to={`${url}/edit`}>
              <IconButton size="16" icon="edit" title={'Edit'} />
            </Link>
          </Box>
          <Text size={'small'}>
            Created By: {data.createdBy} on {moment(data.created).format('Do MMM YYYY @ hh:mm')}
          </Text>

          <div
            style={{ background: '#fff', margin: '8px 0', padding: '8px 16px', border: '1px solid rgb(225, 228, 234)' }}
          >
            <Editor editorState={editorState} readOnly toolbarHidden />
          </div>
          <Link to={`${returnURL}`}>
            <Button size={'xsmall'}>Back to Knowledge</Button>
          </Link>
        </>
      )}
    </>
  );
};

export default ArticleView;
