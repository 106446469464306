import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { baseURL } from '../settings';
import { Loader, Text, Heading, Avatar } from '@ies-ds/react-ui';
import { fetchJson } from '../actions/higherorder';
import { getImageURL } from '../utils';

import moment from 'moment';

import DataTable from '../components/DataTable';

const FeedList = ({ id }) => {
  // const posts = Redux.useSelector(state => state.posts);

  /*const tags = React.useMemo(() => {
    return datalist;
  }, [datalist]);*/
  const [feedlist, setFeedlist] = useState([]);

  useEffect(() => {
    fetchList(id);
  }, []);

  const fetchList = id => {
    fetchJson(`${baseURL}/api/feed/list/${id}`)
      .then(data => {
        setFeedlist(data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ cell: { value } }) => <>{moment(value.created).fromNow()}</>,
      },

      {
        Header: 'Owner',
        accessor: 'owner',
        width: 50,
        Cell: ({ cell: { value } }) => (
          <div style={{ width: '50px' }}>
            <Avatar
              size={'small'}
              src={`${getImageURL(`${baseURL}/api/avatar/view/${value.createdById}`)}`}
              title={`${value.createdBy}`}
            />
          </div>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
      },

      {
        Header: 'Operation',
        accessor: 'operation',
        Cell: ({ cell: { value } }) => (
          <>
            {value.operation} {value.category}
          </>
        ),
      },

      {
        Header: 'Module',
        accessor: 'module',
      },
    ],
    [],
  );

  function tableData() {
    if (feedlist.list === undefined || feedlist.list.length == 0) {
      return [];
    } else {
      return feedlist.list.map(d => {
        return {
          id: d.id,
          operation: d,
          owner: d,
          description: d.description,
          created: d,
          module: d.module,
        };
      });
    }
  }

  const dataT = tableData();

  if (feedlist.list === undefined || feedlist.list.length == 0) {
    return <Loader type="dots" />;
  }

  return (
    <>
      <DataTable columns={columns} data={dataT} />
    </>
  );
};

export default React.memo(FeedList);
