import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Heading, Menu, Avatar, Loader, Text, Box, Button, Icon } from '@ies-ds/react-ui';
import { fetchType, fetchJson, fetchTypeSuccess } from '../actions/higherorder';

import { fetchModule } from './Module/actions';

import { StyledNavLink } from './shared';

import { ModuleEdit, ModuleDelete, ModuleAdd } from './Module';

import { checkNodeExists, getImageURL } from '../utils';

import styled from 'styled-components';
import pluralize from 'pluralize';

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ToggleHeader = styled(Box)`
  padding: 12px 8px 8px 8px;
  width: 280px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledNavItem = styled(Menu.Item)`
  height: 32px;
  /* width: 100%;*/
  .actions {
    display: none;
  }
  &:hover {
    .actions {
      display: flex;
    }
  }
`;

const GetAvatar = ({ moduleId }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData(`/api/brand/find/${moduleId}`);
  }, []);

  const fetchData = apiURL => {
    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        setData(data.item);
      })
      .catch(function(error) {});
  };

  return <Avatar size="small" type="square" src={`${getImageURL(data.logo)}`} />;
};

const actionLinks = data => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].access) {
            case 'edit':
              //  return <ModuleEdit module={data} trigger={<Button size={'xsmall'}>E</Button>} />;
              return null;
            case 'delete':
              return <ModuleDelete module={data} trigger={<Button size={'xsmall'}>X</Button>} />;
          }
        });
  }
};

const NavItem = ({ item }) => {
  return (
    <Box style={{ margin: '2px 0' }}>
      <StyledNavLink to={`/portal/${item.parentId}/projects/${item.id}`} style={{ width: '280px' }}>
        <StyledNavItem key={item.id} style={{ padding: '20px 24px' }}>
          <Box alignItems="center" style={{ width: '210px' }} gap={'4'}>
            {/*<Avatar size="small" type="square" src={`${baseURL}/api/brand/logo/${item.brand.id}`} />*/}

            <GetAvatar moduleId={item.id} />

            <div style={{ width: '80%' }}>
              <EllipsisText size="small" title={item.name}>
                {item.name}
              </EllipsisText>
            </div>
          </Box>

          <div className="actions">
            {actionLinks(item)}
            {/* <ModuleActionsLinks item={item} moduleType={'PROJECT'} />*/}
          </div>
        </StyledNavItem>
      </StyledNavLink>
    </Box>
  );
};

const MenuGroup = ({ data, group }) => {
  const [visible, setVisible] = useState(true);

  const grouped = _.mapValues(_.groupBy(data.children, 'type'), clist => clist.map(item => _.omit(item, 'type')));
  const groupKey = grouped[group.type];
  //const groupHeading = pluralize(data.links[group].label.replace('Add ', ''));
  const groupHeading = pluralize(group.typeLabel);
  const groupEmpty = typeof groupKey === 'undefined' ? true : false;

  const toggleVisibility = () => {
    visible ? setVisible(false) : setVisible(true);
  };

  return (
    <p>
      <ToggleHeader justifyContent={'space-between'} onClick={toggleVisibility}>
        <Heading size={4} noMargin>
          <span style={{ marginRight: '4px', width: '12px', display: 'inline-block', color: '#999' }}>
            {visible ? <>&#x25BE;</> : <>&#x25B8;</>}
          </span>
          {groupHeading}
        </Heading>
        <Text size={'xsmall'} style={{ flex: 1, margin: ' 0 0 0 5px' }}>
          ({groupEmpty ? 0 : groupKey.length})
        </Text>
        <ModuleAdd module={data} type={[`add_${group.type}`]} trigger={<Button size={'xsmall'}>Add</Button>} />
      </ToggleHeader>
      {visible ? (
        <>
          {!groupEmpty
            ? groupKey.map(item => {
                return <NavItem item={item} />;
              })
            : null}
        </>
      ) : null}
    </p>
  );
};

const MenuList = ({ data }) => {
  const grouped = _.mapValues(_.groupBy(data.children, 'type'));
  return Object.keys(grouped).map(function(key) {
    return <MenuGroup data={data} group={grouped[key][0]} />;
  });

  return typeof data.links == 'undefined'
    ? null
    : Object.keys(data.links).map(function(key) {
        switch (data.links[key].access) {
          case 'create':
            return <MenuGroup data={data} group={key} />;
        }
      });
};

const ListMenu = ({ moduleId, ...props }) => {
  useEffect(() => {
    props.fetchModule(moduleId);
  }, [moduleId]);

  return (
    <div style={{ width: '280px' }}>
      {props.isFetching ? (
        <Loader type="dots" />
      ) : (
        <>
          <MenuList data={props.data} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { moduleId } = ownProps;
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: children } = entities.modules[moduleId] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    lastUpdated,

    projects: state.projects,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchType,
      fetchTypeSuccess,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListMenu);
