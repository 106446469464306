import React from 'react';
import { Heading, Text, Box } from '@ies-ds/react-ui';

const StatusIndicator = ({ name, component }) => {
  const status = typeof component == 'undefined';

  return (
    <>
      <tr>
        <td style={{ width: '30px' }}>
          <div
            style={{
              background: status ? 'red' : 'green',
              color: '#fff',
              display: 'inline-block',
              width: '30px',
              fontSize: '14px',
              padding: '5px;',
              textAlign: 'center',
            }}
          >
            {status ? 'NO' : 'YES'}
          </div>
        </td>
        <td>{name}</td>
      </tr>

      {!component ? null : (
        <tr>
          <td colSpan="2">
            <ul>
              {Object.keys(component.links).map(function(key) {
                return (
                  <li key={component.id}>
                    <div style={{ width: '60px', display: 'inline-block' }}> {component.links[key].access}: </div>{' '}
                    <div style={{ padding: '0 8px', display: 'inline-block' }}> {component.links[key].label} </div> -{' '}
                    <div style={{ padding: '0 8px', display: 'inline-block' }}> {component.links[key].url}</div>
                    <div style={{ display: 'inline-block' }}> [{key}] </div>
                  </li>
                );
              })}
            </ul>
          </td>
        </tr>
      )}
    </>
  );
};

const ModuleStatus = ({ module }) => {
  const { brand, deliverable, discussion, library, report, vault } = module;

  return (
    <div
      style={{
        background: '#fff',
        margin: '32px 0',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #eee',
      }}
    >
      <Heading size={3}> Components for {module.name}</Heading>
      <table>
        <StatusIndicator name={'BRAND'} component={brand} />
        <StatusIndicator name={'DELIVERABLE'} component={deliverable} />
        <StatusIndicator name={'DISCUSSION'} component={discussion} />
        <StatusIndicator name={'LIBRARY'} component={library} />
        <StatusIndicator name={'REPORT'} component={report} />
        <StatusIndicator name={'VAULT'} component={vault} />
      </table>
    </div>
  );
};

export default ModuleStatus;
