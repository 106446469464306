import React from 'react';
import { Avatar, Popover, Box, Text } from '@ies-ds/react-ui';
import { NavLink, useParams, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import UpdateMembership from './UpdateMembership';
import { baseURL } from '../../settings';

import { MembershipEdit } from './';

import { DeliverableAdd, DeliverableEdit } from '../../components/Deliverable';
import { imageUrlFetch } from '../../actions/higherorder';

import { getImageURL } from '../../utils';

import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  border: 0px solid #4d58e8;
  &:hover {
    border: 2px solid #4d58e8;
    cursor: pointer;
    transition: 0s border ease-in;
  }
`;

const Editor = ({ module, data, admin, membership, membershipId }) => {
  const { id } = useParams();

  const permission = {
    viewer: { label: 'Viewer', letter: 'V', color: '#f52598' },
    editor: { label: 'Editor', letter: 'E', color: '#1000ff' },
    admin: { label: 'Admin', letter: 'V', color: '#f52598' },
    superadmin: { label: 'SuperAdmin', letter: 'E', color: '#1000ff' },
  };

  const pemLvl = () => {
    return admin
      ? membership
        ? 'Admin'
        : 'Super Admin'
      : membership && data.allowView
      ? data.allowEdit
        ? 'Editor'
        : 'Viewer'
      : null;
  };

  return (
    <div style={{ position: 'relative' }}>
      {membership && data.allowView ? (
        <div
          style={{
            width: '16px',
            height: '16px',
            background: data.allowEdit ? '#1000ff' : '#f52598',
            borderRadius: '100%',
            position: 'absolute',
            border: '2px solid #fff',
            top: '19px',
            left: '6px',
            fontSize: '9px',
            fontWeight: '900',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: '#fff',
          }}
        >
          {data.allowEdit ? 'E' : 'V'}
        </div>
      ) : null}
      {admin ? (
        <div
          style={{
            width: '16px',
            height: '16px',
            background: membership ? '#08a966' : 'black',
            borderRadius: '100%',
            position: 'absolute',
            border: '2px solid #fff',
            top: '19px',
            left: '6px',
            fontSize: '9px',
            fontWeight: '900',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: '#fff',
          }}
        >
          {data.moduleId == id ? 'S' : 'A'}
        </div>
      ) : null}
      {membershipId ? (
        <>
          {module.links.edit && module.links.delete ? (
            <StyledAvatar
              size="small"
              src={getImageURL(`${baseURL}/api/avatar/view/${data.userId}`)}
              title={`${pemLvl()}: ${data.userName} [${data.moduleId}:${data.userId}]`}
            />
          ) : (
            <Avatar
              size="small"
              src={getImageURL(`${baseURL}/api/avatar/view/${data.userId}`)}
              title={`${pemLvl()}: ${data.userName} [${data.moduleId}:${data.userId}]`}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

const MemberList = ({ module, moduleId, data, ...props }) => {
  if (typeof data === 'undefined') {
    return null;
  }

  return (
    <Box {...props} justifyContent={'flex-end'}>
      {data.map(item => (
        <>
          {module.links.edit && module.links.delete ? (
            <MembershipEdit
              module={module}
              userId={item.userId}
              moduleId={moduleId}
              membershipId={item.id}
              trigger={
                <Editor
                  module={module}
                  data={item}
                  admin={item.admin}
                  membershipId={item.allowView}
                  membership={item.moduleId == moduleId ? true : null}
                />
              }
            />
          ) : (
            <Editor
              module={module}
              data={item}
              admin={item.admin}
              membershipId={item.allowView}
              membership={item.moduleId == moduleId ? true : null}
            />
          )}

          {/*<Popover
            trigger={
              <Editor
                data={item}
                admin={item.admin}
                membershipId={item.allowView}
                membership={item.moduleId == moduleId ? true : null}
              />
            }
            style={{ width: '400px' }}
          >
            <div style={{ padding: '16px' }}>
              <Box>
                <Avatar size="xlarge" src={`${baseURL}/api/avatar/view/${item.userId}`} alt={`${item.name}`} />

                <div style={{ flex: 1 }}>
                  <div>
                    <h3 style={{ marginBottom: '0 !important', color: '#333' }}>
                      {item.userName} {item.admin ? '(Admin)' : null}
                    </h3>
                    <span style={{ marginTop: '0 !important', color: '#333' }}>{item.role}</span> <br />
                    <span style={{ marginTop: '5px !important', color: '#333' }}>{item.userEmail}</span>
                  </div>
                </div>
              </Box>
              <h3>{item.organisation}</h3>
              <Text size="xsmall">
                CREATE:{' '}
                <strong>
                  {' '}
                  {item.allowCreate ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | VIEW:{' '}
                <strong>
                  {' '}
                  {item.allowView ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | EDIT:{' '}
                <strong>
                  {' '}
                  {item.allowEdit ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | DELETE:{' '}
                <strong>
                  {' '}
                  {item.allowDelete ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                <br />[ moduleID:{moduleId} | membershipID: {item.id} | membershipModuleID: {item.moduleId} ]
              </Text>
              <UpdateMembership membership={item} moduleId={item.moduleId} membershipId={item.id} />
            </div>
                  </Popover>*/}
        </>
      ))}
    </Box>
  );
};

export default MemberList;
