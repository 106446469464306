import axios from 'axios';
import * as actionTypes from './actionTypes';
import { baseURL } from '../settings';
import { checkStatus, fetchJson } from '../actions/higherorder';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const receiveLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const logout = () => {
  return dispatch => {
    fetch(`${baseURL}/logout`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        dispatch(receiveLogout());
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(receiveLogout());
    }, expirationTime * 1000);
  };
};

export const auth = creds => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      username: creds.username,
      password: creds.password,
      //returnSecureToken: true
    };
    axios
      .post(`${baseURL}/json/login`, authData)
      .then(data => {
        const expirationDate = new Date(new Date().getTime() + data.data.expiresIn * 1000);
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(data.data.token));
        dispatch(checkAuthTimeout(data.data.expiresIn));
      })
      .catch(function(error) {
        console.log(error);
        dispatch(authFail(error.response.data.error));
      });
  };
};

export const forgotPassword = data => {
  return axios.post(`${baseURL}/json/forgot-password`, data);
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(receiveLogout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(receiveLogout());
      } else {
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
      }
    }
  };
};
