import React, { useState } from 'react';
import { Button, Modal, Loader, Text, Heading } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteTypeFile, updateTypeFile, fetchJson } from '../../../actions/higherorder';
import { baseURL } from '../../../settings';
import styled from 'styled-components';
import IconButton from '../../../ies-ui/IconButton';

const StyledLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: blue;
  }
  
`;

const StyledErrorLabel = styled.span`
  font-size: 16px;

  color: red;
`;

const FileLock = ({ module, item, moduleType, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = module;

  const closeHandler = () => {
    setModalopen(false);
    setMessage('');
  };
  const sendHandler = url => {
    setLoading(true);
    setMessage('');
    fetchJson(`${baseURL}${url}`, {
      method: 'PATCH',
    })
      .then(data => {
        setSuccess(true);
        setLoading(false);
        if (data.item.locked) {
          setMessage('File is locked');
        } else {
          setMessage('File is unlocked');
        }
        // setModalopen(true);
        props.updateTypeFile(moduleType ? moduleType : module.type, data.item, data.item.id);
      })
      .catch(error => {
        setSuccess(false);
        setMessage(error.message);
        setModalopen(true);
      });
  };

  if (true) {
    return (
      <>
        {loading ? (
          <Loader size="xsmall" type="dots" />
        ) : item.locked ? (
          <div onClick={() => sendHandler(`/api/vault/unlock-file/${module.id}/${item.id}`)}>
            <IconButton size="16" icon="lock" title="Unlock" />
          </div>
        ) : (
          <div onClick={() => sendHandler(`/api/vault/lock-file/${module.id}/${item.id}`)}>
            <IconButton size="16" icon="unlock" title="Lock" />
          </div>
        )}

        <Modal isOpen={modalOpen}>
          {success ? <StyledLabel>SUCCESS</StyledLabel> : <StyledErrorLabel>ERROR</StyledErrorLabel>}

          <br />
          <Text>{success ? <p>{message}</p> : <StyledErrorLabel>{message}</StyledErrorLabel>}</Text>
          <br />
          <Text>
            {' '}
            <Button size="small" onClick={() => closeHandler()}>
              Ok
            </Button>
          </Text>
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTypeFile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FileLock);
