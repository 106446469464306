import { combineReducers } from 'redux';
import modules from './modules';
import comments from './comments';
import reports from './reports';
import vault from './vault';
import deliverable from './deliverable';
import articles from './articles';
import brand from './brand';

export const entities = combineReducers({
  modules: modules,
  brand,
  comments,
  reports,
  vault,
  deliverable,
  articles,
});
