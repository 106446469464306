import React, { useState, useEffect } from 'react';
import { baseURL } from '../../settings';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTypeMembership, insertTypeListeMembership } from '../../actions/membership';

import { TextInput, Checkbox } from '../../ies-ui/form';

const formData = [
  {
    type: 'text',
    value: 'Role',
    reg: { required: true, minLength: 10 },
  },
  {
    type: 'checkbox',
    value: 'allowEdit',
    label: 'Edit',
  },
  {
    type: 'checkbox',
    value: 'allowCreate',
    label: 'Create',
  },
  {
    type: 'checkbox',
    value: 'allowDelete',
    label: 'Delete',
  },
  {
    type: 'checkbox',
    value: 'allowView',
    label: 'VIEW',
  },
  {
    type: 'checkbox',
    value: 'admin',
    label: 'Admin',
  },
];

const ExampleFormData = [
  {
    type: 'text',
    value: 'TextOne',
  },
  {
    type: 'checkbox',
    value: 'TextOne',
    label: 'Female',
  },
  {
    type: 'checkbox',
    value: 'TextOne',
    label: 'Female',
  },
  {
    type: 'select',
    name: 'Test1',
    options: [
      { value: 'female', label: 'Female' },
      { value: 'male', label: 'Male' },
    ],
  },
];

const useFetch = url => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  async function fetchUrl() {
    const response = await fetch(url);
    const json = await response.json();
    setData(json);
    setLoading(false);
  }
  useEffect(() => {
    fetchUrl();
  }, []);
  return [data, loading];
};

const UpdateMembership = ({ membership, moduleId, membershipId, ...props }) => {
  const apiURL = `${baseURL}/api/membership/edit/${moduleId}/${membershipId}`;
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = values => {
    console.log(values);
    //setLoading(true);

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('role', 'User');
    formData.append('admin', values['admin'] ? '1' : '0');
    formData.append('allowCreate', values['allowCreate'] ? '1' : '0');
    formData.append('allowView', '1');
    formData.append('allowEdit', values['allowEdit'] ? '1' : '0');
    formData.append('allowDelete', values['allowDelete'] ? '1' : '0');

    fetch(apiURL, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: formData,
      // config: { headers: { 'Content-Type': 'application/json' } },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        /* setModalopen(false);
        setLoading(false);
        resetName();
        resetDesc();*/
        //props.updateTypeMembership(`PORTAL`, data.item);
        props.updateTypeMembership(`PROJECT`, data.item);
        props.insertTypeListeMembership(`TASKS`, data.item);
        //props.updateTypeMembership(`TASK`, data.item);
      })
      .catch(function(error) {
        /*setLoading(false);
        setError('error');*/
      });
  };

  return (
    <>
      <p>
        <form onSubmit={handleSubmit(onSubmit)}>
          Role: <TextInput name={'Role'} register={register} defaultValue={membership ? membership.role : 'null'} />
          <br />
          <Checkbox
            name={'admin'}
            register={register}
            label={`Admin`}
            defaultChecked={membership ? membership.admin : null}
          />
          <Checkbox
            name={'allowCreate'}
            register={register}
            label={`Create`}
            defaultChecked={membership ? membership.allowCreate : null}
            // checked={membership ? membership.allowCreate : null}
          />
          <Checkbox
            name={'allowView'}
            register={register}
            label={`View`}
            defaultChecked={membership ? membership.allowView : null}
          />
          <Checkbox
            name={'allowEdit'}
            register={register}
            label={`Edit`}
            defaultChecked={membership ? membership.allowEdit : null}
          />
          <Checkbox
            name={'allowDelete'}
            register={register}
            label={`Delete`}
            defaultChecked={membership ? membership.allowDelete : null}
          />
          <br />
          <button type="submit">Submit</button>
        </form>
      </p>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTypeMembership,
      insertTypeListeMembership,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMembership);
