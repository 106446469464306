import React, { useEffect } from 'react';
import { Heading, Loader } from '@ies-ds/react-ui';
import ModuleContainer from './ModuleContainer';

//ROUTING
import { Route, Switch, useParams, useRouteMatch, Link } from 'react-router-dom';
import NoMatch from '../NoMatch';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchModule,
  fetchPlatformSkeleton,
  fetchPlatformSkeletonReset,
  fetchListEntryModules,
  fetchListEntryModulesReset,
} from '../../components/Module/actions';

// COMPONENTS
import { Container, Table, TableHeader, TableRow, TableCell } from '@ies-ds/react-ui';

const Platform = props => {
  const { id, pid } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    // Platform Skeleton
    props.fetchPlatformSkeleton(id);
    return () => {};
  });

  const { loading, data, error } = props.modules;

  return (
    <Container style={{ padding: '16px', margin: '16px 0' }}>
      <Heading size={2}>Platform</Heading>
      <Table>
        <TableRow>
          <TableHeader>ID</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Module Type</TableHeader>
          <TableHeader>platform</TableHeader>
        </TableRow>
        {loading ? (
          <Loader type={'dots'} />
        ) : (
          data.map(d => {
            return (
              <TableRow>
                <TableCell> {d.id} </TableCell>
                <TableCell>
                  {' '}
                  {/*<Link to={`${url}/${d.platform}/${d.typeLabel.toLowerCase()}/${d.id}`}>{d.name}</Link>*/}
                  <Link to={`${url}/${d.platform}/${d.id}`}>{d.name}</Link>
                </TableCell>
                <TableCell> {d.typeLabel} </TableCell>
                <TableCell> {d.platform} </TableCell>
              </TableRow>
            );
          })
        )}
      </Table>
      {/*
              <ConnectedModuleContainer moduleId={1} />
      <ConnectedModuleContainer moduleId={950} />
      <ConnectedModuleContainer moduleId={951} />
      <ConnectedModuleContainer moduleId={921} />
      <ConnectedModuleContainer moduleId={923} />
      <ConnectedModuleContainer moduleId={866} />
      <ConnectedModuleContainer moduleId={867} />
      <ConnectedModuleContainer moduleId={868} />
      <ConnectedModuleContainer moduleId={999999} />
        
        
        */}
    </Container>
  );
};

const mapStateToProps = state => {
  const { session } = state;

  return {
    modules: session.entry_modules,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
      fetchListEntryModules,
      fetchListEntryModulesReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
