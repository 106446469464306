import React from 'react';
import {
  Heading,
  Grid,
  Icon,
  Text,
  Loader,
  ThemeProvider,
  BaseTheme,
  Container,
  Box,
  TabGroup,
  Tab,
  Button,
} from '@ies-ds/react-ui';
import { useLocation, useHistory, Redirect, Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';

const StyledContainer = styled(Container)`
  p {
    margin: 20px 0;
  }
  ul {
    margin: 16px 32px;
  }
  li {
    margin-bottom: 8px;
  }
  h2 {
    margin: 32px 0 0 0;
  }
  margin-bottom: 32px;
`;

const StaticLayout = ({ children }) => {
  return (
    <ThemeProvider>
      {' '}
      <Grid container style={{ height: '95vh' }}>
        <Grid item sm={12}>
          <StyledContainer padding="max">
            <Button as={Link} to="/">
              Home
            </Button>{' '}
            <Button as={Link} to="/disclaimer">
              Disclaimer
            </Button>{' '}
            <Button as={NavLink} to="/about">
              About
            </Button>
            <br /> <br />
            {children}
          </StyledContainer>

          <Box style={{}} gap="8">
            <img style={{ width: '78px' }} src="https://4rineu.eu/wp-content/uploads/2016/11/EU-flag_white.png" />
            <div>
              <Text size={'xsmall'}>
                This project has received funding from the European Union’s Horizon 2020 research and innovation
                programme under grant agreement No 723829 - All copyrights © 2016-20 reserved to 4RinEU Consortium
              </Text>
              <Text size={'small'}>
                <Link to="/">Home</Link> | <Link to="/disclaimer">Disclaimer</Link> | <Link to="/about">About</Link>
              </Text>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default StaticLayout;
