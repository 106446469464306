import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BuildForm from '../BuildForm';
import StatusBadge from '../../components/StatusBadge';

import { createDeliverable } from './actions';

const DeliverableAdd = ({ module, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { deliverable } = module;

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.createDeliverable(data.item, module.id);
    setLoading(false);
    setModalopen(false);
  };

  if (deliverable.links.add_deliverable) {
    return (
      <>
        {trigger ? (
          <div onClick={() => openHandler()}>{trigger}</div>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            INVITE [{module.id}]
          </Button>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Add a deliverable</h2>
          <p>Complete the form below to add a deliverable. </p>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`/api/deliverable/add/${module.id}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Add Deliverable'}
                completeBtnLabel={'Close'}
                formDataMethodFix={true}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createDeliverable,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableAdd);
