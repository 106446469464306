import React, { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, useParams, Link, useLocation, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../settings';

import {
  Menu,
  Avatar,
  Layout,
  Box,
  ThemeContainer,
  BaseTheme,
  DarkTheme,
  Icon,
  Loader,
  ThemeProvider,
  dark,
} from '@ies-ds/react-ui';

import { Frame, FrameHeader, FrameNav, FrameContent } from '@ies-ds/react-ui';

import Routes from './index';
import { fetchType } from '../actions/higherorder';
import {
  fetchPlatformSkeleton,
  fetchPlatformSkeletonReset,
  fetchListEntryModules,
  fetchListEntryModulesReset,
} from '../components/Module/actions';

import { RootContext } from './RootContext';
import AcccountBadge from '../components/AcccountBadge';
import PortalChanger from '../components/PortalChanger';
import LoadingOverlay from '../components/LoadingOverlay';
import AuthenticatedRoute from './AuthenticatedRoute';
import Projects from '../pages/projects';
import Metrics from '../pages/metrics';
import Settings from '../pages/settings';
import Project from '../pages/project';
import SideMenu from '../components/SideMenu';
import TasksAll from '../components/tasks-all';

//// TEST LOADER
import ModuleLoader from '../components/Module/ModuleLoader';

const LayoutDashboard = props => {
  let location = useLocation();
  let history = useHistory();
  const { id, pid } = useParams();

  let { path, url } = useRouteMatch();

  if (!props.isAuthenticated) {
    history.replace(location.pathname);
  }

  useEffect(() => {
    // Platform Skeleton
    props.fetchPlatformSkeleton(id);
    props.fetchListEntryModules();
    return () => {
      //props.fetchPlatformSkeletonReset();
      props.fetchListEntryModulesReset();
    };
  }, []);

  return (
    <ThemeProvider>
      <Frame leadElement="Nav" navOpen={false}>
        <FrameHeader>
          <Box justifyContent="space-between" alignItems="center" style={{ padding: '0 16px', height: '56px' }}>
            <PortalChanger />
            <AcccountBadge />
          </Box>
        </FrameHeader>
        <ThemeProvider theme={dark}>
          <FrameNav>
            {' '}
            <SideMenu />
          </FrameNav>
        </ThemeProvider>
        <FrameContent>
          <Layout horizontal="true" style={{ height: '100%' }}>
            <Switch>
              <Route path="/portal/:id/module/:pid">
                <ModuleLoader />
              </Route>
              {/*<Route path="/portal/:id/projects">
                  <Projects />
                </Route>*/}
              <Route render={({ match }) => <Projects match={match} />} path={`/portal/:id/projects`} />
              <Route render={({ match }) => <Metrics match={match} />} path={`/portal/:id/metrics`} />

              <Route path="/portal/:id/settings">
                <Settings />
              </Route>
              <Route path="/portal/:id/tasks-all">
                <TasksAll />
              </Route>
              <Route path="/portal/:id/project/:pid">
                <Project />
              </Route>
            </Switch>
          </Layout>
        </FrameContent>
      </Frame>
      {false && (
        <Layout>
          <Layout horizontal="true">
            <SideMenu />
            <Layout>
              <ThemeProvider theme={dark}>
                <Layout.Header>
                  <Box justifyContent="space-between">
                    <PortalChanger />
                    <AcccountBadge />
                  </Box>
                </Layout.Header>
              </ThemeProvider>
              <Layout horizontal="true" style={{ height: '90vh' }}>
                <Switch>
                  <Route path="/portal/:id/module/:pid">
                    <ModuleLoader />
                  </Route>
                  {/*<Route path="/portal/:id/projects">
                  <Projects />
                </Route>*/}
                  <Route render={({ match }) => <Projects match={match} />} path={`/portal/:id/projects`} />
                  <Route render={({ match }) => <Metrics match={match} />} path={`/portal/:id/metrics`} />

                  <Route path="/portal/:id/settings">
                    <Settings />
                  </Route>
                  <Route path="/portal/:id/tasks-all">
                    <TasksAll />
                  </Route>
                  <Route path="/portal/:id/project/:pid">
                    <Project />
                  </Route>
                </Switch>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    portals: state.portals,
    projects: state.projects,
    isAuthenticated: state.session.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
      fetchListEntryModules,
      fetchListEntryModulesReset,
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDashboard);
