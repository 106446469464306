import React, { useState, useEffect, useRef } from 'react';
import IconButton from '../ies-ui/IconButton';
import Popover from '../ies-ui/Popover';
import { Menu, Icon } from '@ies-ds/react-ui';
import { Link } from 'react-router-dom';
import ModuleDelete from './Module/ModuleDelete';
import ModuleEdit from './Module/ModuleEdit';

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    callback();
    /* if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }*/
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });
};

const ProjectPopover = ({ module, icon }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  const { id } = module;

  useOutsideClick(ref, () => {
    if (visible) setVisible(false);
  });

  return (
    <div style={{ position: 'relative' }}>
      <IconButton size="20" icon={icon} onClick={() => setVisible(!visible)} />
      {visible && (
        <Popover ref={ref}>
          <Menu.Container>
            <Menu style={{ minWidth: '120px' }}>
              <Menu.Item>
                <ModuleEdit module={module} moduleType={'PROJECT'} />
              </Menu.Item>
            </Menu>
          </Menu.Container>
          <Menu.Container>
            <Menu>
              <Menu.Item>
                <Icon size={18} icon="bin" color="#555" /> <Link to="/portal/1">Delete</Link>
              </Menu.Item>
            </Menu>
          </Menu.Container>
        </Popover>
      )}
    </div>
  );
};

ProjectPopover.defaultProps = {
  icon: 'arrowD',
};

export default ProjectPopover;
