import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Loader, Text, Heading } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson } from '../../actions/higherorder';
import { deleteModule, deleteListModule } from '../Module/actions';

const ModuleDelete = ({ module, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = module;

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}/api/module/delete/${id}`)
      .then(data => {
        props.deleteModule(module);
        props.deleteListModule(module);
        closeHandler();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  if (module.links.delete) {
    return (
      <>
        {trigger ? (
          <span onClick={() => openHandler()}>{trigger}</span>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Delete
          </Button>
        )}
        <Modal isOpen={modalOpen}>
        <Heading size="2"> {module.links.delete.label}</Heading>
          <Text>Are you sure you want to delete this?</Text>
          <br />
          {loading ? (
            <Loader />
          ) : (
            <>
              <Button
                color="danger"
                size="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Yes, Delete {module.typeLabel}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  closeHandler();
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

ModuleDelete.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteModule,
      deleteListModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDelete);
