import React, { useState, useEffect } from 'react';
import { Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { baseURL } from '../../settings';
import { Heading, Text, Loader, Button } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import draftToMarkdown from 'draftjs-to-markdown';
import moment from 'moment';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { TextInput, Checkbox, Select, Option } from '../../ies-ui/form';

const StyledInput = styled.input`
  padding: 8px;

  font-size: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  ${props => {
    if (props.errors) {
      return `border: 1px solid red; &:focus {outline:0;} `;
    } else {
      return `border: 1px solid #ccc;`;
    }
  }}
`;

const content = {
  entityMap: {},
  blocks: [
    {
      key: '637gr',
      text: 'Initialized from content state.ccc',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};

const ArticleEdit = ({ returnURL, moduleId, ...props }) => {
  let { path, url } = useRouteMatch();
  const { articleId } = useParams();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const [editorState, setEditorState] = useState(null);
  const { handleSubmit, register, errors, setError } = useForm();

  const history = useHistory();

  //const [contentState, setContentState] = useState(content);

  //const contentState = convertFromRaw(content);

  useEffect(() => {
    fetchArticle(`${baseURL}/api/article/view/${moduleId}/${articleId}`);
    return () => {
      /*cleanup*/
    };
  }, [articleId]);

  const onSubmit = values => {
    //alert('submit');
    setUpdating(true);
    setSuccess(false);
    /*setErrorMessage('');*/

    console.log('values');
    console.log(values['title']);

    const convertedData = convertToRaw(editorState.getCurrentContent());

    const formData = new FormData();
    formData.append('content', JSON.stringify(convertedData));
    formData.append('title', values.title);
    formData.append('_method', 'PUT');

    const request = new Request(`${baseURL}/api/article/edit/${moduleId}/${articleId}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    });

    fetch(request)
      // .then(checkStatus)
      .then(r => r.json())
      .then(data => {
        setUpdating(false);
        /* setLoading(false);*/
        setSuccess(true);
        history.push(returnURL);
        /*onSuccess(data);*/
      })
      .catch(function(error) {
        /* setSuccess(false);
        setErrorMessage('' + error);
        setUpdating(false);
        setLoading(false);
        if (error.response && error.response.status === 422) {
          error.response.json().then(data => {
            if (data.elements) {
              data.elements.map(d => {
                if (d.errors) {
                  d.errors.forEach(error => {
                    setError(d.name, 'notMatch', error);
                  });
                }
              });
              setErrorMessage('');
            }
          });
        }*/
      });
  };

  const fetchArticle = apiURL => {
    fetch(`${apiURL}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setLoading(false);
        updateContent(data.item.content);
        setData(data.item);
      })

      .catch(function(error) {
        console.log(error);
      });
  };

  const updateContent = data => {
    const output = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
    setEditorState(output);
  };

  const onEditorStateChange = editorState => {
    const convertedData = convertToRaw(editorState.getCurrentContent());
    console.log(JSON.stringify(convertedData));
    setEditorState(editorState);
  };

  const onContentStateChange = contentState => {
    const convertedData = convertToRaw(editorState.getCurrentContent());
    console.log(contentState);
    setContentState(contentState);
  };

  return (
    <>
      <p>
        <Link to={`${returnURL}`}>
          <Button size={'xsmall'}>Back to Knowledge</Button>
        </Link>
      </p>
      {loading ? (
        <Loader type="dots" />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ margin: '16px 0' }}>
              <Heading size="3">Edit Knowledge Content</Heading>
              <Text>Complete the form below to edit the content</Text>
            </div>

            <div className="field">
              <Text size={'xsmall'}>TITLE</Text>
              <StyledInput name={`title`} ref={register()} defaultValue={data.title} errors={errors[`title`]} />
            </div>

            <Text size={'xsmall'}>CONTENT</Text>

            <div style={{ background: '#fff', padding: '8px', border: '1px solid rgb(225, 228, 234)' }}>
              <Editor
                editorState={editorState}
                // initialContentState={contentState}
                wrapperClassName={'demo-wrapper'}
                editorClassName="panel"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                //onContentStateChange={onContentStateChange}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: false },
                  // image: { uploadCallback: this.uploadImageCallBack, alt: { present: true, mandatory: true } },
                }}
              />
            </div>
            <Text size={'small'}>
              Created By: {data.createdBy} on {moment(data.created).format('Do MMM YYYY @ hh:mm')}
            </Text>

            {updating ? (
              <p>
                <Loader type={'dots'} /> is updating{' '}
              </p>
            ) : null}

            {success ? <p>UPDATED</p> : null}

            <p>
              <Button color="primary" type="submit" size="small">
                Update Content
              </Button>

              <Link to={`${returnURL}`}>
                <Button size={'small'}>Cancel</Button>
              </Link>
            </p>
          </form>
        </>
      )}
    </>
  );
};

export default ArticleEdit;
