import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Avatar, Heading, Text, Box, Loader } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refeshCommentsList, fetchCommentSuccess } from './actions';

import { CommentEdit, CommentReply, CommentDelete, CommentAdd } from './';
import { imageUrlFetch, fetchJson } from '../../actions/higherorder';

import { checkNodeExists } from '../../utils';

const StyledComment = styled.div`
  /*border: 5px solid red;*/
  padding: 8px;
  background: #fff;
  box-shadow: 0px 3px 5px #ccc;
  border-radius: 8px;
`;

const Comment = ({ d, module }) => {
  return (
    <div style={{ display: 'flex', padding: ' 8px 0' }}>
      <Avatar src={`${baseURL}/api/avatar/view/${d.createdById}`} style={{ marginRight: '12px', marginLeft: '0' }} />
      <div style={{ width: '100%', maxWidth: '500px' }}>
        <StyledComment>
          <Box gap={'4'}>
            <Heading size="5" noMargin>
              {d.createdBy}
            </Heading>
            <Text as="span" size={'xsmall'} noMargin>
              {moment(d.created).fromNow()}
            </Text>

            <CommentEdit
              module={module}
              item={d}
              trigger={
                <Text size={'xsmall'} noMargin style={{ margin: '0', color: '#3490a5', cursor: ' pointer' }}>
                  Edit
                </Text>
              }
            />
            <CommentDelete
              module={module}
              item={d}
              trigger={
                <Text size={'xsmall'} noMargin style={{ margin: '0', color: '#3490a5', cursor: ' pointer' }}>
                  Delete
                </Text>
              }
            />

            {/* 
           
            {/*
            <Text size="xsmall" noMargin>
              | id: {d.id} | parent id: {d.parentId}
            </Text>*/}
          </Box>

          <Text size={'small'}>{d.content}</Text>
        </StyledComment>
      </div>
    </div>
  );
};

function buildComments(arr, module, parentId = '') {
  return (
    <>
      {arr.map(e => {
        if (e.parentId === parentId) {
          if (parentId == '') {
            return (
              <div
              //style={{ border: '1px solid #ccd2da', marginBottom: '16px ', background: '#fff', padding: '4px 8px' }}
              >
                <Comment d={e} module={module} />
                <div>{buildComments(arr, module, e.id)}</div>

                <div style={{ borderTop: '1px solid #e6e6e6' }}>
                  <CommentReply module={module} comment={e} />
                </div>
              </div>
            );
          } else {
            return (
              <div style={{ paddingLeft: '32px' }}>
                <Comment d={e} module={module} />
              </div>
            );
          }
        }
      })}
    </>
  );
}

const CommentList = ({ module, ...props }) => {
  const { data, loading } = props.comments;

  const { discussion } = props.data;

  useEffect(() => {
    let timer = setInterval(() => {
      props.refeshCommentsList(module.id);
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {checkNodeExists(discussion) ? (
        discussion.comments.length < 0 ? (
          <p>No Comments</p>
        ) : (
          buildComments(discussion.comments, module)
        )
      ) : (
        'naw'
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { entities, entities: modules } = state;
  const { data } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,

    comments: state.entities.comments,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refeshCommentsList,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
