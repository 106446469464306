import React, { useState, useRef, useEffect } from 'react';

import { ThemeContainer, Layout, BaseTheme, Heading, Text, Button, Box, Card, Loader, Grid } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import { baseURL } from '../../settings';

import {appdata} from '../../settings'

import styled from 'styled-components';
//import { forgotPassword } from '../../actions/auth';
import { Link, useParams } from 'react-router-dom';

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const MessageStyle = styled.span`
  font-size: 14px;
  color: blue;
`;

const ResetPassword = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [csrf, setCsrf] = useState('');
  let { token } = useParams();

  useEffect(() => {
    fetch(`${baseURL}/api/reset-password`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(r => r.json())
      .then(data => {
        console.log(data);
        setCsrf(data.elements[1].value);
      })
      .catch(function(error) {
        console.log(error);
      });
  }, [props]);

  const { register, errors, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = (data, e) => {
    console.log(data);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const formData = new FormData();
    formData.append('reset_password_form[password][first]', data.password);
    formData.append('reset_password_form[password][second]', data.password_repeat);
    formData.append('reset_password_form[token]', token);
    formData.append('reset_password_form[csrf_token]', csrf);

    fetch(`${baseURL}/api/reset-password`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded',
        //Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setLoading(false);
      })
      .catch(function(error) {
        /*setLoading(false);*/
      });

    event.preventDefault();
    setLoading(true);
    //debugger;
    // let email = {
    // email: data.email.trim(),
    //  };
    /*forgotPassword(email)
      .then(data => {
        if (data.data.message) {
          setMessage(data.data.message);
        }
        setLoading(false);
      })
      .catch(function(error) {
        setError(error.response.data.error);
        setLoading(false);
      });*/
  };

  function handleChange(event) {
    setError('');
    setMessage('');
  }

  return (
    <div>
      <ThemeContainer>
        <Layout>
          <Grid container fullwidth>
            <Grid
              item
              sm={12}
              md={6}
              style={{ height: '100vh', background: '#f4f7fe', display: 'flex', position: 'relative' }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  right: '0',
                  left: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card showBorder style={{ flex: 'unset' }}>
                  <Card.Content>
                  <div style={{ margin: '16px 0 32px 0', height: '64px', width: '100%', background: `url(${appdata.logo}) left center / contain no-repeat`}}>
                        
                        </div>
                    <h2>Reset Password</h2>
                    <Text>Complete the form to reset password</Text>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '350px' }}>
                      <div className="field" style={{ margin: '8px 0 8px 0' }}>
                        <StyledLabel htmlFor="email">
                          New Password <span className="required">*</span>
                        </StyledLabel>
                        <br />
                        <input
                          type="password"
                          id="password"
                          name="password"
                          ref={register({
                            required: 'You must specify a password',
                            minLength: {
                              value: 8,
                              message: 'Password must have at least 8 characters',
                            },
                          })}
                          style={{
                            padding: '8px',
                            border: '1px solid #ccc;',
                            fontSize: '16px',
                            backgroundColor: '#fff',
                            width: '100%',
                            boxSizing: 'border-box',
                          }}
                          onChange={handleChange}
                        />
                        <br />
                        <StyledErrorLabel>{errors.password && <p>{errors.password.message}</p>}</StyledErrorLabel>
                      </div>
                      <div className="field" style={{ margin: '8px 0 8px 0' }}>
                        <StyledLabel htmlFor="email">
                          Repeat Password <span className="required">*</span>
                        </StyledLabel>
                        <br />
                        <input
                          type="password"
                          id="password_repeat"
                          name="password_repeat"
                          ref={register({
                            validate: value => value === password.current || 'The passwords do not match',
                          })}
                          style={{
                            padding: '8px',
                            border: '1px solid #ccc;',
                            fontSize: '16px',
                            backgroundColor: '#fff',
                            width: '100%',
                            boxSizing: 'border-box',
                          }}
                          onChange={handleChange}
                        />
                        <br />
                        <StyledErrorLabel>
                          {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                        </StyledErrorLabel>
                      </div>
                      <div>
                        <StyledErrorLabel>
                          <p>{error}</p>
                        </StyledErrorLabel>
                        <MessageStyle>
                          <p>{message}</p>
                        </MessageStyle>
                      </div>

                      <div className="field">
                        <Button type="submit">
                          {loading ? (
                            <>
                              <Loader size="small" type="dots" /> Sending
                            </>
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      </div>
                      <br />
                      <div>
                        <Link to="/login">Back to Login</Link>
                      </div>
                    </form>
                  </Card.Content>
                </Card>
              </div>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                height: '100vh',
                background:
                `url(${appdata.loginimg}) center center / cover no-repeat`,
              }}
            ></Grid>
          </Grid>
        </Layout>
      </ThemeContainer>
    </div>
  );
};

export default ResetPassword;
