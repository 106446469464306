import React, { useEffect, useMemo, useState } from 'react';
import { checkNodeExists, childCount, ModuleCustomValues } from '../utils';
import { Heading, Text, Loader, Box, Avatar, Icon, Grid } from '@ies-ds/react-ui';
import { NavLink, useParams, Route, Switch, useRouteMatch, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchType } from '../actions/higherorder';
import FeedList from '../components/FeedList';
import GanttChart from '../components/Module/GanttChart';
import { CommentList, CommentAdd } from '../components/Comment/';
import { Articles } from '../components/Article/';
import { FileList } from '../components/Vault/File/';
import { ReferenceList } from '../components/Vault/Reference';
import IconButton from '../ies-ui/IconButton';
import { MembershipDatatable, MembershipAdd, MembershipEdit, MembershipDelete } from '../components/Membership';
import { imageUrlFetch } from '../actions/higherorder';
import { ClonePanel } from '../components/Module/Clone';

import { ReportDashboard } from '../components/Report';

import styled from 'styled-components';

import { Header } from '../components/Module/layout';

import { fetchModule } from '../components/Module/actions';

import { ModuleList } from '../components/Module';
import ListLoader from '../components/Module/ListLoader';

import Task from '../pages/task';
import pluralize from 'pluralize';

import { fetchPlatformSkeleton, fetchPlatformSkeletonReset } from '../components/Module/actions';

const StyledNavLinkContainer = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
`;
const StyledNavLink = styled(NavLink)`
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  color: #999;
  padding: 8px 16px;
  display: inline-block;
  margin-bottom: -1px;
  &.active {
    border-bottom: 2px solid #4d58e8;
    color: #000;
    font-weight: 700;
  }
`;

const StyledLink = styled.div`
  color: #4d58e8;
  ${props => (props.add ? `color: #4d58e8` : `color: #000`)}
  display: inline-block;
  padding: 1px;
  &:hover {
    color: #333;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Project = props => {
  const { id, pid } = useParams();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(pid);

    let timer = setInterval(() => {
      //props.fetchModule(pid);
    }, 10000); ///REFRESH EVERY 30 sec

    return () => {
      clearInterval(timer);
    };
  }, [pid]);

  if (!props.doesExsist && !props.isFetching) {
    return 'Deleted ';
  }

  const getPlatformNode = moduleType => {
    //  const { data } = props.platform;

    function iterate(obj, key) {
      var result;
      for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (property === key) {
            return obj[key]; // returns the value
          } else if (typeof obj[property] === 'object') {
            // in case it is an object
            result = iterate(obj[property], key);
            if (typeof result !== 'undefined') {
              return result;
            }
          }
        }
      }
    }

    return props.platform.data.name == moduleType
      ? props.platform.data
      : iterate(props.platform.data.children, moduleType);
  };

  const moduleTabs = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return Object.keys(platformNode.children).map(function(key) {
        const { label } = platformNode.children[key];

        return <StyledNavLink to={`${url}/${labelUrl(label)}`}>{labelName(label)}</StyledNavLink>;
      });
    }
  };

  const moduleDefaultRoute = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(platformNode.children).map(function(key) {
            const { label } = platformNode.children[key];
            return <Redirect exact from={`${url}/`} to={`${url}/${labelUrl(label)}`} />;
          });
    }
  };

  const moduleRoutes = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(platformNode.children).map(function(key) {
            const { label, name } = platformNode.children[key];
            return (
              <Route path={`${path}/${labelUrl(label)}`}>
                <ModuleList data={data} moduleType={name} />
                <ListLoader data={data} />
                <ModuleCustomValues module={data} />
              </Route>
            );
          });
    }
  };

  const moduleSubRoutes = (data, path) => {
    {
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(data.links).map(function(key) {
            switch (data.links[key].access) {
              case 'create':
                return `${labelUrl(data.links[key].label)}|`;
            }
          });
    }
  };

  const getType = url => {
    const lastslashindex = url.lastIndexOf('/');
    const result = url.substring(lastslashindex + 1);
    return result;
  };

  const labelName = label => {
    return (label = pluralize(label.replace('Add ', '')));
  };

  const labelUrl = label => {
    return (label = pluralize(
      label
        .replace('Add ', '')
        .toLowerCase()
        .replace(' ', '-'),
    ));
  };

  return (
    <>
      {props.isFetching ? (
        <>
          <Loader type="dots"></Loader> loading...
        </>
      ) : (
        <>
          {' '}
          <Header module={props.data}>{props.data.name}</Header>
          <Grid container fullwidth>
            <Grid item sm={12}>
              <StyledNavLinkContainer>
                {/*CHILDREN MODULES*/}
                {moduleTabs(props.data)}
                {/*<StyledNavLink to={`${url}/tasks`}>
                  {pluralize('Task')} ({childCount(props.data.children)})
                </StyledNavLink>*/}

                {/*COMPONENTS OF MODULE*/}

                {checkNodeExists(props.data.library) ? (
                  <StyledNavLink to={`${url}/knowledge`}>
                    Knowledge ({childCount(props.data.library.articles)})
                  </StyledNavLink>
                ) : null}

                {checkNodeExists(props.data.vault) ? (
                  <StyledNavLink to={`${url}/files`}>Files ({childCount(props.data.vault.files)})</StyledNavLink>
                ) : null}

                {checkNodeExists(props.data.vault) ? (
                  <StyledNavLink to={`${url}/references`}>
                    References ({childCount(props.data.vault.references)})
                  </StyledNavLink>
                ) : null}

                {checkNodeExists(props.data.discussion) ? (
                  <StyledNavLink to={`${url}/comments`}>
                    Comments ({childCount(props.data.discussion.comments)})
                  </StyledNavLink>
                ) : null}

                {checkNodeExists(props.data.report) ? (
                  <StyledNavLink to={`${url}/metric`}>
                    Reports / Metrics ({childCount(props.data.report.metrics)})
                  </StyledNavLink>
                ) : null}

                {/*DATA FROM MODULE*/}
                <StyledNavLink to={`${url}/editors`}>Editors</StyledNavLink>
                <StyledNavLink to={`${url}/feed`}>Feed</StyledNavLink>
                {/*EXTRA TBC
                <StyledNavLink to={`${url}/gantt-chart`}>Gantt Chart</StyledNavLink>*/}
              </StyledNavLinkContainer>

              <Switch>
                {moduleDefaultRoute(props.data, url)}
                {moduleRoutes(props.data, path)}
                {/*
                <Route path={`${path}/tasks`}>
                  <ModuleList data={props.data} />
                  <ListLoader data={props.data} />
                  <ModuleCustomValues module={props.data} />
                </Route>
                */}
                <Route path={`${path}/knowledge`}>
                  <Articles module={props.data} moduleType={'PROJECT'} />
                </Route>
                <Route exact path={`${path}/files`}>
                  <FileList module={props.data} moduleType={`project`} />
                </Route>
                <Route exact path={`${path}/references`}>
                  <ReferenceList module={props.data} moduleType={`project`} />
                </Route>
                <Route exact path={`${path}/comments`}>
                  <CommentList module={props.data} data={props.data.discussion} />
                  <CommentAdd moduleId={props.data.id} moduleType={'project'} cols="100" />
                </Route>
                <Route exact path={`${path}/feed`}>
                  <FeedList id={props.data.id} />
                </Route>
                <Route exact path={`${path}/gantt-chart`}>
                  <GanttChart module={props.data} moduleID={props.data.id} />
                </Route>
                <Route exact path={`${path}/editors`}>
                  <MembershipDatatable moduleId={props.data.id} data={props.data.memberships} />
                </Route>
                <Route path={`${path}/metric`}>
                  <ReportDashboard module={props.data} moduleId={props.data.id} />
                </Route>

                <Route path={`${path}/clone`}>
                  <ClonePanel module={props.data} />
                </Route>
              </Switch>
              {/* //// STATUS TEST   
              <ModuleStatus module={props.data} />*/}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { pid },
    },
  },
) => {
  const { selectedSubreddit, entities, entities: modules, session } = state;
  const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[pid] || {
    isFetching: true,
    items: [],
  };

  const { platform } = session;

  return {
    platform,
    modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    doesExsist,
    lastUpdated,

    entities: state.entities,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Project);
