import React, { useState } from 'react';
import { Button, Modal, Heading, Loader } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTypeInsert } from '../../actions/higherorder';
import { useInput } from '../shared';

import BuildForm from '../BuildForm';

const ModuleAdd = ({ id, type, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { value: name, bind: bindName, reset: resetName } = useInput('');
  const { value: desc, bind: bindDesc, reset: resetDesc } = useInput('');

  const handleSubmit = e => {
    setLoading(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', desc);

    fetch(`${baseURL}/api/module/add/${id}/4rineu_${type}`, {
      method: 'POST',
      //body: JSON.stringify(data),
      body: formData,
      config: { headers: { 'Content-Type': 'application/json' } },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setModalopen(false);
        setLoading(false);
        resetName();
        resetDesc();
        props.fetchTypeInsert(`${type}S`, data.item);
      })
      .catch(function(error) {
        setLoading(false);
        setError('error');
      });
  };

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    /* props.insertTypeListDeliverables('TASKS', data, moduleId);
    props.addDeliverable('TASK', data, moduleId);
    props.insertTypeDeliverable('TASK', data.item);*/
    props.fetchTypeInsert(`${type}S`, data.item);
    closeHandler();
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" onClick={() => openHandler()}>
          Add {type}
        </Button>
      )}

      <Modal isOpen={modalOpen}>
      <Heading size="2"> Add {type}</Heading>
        <p>Complete the form below to add a new {type}</p>
        {error}
        {loading ? (
          <Loader />
        ) : (
          <>
            <BuildForm
              apiURL={`/api/module/add/${id}/4rineu_${type}`}
              onSuccess={onSuccess}
              completeAction={() => closeHandler()}
              submitBtnLabel={`Add ${type}`}
              completeBtnLabel={'Close'}
            />
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTypeInsert,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleAdd);
