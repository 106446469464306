import React, { useState } from 'react';
import { Button, Modal, Loader , Heading} from '@ies-ds/react-ui';
import BuildForm from '../../components/BuildForm';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTypeEditList, fetchTypeEdit } from '../../actions/higherorder';

const ModuleEdit = ({ module, moduleId, moduleType, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { id } = module;

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    /*props.insertTypeArticle(moduleType, data.item);*/
    props.fetchTypeEditList(`${moduleType}S`, data);
    props.fetchTypeEdit(moduleType, data);
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" onClick={() => openHandler()}>
          Edit
        </Button>
      )}
      <Modal isOpen={modalOpen}>
      <Heading size="2">Edit</Heading>
        <p>Complete the form below to edit. </p>
        {error}
        {loading ? (
          <Loader />
        ) : (
          <>
            <BuildForm
              apiURL={`/api/module/edit/${id}`}
              onSuccess={onSuccess}
              completeAction={() => closeHandler()}
              submitBtnLabel={`Edit ${moduleType}`}
              completeBtnLabel={'Close'}
            />
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTypeEditList,
      fetchTypeEdit,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEdit);
