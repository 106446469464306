import React from 'react';
import { string, any, bool } from 'prop-types';
import Badge from '../ies-ui/Badge';
import styled from 'styled-components';

const StyledStatusBadge = styled(Badge)`
  cursor: default;
  border: 2px solid rgba(60, 60, 60, 0);

  ${props =>
    props.disabled
      ? null
      : `
&:hover {
  cursor: pointer;
  border: 2px solid rgba(60, 60, 60, 0.3);
}`}
`;

const StatusBadge = ({ children, disabled, ...props }) => {
  return (
    <StyledStatusBadge {...props} disabled={disabled}>
      {' '}
      {children}{' '}
    </StyledStatusBadge>
  );
};

StatusBadge.propTypes = {
  children: any,
  size: string,
  disabled: bool,
};

StatusBadge.defaultProps = {
  children: 'NO STATUS',
  size: 'regular',
  disabled: false,
};

export default StatusBadge;
