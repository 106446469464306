import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTypeMembership, insertTypeListeMembership } from '../../actions/membership';

import { updateMembership } from './actions';

import { MembershipDelete } from './';

import BuildForm from '../../components/BuildForm';

const MembershipEdit = ({ userId, module, moduleId, membershipId, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateMembership(data.item, module.id);
    //props.updateTypeMembership(`PROJECT`, data.item);
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" onClick={() => openHandler()}>
          Edit User [{moduleId}]
        </Button>
      )}
      <Modal isOpen={modalOpen}>
        {error}
        {loading ? (
          <Loader />
        ) : (
          <>
            <h2> Edit User</h2>
            <p>Complete the form below to edit the user. </p>
            <BuildForm
              apiURL={`/api/membership/edit/${moduleId}/${membershipId}`}
              onSuccess={onSuccess}
              completeAction={() => closeHandler()}
              submitBtnLabel={`Update User`}
              completeBtnLabel={'Close'}
            />
            {module.links.delete ? (
              <>
                <br />
                <p>or...</p>
                <MembershipDelete
                  module={module}
                  userId={userId}
                  moduleId={moduleId}
                  membershipId={membershipId}
                  completeAction={onSuccess}
                />
                <Button size={`small`} onClick={() => closeHandler()}>
                  Close
                </Button>{' '}
              </>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMembership,
      updateTypeMembership,
      insertTypeListeMembership,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MembershipEdit);
