import React, { useEffect } from 'react';
import {
  ThemeProvider,
  BaseTheme,
  Menu,
  Box,
  Text,
  Icon,
  Grid,
  Card,
  Layout,
  Loader,
  Container,
} from '@ies-ds/react-ui';
import AuthenticatedRoute from '../routes/AuthenticatedRoute';
import { useParams, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import FeedList from '../components/FeedList';
import { StyledNavLink } from '../components/shared';
import { MembershipDatatable } from '../components/Membership';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Articles, ArticleList } from '../components/Article';
import { FileList } from '../components/Vault/File';
import { ModuleCustomValues } from '../utils';
import { CloneList, ClonePanel } from '../components/Module/Clone';

import { fetchModule } from '../components/Module/actions';

const Settings = props => {
  const { id } = useParams();
  const { loading, data } = props.portal;
  let { path, url } = useRouteMatch();

  const portal = data.item;

  useEffect(() => {
    props.fetchModule(id);

    return () => {};
  }, [props]);

  return (
    <>
      <ThemeProvider>
        <Layout.Side style={{ background: '#fff' }}>
          <Menu.Container>
            <div style={{ padding: '8px 16px', minWidth: '300px' }}>
              <Box justifyContent="flex-start" style={{ marginBottom: '16px', fontWeight: '500' }}>
                <Menu>
                  <StyledNavLink to={`${url}/templates`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="database" color="#555" />{' '}
                      <Text style={{ marginLeft: '16px' }}>Templates</Text>
                    </Menu.Item>
                  </StyledNavLink>
                  <StyledNavLink to={`${url}/custom-fields`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="database" color="#555" />{' '}
                      <Text style={{ marginLeft: '16px' }}>Custom Fields</Text>
                    </Menu.Item>
                  </StyledNavLink>
                  <StyledNavLink to={`${url}/users`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="users" color="#555" /> <Text style={{ marginLeft: '16px' }}>Members</Text>
                    </Menu.Item>
                  </StyledNavLink>
                  <StyledNavLink to={`${url}/articles`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="files" color="#555" />
                      <Text style={{ marginLeft: '16px' }}>Articles</Text>
                    </Menu.Item>
                  </StyledNavLink>
                  <StyledNavLink to={`${url}/files`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="database" color="#555" />
                      <Text style={{ marginLeft: '16px' }}>Files</Text>
                    </Menu.Item>
                  </StyledNavLink>
                  <StyledNavLink to={`${url}/feed`} style={{ margin: '0', padding: '4px' }}>
                    <Menu.Item>
                      <Icon size={18} icon="users" color="#555" />
                      <Text style={{ marginLeft: '16px' }}>Feed</Text>
                    </Menu.Item>
                  </StyledNavLink>
                </Menu>
              </Box>
            </div>
          </Menu.Container>
        </Layout.Side>
        <Layout.Content>
          {loading ? (
            <Loader type="dots" />
          ) : (
            <Switch>
              <Route exact path={`${path}`}>
                [{path}]
              </Route>
              <Route path={`${path}/templates`}>
                <h2>Templates </h2>
                <ClonePanel module={portal} />
              </Route>
              <Route path={`${path}/custom-fields`}>
                <h2>Custom Fields </h2>
                <ModuleCustomValues module={portal} />
              </Route>
              <Route path={`${path}/users`}>
                <h2>Admin </h2>
                <MembershipDatatable moduleId={portal.id} data={portal.memberships} showAdminOnly={true} />
                <h2>Members</h2>
                <MembershipDatatable moduleId={portal.id} data={portal.memberships} />
              </Route>
              <Route path={`${path}/articles`}>
                <h2>Portal Articles</h2>
                <Articles module={portal} moduleType={'PORTAL'} />
              </Route>
              <Route path={`${path}/files`}>
                <h2>Portal Files</h2>
                <FileList module={portal} moduleType={`portal`} />
              </Route>
              <Route path={`${path}/feed`}>
                <h2>Portal Feed</h2>
                <FeedList id={id} />
              </Route>
            </Switch>
          )}
        </Layout.Content>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = state => {
  return {
    project: state.project,
    portal: state.portal,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
