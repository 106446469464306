import React from 'react';
import styled from 'styled-components';
import { string, any } from 'prop-types';

const StyledBadge = styled.div`
  font-weight: 500;

  text-transform: uppercase;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  display: inline-flex;
    

  ${props => {
    if (props.size === 'Xsmall') {
      return `
      font-size: 10px;
      padding: 4px 8px;
      height: 20px;
    `;
    } else if (props.size === 'small') {
      return `
      font-size: 12px;
      padding: 4px 8px;
      height: 24px;
    `;
    } else if (props.size === 'large') {
      return `
      font-size: 16px;
      padding:4px 12px;
      height: 28px;
    `;
    } else {
      return `
      font-size: 14px;
      padding:4px 8px;
      height: 24px;
    `;
    }
  }}

  ${props => {
    switch (props.status) {
      case 'IN_PROGRESS':
        return `background: #13bee6`;
      case 'COMPLETED':
        return `background: #22c704`;
      case 'NOT_STARTED':
        return `background: #ffa446;`;
      case 'BLOCKED':
        return `  background: #ff4141;`;
      case 'VERIFIED_COMPLETE':
        return `background: #027951; `;
      default:
        return `
      background: #999; 
    `;
    }

    if (props.status === 'IN_PROGRESS') {
      return `
      background: #13bee6;
    `;
    } else if (props.status === 'COMPLETED') {
      return `
      background: #22c704;
    `;
    } else if (props.status === 'NOT_STARTED') {
      return `
      background: #ffa446;
    `;
    } else if (props.status === 'BLOCKED') {
      return `
      background: #ff4141;
      display: inline;
    `;
    } else if (props.status === 'VERIFIED_COMPLETE') {
      return `
      background: #027951;
    `;
    } else {
      return `
      background: #999; 
    `;
    }
  }}

${props => {
  if (props.fullwidth) {
    return `
      width: 100%;
    `;
  }
}}


`;

const Badge = ({ children, status, ...props }) => {
  return (
    <StyledBadge status={status} {...props}>
      {children}
    </StyledBadge>
  );
};

Badge.propTypes = {
  children: any,
  size: string,
};

Badge.defaultProps = {
  children: 'NO STATUS',
  size: 'regular',
};

export default Badge;
