import React, { useState } from 'react';
import { Button, Modal, Loader, Heading} from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addModule } from './actions';
import BuildForm from '../BuildForm';

const ModuleAdd = ({ module, type, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.addModule(data.item, module.id);
    setLoading(false);
    setModalopen(false);
  };

  if (module.links[type]) {
    const { route, url, label, access } = module.links[type];

    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            {label}
          </Button>
        )}
        <Modal isOpen={modalOpen}>
          <Heading size="2">{label}</Heading>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`${url}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Add New'}
                completeBtnLabel={'Close'}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleAdd);
