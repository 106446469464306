import React, { useEffect } from 'react';
import { useParams, useRouteMatch, Link, NavLink } from 'react-router-dom';
import ModuleLoader from '../../components/Module/ModuleLoader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchModule } from '../../components/Module/actions';

import { Sidenav, SidenavItem, SidenavGroup, SidenavCategory, Icon } from '@ies-ds/react-ui';

import Step from './Step';

const ProjectMenu = ({ ...props }) => {
  const { portalId } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(portalId);
    return () => {
      {
        /*clean*/
      }
    };
  }, [portalId]);

  return (
    <>
      {props.isFetching ? (
        'Loading'
      ) : (
        <>
          <Sidenav>
            {props.data.children.map(item => {
              return (
                <SidenavItem icon="default" as={NavLink} to={`${url}/${item.id}`}>
                  {item.name}
                </SidenavItem>
              );
            })}
          </Sidenav>
        </>
      )}
    </>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { portalId },
    },
  },
) => {
  const { entities } = state;
  const { isFetching, data } = entities.modules[portalId] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMenu);
