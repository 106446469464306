import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchModule } from './actions';

const ListLoader = ({ data, ...props }) => {
  const LoadMosd = id => {
    return props.fetchModule(id);
  };

  return data.children.map(item => {
    return LoadMosd(item.id);
  });
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListLoader);
