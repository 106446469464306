import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Loader, Heading, Text } from '@ies-ds/react-ui';
import { baseURL } from '../../../settings';
import IconButton from '../../../ies-ui/IconButton';
import moment from 'moment';

import { ReferenceAdd, ReferenceEdit, ReferenceDelete } from './';

import VaultUpload from '../File/Upload';
import VaultEdit from '../File/Edit';
import VaultDelete from '../File/Delete';
import VaultLock from '../File/Lock';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refeshVault } from '../actions';

import { checkNodeExists, childCount } from '../../../utils';

import StyledTable from '../../../ies-ui/StyledTable';

const createReference = node => {
  const action = node[3].allowCreate ? (
    <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
      <ReferenceAdd module={module} />
    </Box>
  ) : null;
  return action;
};

const CellTitle = ({ item }) => {
  return (
    <>
      <Heading size={'4'} noMargin>
        <a href={item.url} target="_blank" style={{ color: '#333' }}>
          {item.title}
        </a>
      </Heading>
      <Text size="small" style={{ margin: '0px 0 0 0' }}>
        {item.description}
      </Text>
      <Text size="xsmall" style={{ color: '#2989dc', margin: '4px 0 0 0' }}>
        <a href={item.url} target="_blank">
          {item.url}{' '}
        </a>
      </Text>
    </>
  );
};

const ReferenceList = ({ module, ...props }) => {
  return (
    <>
      {module.vault.links.add_reference && (
        <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
          <ReferenceAdd module={module} item={module.vault.links.add_reference} />
        </Box>
      )}

      {checkNodeExists(props.data.vault) ? (
        <>
          <StyledTable>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Type</th>
              <th>Date Added</th>
              <th>Created By</th>
              <th></th>
            </tr>
            {props.data.vault.references.length <= 0 ? (
              <tr>
                <td colspan="5">No references Yet </td>
              </tr>
            ) : (
              props.data.vault.references.map(item => {
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td style={{ maxWidth: '600px' }}>
                      <CellTitle item={item} />
                    </td>

                    <td>{item.resourceType}</td>
                    <td>{moment(item.created).format('Do MMM YYYY')}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Box justifyContent="flex-end">
                        <a href={item.url} target="_blank">
                          <IconButton size="16" icon="link" title="View" />
                        </a>
                        <ReferenceEdit
                          module={module}
                          item={item}
                          trigger={
                            <IconButton size="16" icon="pencil" title={item.locked ? 'Fie is locked' : 'Edit'} />
                          }
                        />
                        <ReferenceDelete
                          module={module}
                          item={item}
                          trigger={<IconButton size="16" icon="bin" title={item.locked ? 'Fie is locked' : 'Edit'} />}
                        />
                      </Box>
                    </td>
                  </tr>
                );
              })
            )}
          </StyledTable>
        </>
      ) : (
        'no references'
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: vault, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,
    selectedSubreddit,
    vault,
    isFetching,
    doesExsist,
    lastUpdated,

    vault: state.entities.vault,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refeshVault,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceList);
