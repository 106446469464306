import React, { Suspense, useState, useEffect } from 'react';
import { Heading, Loader } from '@ies-ds/react-ui';
import { Link } from 'react-router-dom';
import { baseURL } from '../settings';

const ListTasks = props => {
  const [tasks, setTasks] = useState(undefined);

  useEffect(() => {
    fetchProjects('task', props.id);
  }, []);

  const fetchProjects = (type, pid) => {
    fetch(`${baseURL}/api/module/xxxxxxxxxxxxxxdetailed-list-by-type/4rineu_${type}/${pid}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => setTasks(data.list))
      .catch(function(error) {});
  };

  if (tasks == undefined) {
    return <Loader size="small" />;
  }

  const listItems = tasks.map(task => (
    <p>
      -- {task.name} [{task.id}]
    </p>
  ));

  if (listItems.length == 0) {
    return <p>No Tasks...</p>;
  }

  return <>{listItems}</>;
};

const ListProjects = props => {
  const [project, setProject] = useState(undefined);

  useEffect(() => {
    fetchProjects('project', props.id);
  }, []);

  const fetchProjects = (type, pid) => {
    fetch(`${baseURL}/api/module/detailed-list-by-type/4rineu_${type}/${pid}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => setProject(data.list))
      .catch(function(error) {});
  };

  if (project == undefined) {
    return <Loader size="small" />;
  }

  const listItems = project.map(project => (
    <div style={{ margin: '16px 0', border: '1px solid #ccc', padding: '16px' }}>
      <h3>
        {project.name} [{project.id}]
      </h3>
      <Suspense fallback={<Loader />}>
        <ListTasks id={project.id} />
      </Suspense>
    </div>
  ));

  return <>{listItems}</>;
};

const TasksAll = () => {
  const [portals, setPortals] = useState([]);

  React.useEffect(() => {
    fetchPortals();
  }, []);

  const listPortals = data => {
    // const getP = fetchProjects('project', data.id);
    return (
      <div style={{ padding: '16px', margin: '0 0 16px 0', background: '#fff' }}>
        <h2 key={data.id}>{`${data.name} [${data.id}]`}</h2>
        <Suspense fallback={<Loader />}>
          <ListProjects id={data.id} />
        </Suspense>
      </div>
    );
  };

  const fetchPortals = () => {
    fetch(`${baseURL}/api/membership/list-entry-modules`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => setPortals(data.list))
      .catch(function(error) {});
  };

  const listItems = portals.map(portal => listPortals(portal));

  return (
    <div>
      <Heading appearance="h2">Tasks</Heading>
      {listItems.length === 0 ? <Loader size="small" /> : <div>{listItems}</div>}
    </div>
  );
};

export default TasksAll;
