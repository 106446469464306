import React, { useState, useEffect } from 'react';
import { baseURL } from '../../settings';
import { Loader } from '@ies-ds/react-ui';
import styled from 'styled-components';
import { fetchJson } from '../../actions/higherorder';

const KpiCardStyled = styled.div`
  background: #fff;
  padding: 16px 32px;
  min-width: 200px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px 16px 0;
  border: 1px solid #e5e9f1;
  .value {
    font-weight: 700;
    font-size: 40px;
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;
    position: relative;
    color: #1a2b4b;
  }
  .symbol {
    font-size: 28px;
    margin: 4px;
    color: #999;
    position: absolute;
    left: -23px;
  }
  .label {
    font-size: 16px;
    color: #999;
    margin: 4px 0;
  }
`;

const KpiCard = ({ data, label, symbol }) => {
  return (
    <KpiCardStyled>
      <div className="value">
        <span className="symbol">{symbol}</span>
        {data ? data : '-'}
      </div>
      <div className="label">{label}</div>
    </KpiCardStyled>
  );
};

const Bar = ({ data, label, max }) => {
  const width = (data / max) * 98;

  return (
    <div
      style={{
        marginLeft: '15px',
        width: `${width}%`,
        background: '#3f99e6',
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: '700',
        padding: '4px',
        color: '#fff',
      }}
    >
      {label}
    </div>
  );
};

const ReportCost = ({ moduleId }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchForm(`/api/report/show/${moduleId}/attribute/gavin`);
  }, []);

  const fetchForm = apiURL => {
    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        setData(data.item);
        setLoading(false);
      })
      .catch(function(error) {});
  };

  const ListMetric = () => {
    return (
      <>
        {loading ? (
          <Loader type="dots" />
        ) : (
          <>
            <br />
            <h3>Cost Overview</h3>

            <KpiCard label={'Max Value'} symbol={data.symbol} data={data.aggregatedValues[0].max_value}></KpiCard>
            <KpiCard label={'Min Value'} symbol={data.symbol} data={data.aggregatedValues[0].min_value}></KpiCard>
            <KpiCard label={'Avg Value'} symbol={data.symbol} data={data.aggregatedValues[0].avg_value}></KpiCard>
            <KpiCard label={'Sum Value'} symbol={data.symbol} data={data.aggregatedValues[0].sum_value}></KpiCard>
            <br />

            <h3>Task by Cost</h3>
            <div>
              <table style={{ background: '#fff', padding: '16px', border: '1px solid #e5e9f1' }}>
                {data.groupedValues.map(d => {
                  return (
                    <>
                      <tr>
                        <td>
                          {d.moduleName} [ {d.moduleId}]
                        </td>
                        <td style={{ width: '680px' }}>
                          <Bar
                            data={d.total}
                            label={`${data.symbol} ${d.total}`}
                            max={data.aggregatedValues[0].max_value}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        )}
      </>
    );
  };

  return <>{ListMetric()}</>;
};

export default ReportCost;
