import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchArticles = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_ARTICLES`,
    });

    fetchJson(`${baseURL}/api/article/list/${moduleId}`)
      .then(data => {
        dispatch(fetchArticlesSuccess(data.list));
      })
      .catch(error => {
        dispatch(fetchArticlesFailure(error));
      });
  };
};

export const refeshArticles = moduleId => {
  return dispatch => {
    dispatch({
      type: `REFESH_ARTICLES`,
    });

    fetchJson(`${baseURL}/api/article/list/${moduleId}`)
      .then(data => {
        dispatch(fetchArticlesSuccess(data.list));
      })
      .catch(error => {
        dispatch(fetchArticlesFailure(error));
      });
  };
};

export const fetchArticlesSuccess = data => {
  return {
    type: `FETCH_ARTICLES_SUCCESS`,
    data,
  };
};

export const fetchArticlesFailure = error => {
  return {
    type: `FETCH_ARTICLES_FAILURE`,
    error,
  };
};

export const fetchArticlesReset = () => {
  return {
    type: `FETCH_ARTICLES_RESET`,
  };
};

/**** REDUCE TO MODULES */

export const insertArticle = (dataItem, moduleId) => {
  return {
    type: `CREATE_ARTICLE`,
    dataItem,
    moduleId,
  };
};

export const updateArticle = (dataItem, moduleId) => {
  return {
    type: `UPDATE_ARTICLE`,
    dataItem,
    moduleId,
  };
};

export const deleteArticle = (itemId, moduleId) => {
  return {
    type: `DELETE_ARTICLE`,
    itemId,
    moduleId,
  };
};
