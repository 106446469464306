import React, { useEffect } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import ModuleLoader from '../../components/Module/ModuleLoader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchModule } from '../../components/Module/actions';

import { Text, Box, Loader, Icon, Button } from '@ies-ds/react-ui';

import { FileUpload } from '../../components/Vault/File';
import { ReferenceAdd } from '../../components/Vault/Reference';

import StepItem from './StepItem';
import Files from './components/Files';
import LinkList from './components/LinkList';
import ModalViewer from './components/ModalViewer';

const Step = ({ module, ...props }) => {
  const { stepId } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(module.id);
    return () => {
      {
        /*clean*/
      }
    };
  }, [module.id]);

  return (
    <>
      {props.isFetching ? (
        <Loader type="dots" />
      ) : (
        <>
          <Text as="div" color="light" size="small">
            {props.data.library.articles.map(item => {
              return (
                <Box gap="8" alignItems="center">
                  <Icon icon="file" size="xsmall" />
                  {/*<Link to={`${url}/doc/${props.data.id}/${item.id}`}>{item.title}</Link>
                  <a href="javascript:void(0)"></a>*/}
                  <ModalViewer articleId={item.id} moduleId={props.data.id} label={item.title} />
                </Box>
              );
            })}
          </Text>

          <LinkList links={props.data.vault.references} module={module} />

          <Text as="div" color="light" size="small" style={{ marginTop: '24px' }}>
            <Files files={props.data.vault.files} module={module} />
            <br />
          </Text>
          {props.data.children.map(item => {
            return <StepItem module={item} />;
          })}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { module } = ownProps;

  const { entities } = state;
  const { isFetching, data } = entities.modules[module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    entities,
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Step);
