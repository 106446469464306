export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const PROFILE_GET = 'PROFILE_GET';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAIL = 'PROFILE_GET_FAIL';
export const PROFILE_RESET = 'PROFILE_RESET';
