import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCustomField } from './actions';
import { fetchModule } from '../Module/actions';

import BuildForm from '../BuildForm';

import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const AddCustomField = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.fetchModule(module.id);
    props.createCustomField(data.item, module.id);

    closeHandler();
  };

  if (true) {
    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <StyledLink size="small" onClick={() => openHandler()}>
            {item.label}
          </StyledLink>
        )}
        <Modal isOpen={modalOpen}>
          <a
            style={{ position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', color: '#a7a7a7' }}
            onClick={() => closeHandler()}
          >
            x
          </a>
          <h2> {item.label}</h2>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={item.url}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Add Value'}
                completeBtnLabel={'Close'}
                formDataMethodFix={false}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createCustomField,
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomField);
