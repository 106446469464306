import React from 'react';
import { Avatar, Box } from '@ies-ds/react-ui';
import { checkNodeExists } from '../../../utils';
import styled from 'styled-components';
import moment from 'moment';
import { baseURL } from '../../../settings';

import { DeliverableEdit } from '../../Deliverable';
import { getImageURL } from '../../../utils';

const StyledLink = styled.div`
  color: #4d58e8;
  ${props => (props.add ? `color: #4d58e8` : `color: #000`)}
  display: inline-block;
  padding: 1px;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: #333;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DeliverableOwner = ({ module, label, node, isDate }) => {
  return (
    <div>
      <strong>{label}: </strong>{' '}
      <div style={{ margin: '0 24px 0 0px', minWidth: '80px' }}>
        {module.deliverable.links.edit ? (
          <DeliverableEdit
            deliverable={module.deliverable}
            module={module}
            moduleType={'PROJECT'}
            trigger={
              module.deliverable.ownerName ? (
                <StyledLink>
                  <Avatar
                    size="xsmall"
                    src={getImageURL(`${baseURL}/api/avatar/view/${module.deliverable.ownerId}`)}
                    title={`${module.deliverable.ownerName}`}
                  />
                  {module.deliverable[node]}
                </StyledLink>
              ) : (
                <StyledLink add>Add User</StyledLink>
              )
            }
          />
        ) : isDate ? (
          isNaN(module.deliverable[node]) ? (
            moment(module.deliverable[node]).format('Do MMM YYYY')
          ) : (
            'n/a'
          )
        ) : (
          <>*{module.deliverable[node]}</>
        )}
      </div>
    </div>
  );
};

const DeliverableItem = ({ module, label, node, isDate }) => {
  return (
    <div>
      <strong>{label}: </strong>{' '}
      <div style={{ margin: '0 24px 0 0px', minWidth: '80px' }}>
        {module.deliverable.links.edit ? (
          <DeliverableEdit
            deliverable={module.deliverable}
            module={module}
            moduleType={'PROJECT'}
            trigger={
              module.deliverable[node] ? (
                <StyledLink>
                  {isDate
                    ? isNaN(module.deliverable[node])
                      ? moment(module.deliverable[node]).format('Do MMM YYYY')
                      : 'Add Date'
                    : module.deliverable[node]}
                </StyledLink>
              ) : (
                <StyledLink add>Add Date</StyledLink>
              )
            }
          />
        ) : isDate ? (
          isNaN(module.deliverable[node]) ? (
            moment(module.deliverable[node]).format('Do MMM YYYY')
          ) : (
            'n/a'
          )
        ) : (
          module.deliverable[node]
        )}
      </div>
    </div>
  );
};

const Deliverable = ({ module }) => {
  return (
    <>
      {checkNodeExists(module.deliverable.links) ? (
        <>
          {!module.deliverable.links.add_deliverable ? (
            <Box style={{ margin: '0', fontSize: '12px' }}>
              <DeliverableOwner module={module} label={'Owner'} node={'ownerName'} />
              <DeliverableItem module={module} label={'Start Date'} node={'startDate'} isDate />
              <DeliverableItem module={module} label={'Deadline'} node={'deadline'} isDate />
              <DeliverableItem module={module} label={'Commenced'} node={'commencedDate'} isDate />
              <DeliverableItem module={module} label={'Completion'} node={'completionDate'} isDate />
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Deliverable;
