import React, { useEffect, useState } from 'react';
import { Heading, Text, Container, Box, Icon, Sidepanel } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CommentList, CommentAdd } from '../../../components/Comment';

const Chat = ({ module, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {props.isFetching ? null : (
        <>
          <a href="javascript:void(0)" onClick={() => setOpen(true)}>
            <Text as="span" weight="bold">
              <Icon icon="message" size="xsmall" style={{ marginRight: '4px' }} />

              {props.data.discussion.comments.length}
            </Text>
          </a>
          <Sidepanel
            width="xlarge"
            isOpen={open}
            onClose={() => setOpen(false)}
            style={{
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {' '}
            <Text as="span" size="xsmall" color="lighter" style={{ marginTop: '32px' }}>
              Disscussion
            </Text>
            <Text as="h3" color="dark" style={{ margin: '4px 0 16px 0' }}>
              {module.name}
            </Text>
            <div
              style={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                background: ' #f1f5f9',
                padding: '16px',
              }}
            >
              <CommentList module={module} />
            </div>
            <div style={{ height: '220px' }}>
              <CommentAdd moduleId={module.id} cols="70" />
            </div>
          </Sidepanel>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { module } = ownProps;

  const { entities } = state;
  const { isFetching, data } = entities.modules[module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    entities,
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
