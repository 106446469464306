import React, { useState } from 'react';
import { Button, Loader, Textfield } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import BuildForm from '../BuildForm';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { insertComment } from './actions';

import { fetchModule } from '../Module/actions';

const StyledReply = styled(Textfield)``;

const CommentReply = ({ module, comment, ...props }) => {
  const placeholderText = 'Reply to comment...';
  const [adding, setAdding] = useState(false);
  const [active, setActive] = useState(true);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState('');
  const [placeholder, setPlaceholder] = useState(placeholderText);
  const { handleSubmit, register, errors, setError } = useForm();

  const onSubmit = values => {
    setAdding(true);
    setText('');
    setActive(true);
    setEdit(false);

    const formData = new FormData();
    formData.append('title', 'Added Comment Default');
    formData.append('content', values.comment);
    fetch(`${baseURL}/api/comment/reply/${module.id}/${comment.id}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setAdding(false);
        console.log(module.id);
        props.insertComment(data.item, module.id);
        props.fetchModule(module.id);
        /*resetComment();
        props.insertTypeComment(moduleType, data.item);*/
      })
      .catch(function(error) {
        /*setLoading(false);*/
      });
  };

  const onCancel = e => {
    setText('');
    setPlaceholder(placeholderText);
    setEdit(false);
  };

  const onFocus = () => {
    setPlaceholder('');
    setEdit(true);
    //alert('focus');
  };

  const onBlur = () => {
    setPlaceholder(placeholderText);
    if (text == '') {
      setEdit(false);
    }
  };

  return (
    <>
      {active ? (
        <div>
          {/*<p>Edit</p> <input type={'text'} />
          <BuildForm
            apiURL={`/api/comment/reply/921/234`}
            //apiURL={`/api/comment/reply/${module.id}/${comment.id}`}
            onSuccess={onSuccess}
            completeAction={() => closeHandler()}
            submitBtnLabel={`Add ${type}`}
            completeBtnLabel={'Close'}
          />
          <Button onClick={() => postReply()} />*/}
        </div>
      ) : (
        <div onClick={() => reply()}>[Reply]</div>
      )}
      {adding ? (
        <>
          <Loader type="dots" /> Posting{' '}
        </>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textfield
          name={`comment`}
          ref={register()}
          placeholder={placeholder}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          onChange={e => setText(e.target.value)}
          value={text}
          style={{ marginLeft: '40px', marginBottom: '16px' }}
        />
        {edit ? (
          <>
            <Button
              color={'primary'}
              size={'xsmall'}
              onClick={() => handleSubmit()}
              disabled={text != '' ? false : true}
            >
              Post Reply
            </Button>
            <Button size={'xsmall'} onClick={() => onCancel()}>
              Cancel
            </Button>
          </>
        ) : null}
      </form>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      insertComment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommentReply);
