import React from 'react';

import styled from 'styled-components';

import { Draggable } from 'react-beautiful-dnd';

import { ModuleEdit, ModuleDelete } from '../../../../components/Module';
import { Button, Box, Text } from '@ies-ds/react-ui';
import { NavLink, useParams, Route, Switch, useRouteMatch, Link } from 'react-router-dom';

const Container = styled.div`
  padding: 8px 16px;
  margin-bottom: 12px;
  background: #fff;
  border: 1px solid #ddd;

  box-shadow: ${props => (props.isDragging ? '0 0 0 2px rgb(21 156 228 / 40%)' : 'none')};
`;

const Task = ({ data, isDragDisabled, index }) => {
  const { url } = useRouteMatch();
  return (
    <Draggable
      draggableId={data.id}
      key={data.id}
      index={index}
      style={{ border: '2px solid red' }}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <Box>
            <Box direction="column">
              <Text as="div">
                <Link to={`${url}/${data.result.id}`}> {data.result.name} </Link>
              </Text>
              <Text as="p" size="small" color="light">
                {data.result.description}
              </Text>

              <Text size="xsmall" as="div" color="lightest">
                <Box gap="16">
                  <span>ID: {data.result.id} </span>
                  <span> PLATFORM: {data.result.platform} </span>
                  <span> MODULE TYPE: {data.result.typeLabel}</span>
                </Box>
              </Text>
            </Box>
            <Box justifyContent="flex-end">
              {Object.keys(data.result.links).map(function(key) {
                switch (data.result.links[key].operation) {
                  case 'edit':
                    return (
                      <ModuleEdit
                        module={data.result}
                        trigger={
                          <Button icon="edit24" size="small">
                            Edit
                          </Button>
                        }
                      />
                    );
                  /* case 'create':
                          return <ModuleAdd module={data} type={key} />;*/
                  case 'delete':
                    return (
                      <ModuleDelete
                        module={data.result}
                        type={key}
                        trigger={
                          <Button icon="close24" size="small">
                            Delete
                          </Button>
                        }
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Box>
          </Box>
        </Container>
      )}
    </Draggable>
  );
};

export default Task;
