import React, { useEffect, useState } from 'react';

import { NavLink, useParams, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModuleStatus, ModuleCustomValues, checkNodeExists } from '../../utils/';

import { ModuleAdd, ModuleEdit, ModuleDelete } from '../Module';

import { fetchModule, fetchModuleList, updateComponent } from './actions';

import SortableModules from '../../routes/v2/components/SortableModules/SortableModules';

import ModuleContainer from '../../routes/v2/ModuleContainer';
import ArticleList from '../../routes/calibr/components/Article/List';
//
import { Articles } from '../Article';
import { FileList } from '../Vault/File';
import { ReferenceList } from '../Vault/Reference';

// COMPONENTS
import {
  Heading,
  Text,
  Loader,
  Grid,
  Container,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Button,
  Box,
  Modal,
  Tab,
  TabGroup,
  Logo,
} from '@ies-ds/react-ui';

const selectedModule = '4rineu_project';

const Breadcrumbs = ({ data, url }) => {
  const { platform, fqn, path, fullPath } = data;

  const fqnArray = fqn.split('/');
  var result = fullPath.substring(1, fullPath.length - 1);
  const fullPathArray = result.split('/');

  let currenRoute = '';

  var currentPage = fqnArray[fqnArray.length - 1];

  return (
    <>
      <Link to={'/v2'}>Home</Link> /{' '}
      {fqnArray.slice(0, -1).map((routeName, i) => {
        currenRoute = currenRoute + `/${fullPathArray[i]}`;
        return (
          <>
            <Link key={i} to={`/v2/${platform}${currenRoute}`}>
              {routeName}
            </Link>{' '}
            /
          </>
        );
      })}
      {currentPage}
    </>
  );
};

const ModuleLoader = ({ moduleId, ...props }) => {
  const { url } = useRouteMatch();
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    props.fetchModule(moduleId);
    setTimeout(function() {
      //  props.updateComponent({ test: 'Gavin' }, 921, 'vault', 'references');
    }, 3000);
  }, [moduleId]);

  const { pid } = useParams();

  return (
    <div>
      {props.isFetching ? (
        <Text>
          Module Loading <Loader type="dots" />{' '}
        </Text>
      ) : (
        <>
          <Grid container fullwidth>
            <Grid item lg={12}>
              <Text size="small" color="light">
                <Breadcrumbs data={props.data} />
              </Text>
              <Text as="h2">
                {props.data.name} ({props.data.typeLabel} ){' '}
                {Object.keys(props.data.links).map(function(key) {
                  switch (props.data.links[key].access) {
                    case 'edit':
                      if (props.data.links[key].operation === 'edit') {
                        return (
                          <>
                            <ModuleEdit
                              module={props.data}
                              trigger={
                                <Button size="xsmall" icon="pencil">
                                  Edit
                                </Button>
                              }
                            />
                          </>
                        );
                      }

                    default:
                      return null;
                  }
                })}
              </Text>
              <Text>{props.data.description}</Text>
              <br />
              {/*props.platform.loading ? 'loading' : 'Complete'*/}

              <Text as="h2">Child Modules</Text>

              <SortableModules data={props.data} />
              {/*
              <Container style={{ margin: '0 0 16px 0' }}>
                {Object.keys(props.data.links).map(function(key) {
                  switch (props.data.links[key].access) {
                    case 'create':
                      if (props.data.links[key].operation === 'add') {
                        return (
                          <>
                            <ModuleAdd
                              module={props.data}
                              type={key}
                              trigger={<Button size="small">Add {props.data.links[key].label}</Button>}
                            />
                          </>
                        );
                      }

                    default:
                      return null;
                  }
                })}

                <Table>
                  <TableRow>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Desc</TableHeader>
                    <TableHeader>Module Type</TableHeader>
                    <TableHeader>platform</TableHeader>
                    <TableHeader></TableHeader>
                  </TableRow>

                  {props.data.children.map(data => {
                    return (
                      <TableRow key={data.id}>
                        <TableCell> {data.id} </TableCell>
                        <TableCell>
                          <Link to={`${url}/${data.id}`}> {data.name}</Link>
                        </TableCell>
                        <TableCell> {data.description} </TableCell>
                        <TableCell> {data.typeLabel} </TableCell>
                        <TableCell>{data.platform}</TableCell>
                        <TableCell>
                          <Box justifyContent="flex-end">
                            {Object.keys(data.links).map(function(key) {
                              switch (data.links[key].operation) {
                                case 'edit':
                                  return (
                                    <ModuleEdit
                                      module={data}
                                      trigger={
                                        <Button icon="edit24" size="small">
                                          Edit
                                        </Button>
                                      }
                                    />
                                  );
    
                                case 'delete':
                                  return (
                                    <ModuleDelete
                                      module={data}
                                      type={key}
                                      trigger={
                                        <Button icon="close24" size="small">
                                          Delete
                                        </Button>
                                      }
                                    />
                                  );
                                default:
                                  return null;
                              }
                            })}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </Container>
            */}
              {checkNodeExists(props.data.vault) && (
                <>
                  <Text as="h2">Files</Text>
                  <Container style={{ margin: '0 0 16px 0' }}>
                    <FileList module={props.data} moduleType={`project`} />
                  </Container>
                </>
              )}
              {checkNodeExists(props.data.vault) && (
                <>
                  <Text as="h2">Links</Text>
                  <Container style={{ margin: '0 0 16px 0' }}>
                    <ReferenceList module={props.data} moduleType={`project`} />
                  </Container>
                </>
              )}
              {checkNodeExists(props.data.library) && (
                <>
                  <Text as="h2">Library </Text>
                  <Container style={{ margin: '0 0 16px 0' }}>
                    <ArticleList module={props.data} />
                  </Container>
                </>
              )}
              <br />
              <br />
              <br />
              <hr style={{ border: 0, borderBottom: '1px solid #d4d4d4' }} />
              <Text size={'xsmall'}>
                Module ID: {props.data.id} | Type: {props.data.type}
              </Text>
              <Heading>
                {props.data.typeLabel}: {props.data.name}
              </Heading>
              <Text>{props.data.description}</Text>
            </Grid>
            <Grid item lg={6}>
              <Text size={'small'}>
                <strong>id: </strong> {props.data.id}
                <br />
                <strong>parentId: </strong> {props.data.parentId}
                <br />
                <strong>idx: </strong> {props.data.idx}
                <br />
                <strong>name: </strong> {props.data.name}
                <br />
                <strong>description: </strong> {props.data.description}
                <br />
                <strong>type: </strong> {props.data.type}
                <br />
                <strong>typeLabel: </strong> {props.data.typeLabel}
                <br />
                <strong>platform: </strong> {props.data.platform}
                <br />
                <strong>path: </strong> {props.data.path}
                <br />
                <strong>fullPath: </strong> {props.data.fullPath}
                <br />
                <strong>fqn: </strong> {props.data.fqn}
                <br />
                <strong>level: </strong> {props.data.level}
                <strong>childCount: </strong> {props.data.childCount}
                <br />
                <strong>createdBy: </strong> {props.data.createdBy}
                <br />
                <strong>created: </strong> {props.data.created}
                <br />
                <strong>customValues: </strong> {'TODO: props.data.customValues'}
              </Text>

              <Heading size={3}>Children Modules</Heading>

              {props.data.children.map(data => {
                return (
                  <p key={data.id}>
                    {/*<Link to={`${url}/${data.typeLabel.toLowerCase()}/${data.id}`}> {data.name}</Link>*/}
                    <Link to={`${url}/${data.id}`}> {data.name}</Link>
                    <Text size={'small'}>
                      Module ID: {data.id} | Type: {data.type}
                    </Text>
                    {Object.keys(data.links).map(function(key) {
                      switch (data.links[key].access) {
                        case 'edit':
                          return <ModuleEdit module={data} />;
                        /* case 'create':
                          return <ModuleAdd module={data} type={key} />;*/
                        case 'delete':
                          return <ModuleDelete module={data} type={key} />;
                        default:
                          return null;
                      }
                    })}
                  </p>
                );
              })}
            </Grid>
            <Grid item lg={6}>
              <Heading size={3}>Links</Heading>
              {typeof props.data.links == 'undefined' ? (
                'no link'
              ) : (
                <table style={{ minWidth: '220px', border: '1px solid #ccc' }}>
                  {Object.keys(props.data.links).map(function(key) {
                    switch (props.data.links[key].access) {
                      case 'edit':
                        return (
                          <tr>
                            <td style={{ border: '1px solid #ccc' }}>
                              <ModuleEdit module={props.data}>Button</ModuleEdit>{' '}
                            </td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].label}</td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].url}</td>
                          </tr>
                        );
                      case 'create':
                        return (
                          <tr>
                            <td style={{ border: '1px solid #ccc' }}>
                              <ModuleAdd module={props.data} type={key} trigger={<Button>Add</Button>} />{' '}
                            </td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].label}</td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].url}</td>
                          </tr>
                        );

                      default:
                        return (
                          <tr>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].access} </td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].label}</td>
                            <td style={{ border: '1px solid #ccc' }}>{props.data.links[key].url}</td>
                          </tr>
                        );
                    }
                  })}
                </table>
              )}

              <br />
              <ModuleCustomValues module={props.data} />
              <ModuleStatus module={props.data} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { session: platform } = state;
  const { isFetching, lastUpdated, data, data: children } = entities.modules[ownProps.moduleId] || {
    isFetching: true,
    items: [],
  };

  return {
    platform,
    modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    lastUpdated,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchModuleList,
      updateComponent,
      /* fetchType,
      fetchVault,
      fetchVaultReset,
      fetchCommentsList,
      fetchCommentReset,
      fetchDeliverable,
      fetchDeliverableReset,
      fetchReportsList,
      fetchReportsReset,
      fetchArticles,
      fetchArticlesReset,
      fetchBrand,
      fetchBrandReset,*/
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLoader);
