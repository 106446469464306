import update from 'immutability-helper';

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  let error;

  switch (action.type) {
    case 'FETCH_REPORTS':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    case 'FETCH_REPORTS_SUCCESS':
      return {
        ...state,

        data: action.data,
        error: null,
        loading: false,
      };

    case `UPDATE_REPORT`:
      return update(state, {
        data: {
          comments: comments =>
            comments.map(item => {
              if (item.id !== action.commentId) return item;
              return update(item, { $merge: action.data });
            }),
        },
      });

    case 'UPDATE_REPORT_LIST':
      return {
        ...state,

        data: action.data,
        error: null,
        loading: false,
      };

    case 'FETCH_REPORTS_FAILURE':
      // error = action.err || '{message: action.data.message}';
      // error = action.error || action.error;

      error = action.error.response || { message: action.error.message };

      return {
        ...state,

        data: [],
        error,
        loading: false,
      };

    case 'FETCH_REPORTS_RESET':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    /* case `ADD_REPORT_METRIC`:
      return update(state, {
        data: {
          metrics: { $push: [action.data] },
        },
      });*/

    case `DELETE_REPORT_METRIC`:
      return update(state, {
        data: {
          metrics: arr => arr.filter(item => item.id !== action.data),
        },
      });

    case `UPDATE_REPORT_METRIC`:
      return update(state, {
        data: {
          metrics: metrics =>
            metrics.map(item => {
              if (item.id !== action.data.id) return item;
              return update(item, { $merge: action.data });
            }),
        },
      });

    default:
      return state;
  }
};
