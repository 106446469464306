import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../../settings';
import { insertComment } from './actions';
import { Button, Loader } from '@ies-ds/react-ui';
import { useInput } from '../shared';
import { fetchJson, postForm } from '../../actions/higherorder';

const CommentAdd = ({ moduleId, moduleType, cols, ...props }) => {
  const [loading, setLoading] = useState(false);
  const { value: comment, bind: bindComment, reset: resetComment } = useInput('');

  const handleSubmit = e => {
    setLoading(true);

    const formData = new FormData();
    formData.append('title', 'Added Comment Default');
    formData.append('content', comment);

    postForm(`${baseURL}/api/comment/add/${moduleId}`, 'POST', formData)
      .then(data => {
        setLoading(false);
        resetComment();
        props.insertComment(data.item, moduleId);
      })
      .catch(function(error) {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loader type="dots" /> : null}
      <form onSubmit={handleSubmit} style={{ width: '400px' }}>
        <div className="field">
          <label htmlFor="desc" style={{ fontSize: '12px', fontWeight: '500' }}>
            Comment
          </label>
          <br />
          <textarea
            type="text"
            id="desc"
            rows="6"
            cols={cols}
            {...bindComment}
            style={{
              padding: '8px',
              fontSize: '16px',
              color: 'rgb(56, 70, 86)',
              background: 'rgb(247, 249, 252)',
              border: '1px solid rgb(206, 217, 228)',
              borderRadius: '3px',
            }}
          />
        </div>
        <br />
      </form>
      <Button
        color={'primary'}
        size="small"
        onClick={() => {
          handleSubmit();
        }}
      >
        Post New Comment
      </Button>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      insertComment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommentAdd);
