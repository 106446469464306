import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Password from '../containers/Auth/Password';
import Login from '../containers/Auth/Auth';
import Invitation from '../containers/Auth/Invitation';
import PasswordReset from '../containers/Auth/PasswordReset';

import { PageDisclaimer, PageAbout } from '../pages/static';

const LoginPage = props => (
  <>
    <Switch>
      <Route path="/invitation" component={Invitation} />
      <Route exact path="/reset-password" component={PasswordReset} />
      <Route exact path="/forgot-password" component={Password} />
      <Route exact path="/disclaimer" component={PageDisclaimer} />
      <Route exact path="/about" component={PageAbout} />
      <Route path="/">
        <Login {...props} />
      </Route>
    </Switch>
  </>
);

export default LoginPage;
