import update from 'immutability-helper';

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  let error;

  switch (action.type) {
    case 'FETCH_VAULT':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    case 'REFRESH_VAULT':
      return {
        ...state,
      };

    case 'FETCH_VAULT_SUCCESS':
      return {
        ...state,

        data: action.data,
        error: null,
        loading: false,
      };

    case 'FETCH_VAULT_RESET':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    default:
      return state;
  }
};
