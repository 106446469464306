import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMetric } from '../actions';

import BuildForm from '../../../components/BuildForm';

const MetricEdit = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateMetric(data.item, module.id);
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {item.links.edit ? (
        <>
          {trigger ? (
            <div onClick={() => openHandler()}>{trigger}</div>
          ) : (
            <Button size="small" onClick={() => openHandler()}>
              Edit Metric [{item.id}]
            </Button>
          )}
          <Modal isOpen={modalOpen}>
            {error}
            {loading ? (
              <Loader />
            ) : (
              <>
                <h2> Edit Metric</h2>
                <p>Complete the form below to edit the metric. </p>
                <BuildForm
                  apiURL={`/api/report/metric/edit/${module.id}/${item.id}`}
                  onSuccess={onSuccess}
                  completeAction={() => closeHandler()}
                  submitBtnLabel={`Update Metric`}
                  completeBtnLabel={'Close'}
                  formDataMethodFix={false}
                />
              </>
            )}
          </Modal>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMetric,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MetricEdit);
