import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';
import { Loader } from '@ies-ds/react-ui';
import StatusBadge from '../../StatusBadge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchModule } from '../actions';
import Status from './status';

const ConnectStatus = ({ module, ...props }) => {
  return <p>{props.isFetching ? <Loader type="dots" size="small" /> : <Status module={props.data} />}</p>;
};

const mapStateToProps = (state, myProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[myProps.module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    children,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectStatus);
