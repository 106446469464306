import React, { useState, useEffect } from 'react';
import { Box, Grid, Loader, Button } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { useParams, useRouteMatch } from 'react-router-dom';
import { fetchJson } from '../../actions/higherorder';
import { MetricAdd, ReportMetricDelete, ReportMetricEdit } from './Metric';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateReportLayout } from './actions';

import {
  ReportMetricTypeDate,
  ReportMetricTypeNumeric,
  ReportMetricTypePicklist,
  ReportMetricTypeList,
  ReportMetricTypeText,
} from './Metric/type/';

import CardPanel from './CardPanel';

import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout';

import { checkNodeExists } from '../../utils';

const ResponsiveGridLayout = WidthProvider(Responsive);
const FullwidthGridLayout = WidthProvider(GridLayout);

const renderSwitch = (data, module, item) => {
  switch (data.type) {
    case 'TEXT':
      return <ReportMetricTypeText data={data} module={module} item={item} />;
    case 'NUMERIC':
      return <ReportMetricTypeNumeric data={data} module={module} item={item} />;
    case 'PICKLIST':
      return <ReportMetricTypePicklist data={data} module={module} item={item} />;
    case 'DATE':
      return <ReportMetricTypeDate data={data} module={module} item={item} />;
    default:
      return (
        <p>
          Attribute: {data.attribute} <br /> Type: {data.type}
        </p>
      );
      return <ReportMetricTypeList data={data} />;
  }
};

const ReportAttribute = ({ module, item }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchForm(`/api/report/show/${module.id}/attribute/${item.attribute}`);
    setLoading(true);
  }, [item.attribute]);

  const fetchForm = apiURL => {
    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        setData(data.item);
        setLoading(false);
      })
      .catch(function(error) {});
  };
  return (
    <div style={{ padding: '16px 0' }}>
      {loading ? <Loader type={`dots`} /> : <>{renderSwitch(data, module, item)}</>}
    </div>
  );
};

const ReportDashboard = ({ module, moduleId, ...props }) => {
  const onLayoutChange = layout => {
    saveLayoutChange(layout);
  };

  const saveLayoutChange = layout => {
    const formData = new FormData();
    formData.append('layout', JSON.stringify(layout));

    const request = new Request(`${baseURL}/api/report/save-layout/${module.id}/${props.data.report.id}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(() => {
        //props.updateReportLayout(layout, module.id);
        props.updateReportLayout(JSON.stringify(layout), module.id);
      })
      .catch(function(error) {});
  };

  return (
    <>
      {/* TODO : CHANGE TO EDIT NODE AND NOT VIEW - NEED TO ADD EDIT NODE IN JSON*/}
      {module.report.links.add_metric ? (
        <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
          <MetricAdd module={module} trigger={<Button size="small">Add Metric Panel</Button>} />
        </Box>
      ) : null}

      {checkNodeExists(props.data.report) ? (
        <>
          <FullwidthGridLayout
            className="layout"
            layout={props.data.report.layout ? JSON.parse(props.data.report.layout) : {}}
            // breakpoints={{ lg: '1200', md: '996', sm: '768', xs: '480', xxs: '0' }}
            // cols={{ lg: '12', md: '12', sm: '12', xs: '4', xxs: '2' }}
            rowHeight={100}
            width={1600}
            draggableHandle=".MyDragHandleClassName"
            onLayoutChange={onLayoutChange}
          >
            {props.data.report.metrics.map(item => {
              return (
                <CardPanel
                  module={module}
                  key={item.id}
                  moduleId={moduleId}
                  item={item}
                  height={520}
                  title={`${item.title}`}
                  description={`${item.description}`}
                  data-grid={{ x: 0, y: Infinity, w: 3, h: 4 }}
                >
                  <ReportAttribute module={module} attribute={item.attribute} item={item} />
                </CardPanel>
              );
            })}
          </FullwidthGridLayout>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { entities } = state;
  const { data } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateReportLayout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard);
