import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text, Heading, Loader, Box, Avatar, Icon, Buttongroup } from '@ies-ds/react-ui';

import ModuleActionsLinks from '../Module/ModuleActionsLinks';

import { ModuleAdd, ModuleEdit, ModuleDelete } from './';
import { Deliverable } from './layout';
import ConnectStatus from './layout/connectStatus';
import ConnectDeliverable from './layout/connectDeliverable';
import GanttChart from './GanttChart';
import { checkNodeExists } from '../../utils';
import moment from 'moment';

import Task from '../../pages/task';

import IconButton from '../../ies-ui/IconButton';
import pluralize from 'pluralize';

import _ from 'lodash';

// ACTIONS
import { fetchType } from '../../actions/higherorder';

// COMPONENTS
import DataTable from '../../components/DataTable';

const ModuleList = ({ data, module, moduleType, moduleId, store, ...props }) => {
  const { id, pid, tid, taskid } = useParams();
  let { path, url } = useRouteMatch();

  const filterDataType = _.filter(data.children, function(item) {
    return item.type == moduleType;
  });

  useEffect(() => {
    //props.fetchType(`${moduleType}S`, `/api/module/detailed-list-by-type/4rineu_${moduleType}/${module.id}`);
  }, [moduleId]);

  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach(row => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Task',
        id: 'task',
        accessor: 'task',

        Cell: ({ cell: { value } }) => (
          <div>
            <Heading size={'4'} noMargin style={{ fontSize: '14px' }}>
              <Link
                to={`${url.substr(0, url.lastIndexOf('/'))}/${pluralize(value.type.split('_').pop())}/${value.id}`}
                // to={`${url}/${value.id}`}
                style={{ color: '#333' }}
              >
                {value.name}
              </Link>
            </Heading>
            <Text size="xsmall" style={{ margin: '0', color: '#888' }}>
              {value.description}
            </Text>
          </div>
        ),
      },

      {
        Header: 'Status',
        accessor: 'status',
        width: 80,
        Cell: ({ cell: { value } }) => (
          <Box style={{ width: '150px' }}>
            <ConnectStatus module={value} />
            {/*<ConnectDeliverable module={value} />*/}
          </Box>
        ),
      },

      {
        Header: 'Owner',
        accessor: 'owner',
        width: 80,
        Cell: ({ cell: { value } }) => (
          <Box style={{ minWidth: '50px' }}>
            <ConnectDeliverable module={value} type={'owner'} />
          </Box>
        ),
      },

      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({ cell: { value } }) => (
          <Box style={{ minWidth: '100px' }}>
            <ConnectDeliverable module={value} type={'startDate'} />
          </Box>
        ),
      },

      {
        Header: 'Deadline',
        accessor: 'deadline',
        Cell: ({ cell: { value } }) => (
          <Box style={{ minWidth: '100px' }}>
            <ConnectDeliverable module={value} type={'deadline'} />
          </Box>
        ),
      },

      {
        id: 'edit',
        accessor: 'edit',
        Cell: ({ cell: { value } }) => (
          <Box justifyContent="flex-end">
            <IconButton as={Link} to={`${url}/${value.id}`} size="16" icon="viewSolid" title={'View'} />
            {actionLinks(value)}{' '}
          </Box>
        ),
      },
    ],
    [],
  );

  function tableData(data) {
    return data.map(d => {
      return {
        id: d,
        task: d,
        owner: d,
        startDate: d,
        deadline: d,
        status: d,
        edit: d,
      };
    });
  }

  return (
    <>
      <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
        {createLinks(data, moduleType)}
        <Box justifyContent={'flex-end'}>
          {checkNodeExists(data.deliverable) ? (
            <Buttongroup>
              <Button color={'primary'} size={'small'} as={Link} to={url.substr(0, url.lastIndexOf('/'))}>
                List
              </Button>
              <Button size={'small'} as={Link} to={`${url.substr(0, url.lastIndexOf('/'))}/gantt-chart`}>
                Gantt
              </Button>
            </Buttongroup>
          ) : null}
        </Box>
      </Box>
      <DataTable columns={columns} data={tableData(filterDataType)} />

      <Switch>
        <Route render={({ match }) => <Task match={match} returnURL={url} />} path={`${path}/:tid`} />
      </Switch>
    </>
  );
};

const actionLinks = data => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].route) {
            case 'api_module_edit':
              return <ModuleEdit module={data} trigger={<IconButton size="16" icon="pencil" title={'Edit'} />} />;
            case 'api_module_delete':
              return <ModuleDelete module={data} trigger={<IconButton size="16" icon="bin" title={'Delete'} />} />;
          }
        });
  }
};

const createLinks = (data, moduleType) => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].access) {
            case 'create':
              if (key == `add_${moduleType}`) {
                return (
                  <ModuleAdd
                    module={data}
                    type={key}
                    trigger={<Button size={'small'}>Add {data.links[key].label}</Button>}
                  />
                );
              }
          }
        });
  }
};

const mapStateToProps = (state, ownProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data: children } = entities.modules[ownProps.moduleId] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,

    selectedSubreddit,
    children,
    isFetching,
    lastUpdated,

    tasks: state.tasks,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleList);
