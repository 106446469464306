import React from 'react';
import { Menu, Layout, ThemeContainer, DarkTheme, Icon, ThemeProvider, dark, Box, Text } from '@ies-ds/react-ui';
import { useRouteMatch, Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  &.active {
    background: #20232f;
    display: block;
    color: #fff !important;
    border-left: 2px solid #0f54e8;
  }
  &:hover {
    text-decoration: none;
  }
`;

const SideMenu = () => {
  let { url } = useRouteMatch();

  return (
    <ThemeProvider theme={dark}>
      <Layout.Side style={{ width: '64px' }}>
        <Menu.Container style={{ padding: '0px' }}>
          <Menu.Item style={{ margin: '0', padding: ' 0' }}>
            <Box alignItems="center" justifyContent="center" style={{ height: '56px' }}>
              <Link to={`/`} style={{ margin: '0', padding: '0' }}>
                <Icon size={24} icon="home" color="#fff" />
              </Link>
            </Box>
          </Menu.Item>
        </Menu.Container>
        <Menu.Container style={{ padding: '0px' }}>
          <Menu>
            {/*<Menu.Item>
              <Link to={`${url}/projects`}>
                <Icon size={18} icon="data24" color="#eee" />
              </Link>
            </Menu.Item>*/}
            <StyledNavLink to={`${url}/projects`} style={{ margin: ' 0' }}>
              <Menu.Item style={{ margin: '0', padding: '8px 0', flexDirection: 'column' }}>
                <Icon size={20} icon="clipboard24" color="#fff" style={{ margin: ' 0' }} />
                <Text style={{ fontSize: '10px', marginBottom: '0' }}>Work</Text>
              </Menu.Item>
            </StyledNavLink>
            <StyledNavLink to={`${url}/metrics`} style={{ margin: '0' }}>
              <Menu.Item style={{ margin: '0', padding: '8px 0', flexDirection: 'column' }}>
                <Icon size={20} icon="grid24" color="#fff" />
                <Text style={{ fontSize: '10px', marginBottom: '0' }}>Metrics</Text>
              </Menu.Item>
            </StyledNavLink>
            <StyledNavLink to={`${url}/settings/users`} style={{ margin: '0' }}>
              <Menu.Item style={{ margin: '0', padding: '8px 0', flexDirection: 'column' }}>
                <Icon size={20} icon="settingsSolid" color="#fff" />
                <Text style={{ fontSize: '10px', marginBottom: '0' }}>Settings</Text>
              </Menu.Item>
            </StyledNavLink>
          </Menu>
        </Menu.Container>

        {/*<Menu.Container>
          <Menu>
            <Menu.Item>
              <IconButton size={18} icon="userFilled" color="#eee" to="/" />
            </Menu.Item>
            <Menu.Item>
              <Icon size={18} icon="dashboardFilled" color="#eee" /> <Link to="/projects">Feed</Link>
            </Menu.Item>
            <Menu.Item>
              <Icon size={18} icon="files" color="#eee" /> <Link to="/tasks">Articles</Link>
            </Menu.Item>
            <Menu.Item>
              <Icon size={18} icon="defaultFilled" color="#eee" /> <Link to="/tasks">Settings</Link>
            </Menu.Item>
          </Menu>
        </Menu.Container>
        <Menu.Container>
          <Menu>
            <Menu.Item>
              <Icon size={18} icon="logoutFilled" color="#eee" />{' '}
              <a href="#" onClick={() => logout()}>
                Logout
              </a>
            </Menu.Item> 
          </Menu>
       </Menu.Container>*/}
      </Layout.Side>
    </ThemeProvider>
  );
};

export default SideMenu;
