import React from 'react';
import StaticLayout from './Layout';
import { Heading, Text } from '@ies-ds/react-ui';

const PageDisclaimer = () => {
  return (
    <StaticLayout>
      <Heading>Disclaimer</Heading>
      <p>
        This platform is part of a project that has received funding from the European Union&rsquo;s Horizon 2020
        research and innovation programme under grant agreement No 723829. All contents reflect only the authors' views
        and the Commission is not responsible for any use that may be made of the information it contains.
      </p>

      <p>
        This collaborative design platform and, unless otherwise indicated, all rights to its contents are the sole
        property of the partners of the 4RinEU Project.
      </p>

      <p>
        In particular, the collaborative design platform website is owned and operated by Integrated Environmental
        Solutions Ltd, under specific{' '}
        <a target="_blank" href="https://www.iesve.com/legal/terms">
          Terms &amp; Conditions
        </a>
        , and in accordance to the{' '}
        <a target="_blank" href="https://www.iesve.com/legal/privacy">
          Privacy Policy
        </a>{' '}
        and the{' '}
        <a target="_blank" href="https://www.iesve.com/legal/cookie-policy">
          Cookie Policy
        </a>
        .
      </p>

      <p>
        Some contents of this collaborative design platform may also represent copyright material of those who have made
        it publicly available. The works for which the source is indicated are, therefore, the exclusive property of the
        respective rights holders and their assignees. By publishing them, Eurac Research does not transfer any rights
        to any of the contents of the collaborative design platform website.
      </p>

      <p>
        All content published or featured in this collaborative design platform, including its selection and
        organisation as well as the layout and design of the website, is protected under applicable intellectual
        property law. Eurac Research respects in all cases the intellectual property rights of others.
      </p>

      <p>
        The website and the data contained therein may be used only for personal use (e.g. for own information,
        research, study), or in any case not for commercial purposes, provided that the source (European Academy of
        Bolzano (Eurac Research) - Institute for Renewable Energy (ed.)) is mentioned.
      </p>

      <p>
        Any use for commercial purposes or economic exploitation by users is always therefore expressly prohibited. For
        commercial purposes, the use, copying and distribution of the documents and related images available on this
        website is only permitted with written permission.
      </p>
    </StaticLayout>
  );
};

export default PageDisclaimer;
