import { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      },
    },
  };
};

export const StyledNavLink = styled(NavLink)`
  &.active {
    background: #f1f4fb;
    display: block;
    color: #000 !important;
  }
  &:hover {
    text-decoration: none;
  }
`;
