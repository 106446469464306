import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ies-ui/IconButton';
import { Button, Modal, Heading, Loader, Text} from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson, fetchTypeDelete } from '../../actions/higherorder';

const ModuleDelete = ({ module, moduleId, moduleType, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = module;

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}/api/module/delete/${id}`)
      .then(data => {
        // props.fetchTypeDelete(`${moduleType}`, id);
        setModalopen(false);
        setLoading(false);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" onClick={() => openHandler()}>
          Delete
        </Button>
      )}
      <Modal isOpen={modalOpen}>
      <Heading size="2"> Delete </Heading>
        <Text style={{ color: 'red', fontWeight: '600' }}>PLEASE CAREFUL WHEN DELETING ENTRIES.</Text>
        <Text style={{ color: 'red', fontWeight: '600' }}>SOME ITEMS ARE NEEEDED FOR DEV.</Text>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button
              color="danger"
              size="small"
              onClick={() => {
                handleSubmit();
              }}
            >
              Delete {moduleType}
            </Button>
            <Button
              size="small"
              onClick={() => {
                closeHandler();
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

ModuleDelete.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTypeDelete,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDelete);
