import { combineReducers } from 'redux';
import authReducer from './auth';
import profileReducer from './profile';
import platform from './platform';
import entry_modules from './entry_modules';

export const session = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  platform,
  entry_modules,
});
