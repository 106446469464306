import React from 'react';
import { Avatar, Popover, Box, Text, Card } from '@ies-ds/react-ui';
import UpdateMembership from './UpdateMembership';
import { baseURL } from '../../settings';

import styled from 'styled-components';
import { imageUrlFetch } from '../../actions/higherorder';

const StyledTable = styled.table`
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #e1e4ea;
  width: 100%;
  background: #fff;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #545556;
    letter-spacing: 1px;
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid #e1e4ea;
    /* border-right: 1px solid #e1e4ea;*/

    :last-child {
      border-right: 0;
    }
  }
`;

const Editor = ({ data, admin, membership }) => {
  return (
    <>
      {admin ? (
        <div
          style={{
            width: '10px',
            height: '10px',
            background: '#3ed019',
            borderRadius: '100%',
            position: 'absolute',
            border: '2px solid #f4f7fe',
            top: '-3px',
            right: '3px',
          }}
        ></div>
      ) : null}

      {membership ? (
        <div
          style={{
            width: '10px',
            height: '10px',
            background: 'red',
            borderRadius: '100%',
            position: 'absolute',
            border: '2px solid #f4f7fe',
            top: '-3px',
            left: '0',
          }}
        ></div>
      ) : null}
      <Box>
        <Avatar size="large" src={`${baseURL}/api/avatar/view/${data.userId}`} alt={`${data.name}`} title={data.name} />
        {data.name}
      </Box>
    </>
  );
};

const MembershipDatatable = ({ module, moduleId, data, showAdminOnly, ...props }) => {
  if (typeof data === 'undefined') {
    return null;
  }

  return (
    <StyledTable {...props}>
      <tr>
        <th>ID</th>
        <th></th>

        <th>User</th>
        <th>Role</th>
        <th>Admin?</th>

        <th>Email</th>
      </tr>
      {data.map(item => (
        <>
          <tr>
            <td>{item.id}</td>
            <td>
              <Popover
                trigger={
                  <Avatar
                    size="small"
                    src={`${baseURL}/api/avatar/view/${item.userId}`}
                    alt={`${item.name}`}
                    title={item.name}
                  />
                }
                style={{ width: '400px' }}
              >
                <div style={{ padding: '16px' }}>
                  <Box>
                    <div style={{ flex: 1 }}>
                      <div>
                        <h3 style={{ marginBottom: '0 !important', color: '#333' }}>
                          {item.userName} {item.admin ? '(Admin)' : null}
                        </h3>
                        <span style={{ marginTop: '0 !important', color: '#333' }}>{item.role}</span> <br />
                        <span style={{ marginTop: '5px !important', color: '#333' }}>{item.userEmail}</span>
                      </div>
                    </div>
                  </Box>
                  <h3>{item.organisation}</h3>
                  <Text size="xsmall">
                    CREATE:{' '}
                    <strong>
                      {' '}
                      {item.allowCreate ? (
                        <span style={{ color: 'green' }}>YES</span>
                      ) : (
                        <span style={{ color: 'red' }}>NO</span>
                      )}{' '}
                    </strong>
                    | VIEW:{' '}
                    <strong>
                      {' '}
                      {item.allowView ? (
                        <span style={{ color: 'green' }}>YES</span>
                      ) : (
                        <span style={{ color: 'red' }}>NO</span>
                      )}{' '}
                    </strong>
                    | EDIT:{' '}
                    <strong>
                      {' '}
                      {item.allowEdit ? (
                        <span style={{ color: 'green' }}>YES</span>
                      ) : (
                        <span style={{ color: 'red' }}>NO</span>
                      )}{' '}
                    </strong>
                    | DELETE:{' '}
                    <strong>
                      {' '}
                      {item.allowDelete ? (
                        <span style={{ color: 'green' }}>YES</span>
                      ) : (
                        <span style={{ color: 'red' }}>NO</span>
                      )}{' '}
                    </strong>
                    <br />[ moduleID:{moduleId} | membershipID: {item.id} | membershipModuleID: {item.moduleId} ]
                  </Text>
                  <UpdateMembership membership={item} moduleId={item.moduleId} membershipId={item.id} />
                </div>
              </Popover>
            </td>
            <td>{item.userName}</td>
            <td> {item.role}</td>
            <td>{item.admin ? 'Admin' : null}</td>

            <td> {item.userEmail}</td>
          </tr>
        </>
      ))}
    </StyledTable>
  );

  return (
    <div {...props}>
      {data.map(item => (
        <>
          <Popover
            trigger={<Editor data={item} admin={item.admin} membership={item.moduleId == moduleId ? true : null} />}
            style={{ width: '400px' }}
          >
            <div style={{ padding: '16px' }}>
              <Box>
                <div style={{ flex: 1 }}>
                  <div>
                    <h3 style={{ marginBottom: '0 !important', color: '#333' }}>
                      {item.userName} {item.admin ? '(Admin)' : null}
                    </h3>
                    <span style={{ marginTop: '0 !important', color: '#333' }}>{item.role}</span> <br />
                    <span style={{ marginTop: '5px !important', color: '#333' }}>{item.userEmail}</span>
                  </div>
                </div>
              </Box>
              <h3>{item.organisation}</h3>
              <Text size="xsmall">
                CREATE:{' '}
                <strong>
                  {' '}
                  {item.allowCreate ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | VIEW:{' '}
                <strong>
                  {' '}
                  {item.allowView ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | EDIT:{' '}
                <strong>
                  {' '}
                  {item.allowEdit ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                | DELETE:{' '}
                <strong>
                  {' '}
                  {item.allowDelete ? (
                    <span style={{ color: 'green' }}>YES</span>
                  ) : (
                    <span style={{ color: 'red' }}>NO</span>
                  )}{' '}
                </strong>
                <br />[ moduleID:{moduleId} | membershipID: {item.id} | membershipModuleID: {item.moduleId} ]
              </Text>
              <UpdateMembership membership={item} moduleId={item.moduleId} membershipId={item.id} />
            </div>
          </Popover>
          <br />
        </>
      ))}
    </div>
  );
};

export default MembershipDatatable;
