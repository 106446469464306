export const createCustomField = (dataItem, moduleId) => {
  return {
    type: `CREATE_CUSTOMFIELD`,
    dataItem,
    moduleId,
  };
};

export const updateCustomField = dataItem => {
  return {
    type: `UPDATE_CUSTOMFIELD`,
    dataItem,
  };
};

export const deleteCustomField = item => {
  return {
    type: `DELETE_CUSTOMFIELD`,
    item,
  };
};
