import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { baseURL } from '../../../settings';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMetricLayout } from '../actions';

import { useForm } from 'react-hook-form';

import styled from 'styled-components';

import BuildForm from '../../../components/BuildForm';

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const StyledInput = styled.input`
  padding: 8px;

  font-size: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  ${props => {
    if (props.errors) {
      return `border: 1px solid red; &:focus {outline:0;} `;
    } else {
      return `border: 1px solid #ccc;`;
    }
  }}
`;

const FormLabel = ({ element }) => {
  return (
    <StyledLabel htmlFor={element.name}>
      {element.label}
      {element.required ? <span class="required">*</span> : null}
    </StyledLabel>
  );
};

const FormInput = ({ element }) => {
  return (
    <StyledInput name={`${element.name}`} ref={register({ required: element.required })} defaultValue={element.value} />
  );
};

const MetricSettings = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = values => {
    saveLayoutChange(JSON.stringify(values));
  };

  const saveLayoutChange = layout => {
    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('title', item.title);
    formData.append('description', item.description);
    formData.append('layout', layout);
    setLoading(true);

    const request = new Request(`${baseURL}/api/report/metric/edit/${module.id}/${item.id}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        closeHandler();
        props.updateMetricLayout(data.item, module.id);
      })
      .catch(function(error) {});
  };

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateMetricLayout(data.item, module.id);
    setLoading(false);
    setModalopen(false);
  };

  const checkDefaultValue = value => {
    return item.layout == '' ? 'default' : JSON.parse(item.layout)[value];
  };

  return (
    <>
      {item.links.edit ? (
        <>
          {trigger ? (
            <div onClick={() => openHandler()}>{trigger}</div>
          ) : (
            <Button size="small" onClick={() => openHandler()}>
              Edit Settings [{item.id}]
            </Button>
          )}
          <Modal isOpen={modalOpen}>
            <a
              style={{ position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', color: '#a7a7a7' }}
              onClick={() => closeHandler()}
            >
              x
            </a>
            {error}
            {loading ? (
              <Loader />
            ) : (
              <>
                <h2>Panel Configuration</h2>
                <p>Update the panel configuration settings. </p>
                {/*  <BuildForm
                  apiURL={`/api/report/metric/edit/${module.id}/${item.id}`}
                  onSuccess={onSuccess}
                  completeAction={() => closeHandler()}
                  submitBtnLabel={`Update Metric`}
                  completeBtnLabel={'Close'}
                  formDataMethodFix={false}
                />
                */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <FormLabel element={{ label: 'Panel Type', required: false }} />
                    <select
                      name={'panelType'}
                      ref={register({ required: false })}
                      // required={d.required}

                      defaultValue={checkDefaultValue('panelType')}
                      style={{ width: '100%', padding: '10px 2px', fontSize: '16x' }}
                    >
                      <option value="" disabled selected>
                        Select Panel Type
                      </option>
                      <option value="default">Default</option>
                      <option value="min">Min Value</option>
                      <option value="max">Max Value</option>
                      <option value="avg">Average Value</option>
                      <option value="tot">Total Value</option>

                      <option value="bar">Bar</option>
                      <option value="table">Table</option>
                      <option value="all">Full Table</option>
                      <option value="datatable">Datatable</option>
                    </select>
                    {/*d.help ? <Text size="xsmall">{d.help}</Text> : null*/}
                  </div>

                  <div className="field">
                    <FormLabel element={{ label: 'Backgorund Color', required: false }} />
                    <select
                      name={'bgColor'}
                      ref={register({ required: false })}
                      // required={d.required}

                      defaultValue={checkDefaultValue('bgColor')}
                      style={{ width: '100%', padding: '10px 2px', fontSize: '16x' }}
                    >
                      <option value="" disabled selected>
                        Select Bg Colour
                      </option>
                      <option value="default">White</option>
                      <option value="red">Red</option>
                      <option value="green">Green</option>
                      <option value="blue">Blue</option>
                      <option value="pink">Pink</option>
                      <option value="yellow">Yellow</option>
                    </select>
                    {/*d.help ? <Text size="xsmall">{d.help}</Text> : null*/}
                  </div>

                  <br />
                  <br />

                  <Button color="primary" type="submit">
                    Update
                  </Button>
                  <Button onClick={() => closeHandler()}>Cancel</Button>
                </form>
              </>
            )}
          </Modal>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMetricLayout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MetricSettings);
