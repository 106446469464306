import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import BuildForm from '../../components/BuildForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { addReportMetric } from '../actions';

import { fetchModule } from '../Module/actions';

const BrandSaveLogo = ({ module, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.fetchModule(module.id);
    setLoading(false);
    setModalopen(false);
  };

  if (true) {
    return (
      <>
        {trigger ? (
          <div onClick={() => openHandler()}>{trigger}</div>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Edit Image
          </Button>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Edit Image</h2>
          <p>Complete the form below to update the image. </p>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`/api/brand/save-logo/${module.id}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={`Upload Image`}
                completeBtnLabel={'Cancel'}
                formDataMethodFix={true}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      //addReportMetric,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrandSaveLogo);
