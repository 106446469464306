import React, { useEffect, useState } from 'react';
import { Switch, Route, useParams, useRouteMatch, Link } from 'react-router-dom';
import ModuleLoader from '../../components/Module/ModuleLoader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchModule } from '../../components/Module/actions';

import ArticleViewer from './Viewer';

import Step from './Step';
import Chat from './components/Chat';
import Breadcrumbs from './components/Breadcrumbs';

import { Heading, Text, Container, Box, Icon, Sidepanel } from '@ies-ds/react-ui';

import { baseURL } from '../../settings';

import { fetchJson } from '../../actions/higherorder';

const Project = ({ ...props }) => {
  const { portalId } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(portalId);
    return () => {
      {
        /*clean*/
      }
    };
  }, [portalId]);

  return (
    <>
      {props.isFetching ? (
        'Loading'
      ) : (
        <>
          <Text size="small" color="light">
            <Breadcrumbs data={props.data} />
          </Text>
          <Text component="h2" style={{ margin: '0' }}>
            {props.data.name}
          </Text>
          <Text color="light" style={{ marginBottom: '16px' }}></Text>

          <div>
            {props.data.children.map(item => {
              return (
                <Container style={{ width: '100%', marginBottom: '24px' }}>
                  <Box justifyContent="space-between">
                    <Link to={`${url}/${item.id}`}>
                      <Text component="h3">{item.name}</Text>
                    </Link>
                  </Box>
                </Container>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { portalId },
    },
  },
) => {
  const { entities } = state;
  const { isFetching, data } = entities.modules[portalId] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Project);
