import React, { useState, useEffect } from 'react';
import { baseURL } from '../../settings';
import { fetchJson } from '../../actions/higherorder';

import { NavLink, Link, useParams, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Heading, Text, Grid, Button, Loader, Box } from '@ies-ds/react-ui';

const StyledEditorContainer = styled.div`
  .wysiwyg-wrapper {
    min-height: 300px;
  }
  .editor-panel {
    min-height: 280px;
    padding: 0 12px 16px 12px;
    &:hover {
      cursor: text;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  &:hover {
    cursor: pointer;
    color: #ccc;
    text-decoration: underline;
  }
`;

const ArticleReader = ({ module, backURL, ...props }) => {
  const { articleId } = useParams();
  let { path, url } = useRouteMatch();
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchArticle(`${baseURL}/api/article/view/${module.id}/${articleId}`);
    return () => {
      /*cleanup*/
    };
  }, [articleId]);

  const fetchArticle = apiURL => {
    fetchJson(apiURL)
      .then(data => {
        setLoading(false);
        setData(data.item);
        updateContent(data.item.content);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateContent = data => {
    const output = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
    setEditorState(output);
  };

  const [editorState, setEditorState] = useState(null);

  return (
    <>
      <div style={{ position: 'fixed', background: 'rgba(0,0,0,0.7)', top: 0, left: 0, right: 0, bottom: 0 }}>
        <div
          style={{
            position: 'relative',

            top: '10px',
            maxWidth: '1200px',

            padding: '0',
            margin: '16px auto',
            color: '#fff',
          }}
        >
          <StyledLink to={backURL}>X Close</StyledLink>
        </div>

        <div
          style={{
            position: 'relative',
            background: '#fff',
            top: '8px',
            maxWidth: '1200px',
            bottom: '0px',
            overflow: 'auto',
            padding: '32px 32px 64px 32px',
            margin: '16px auto',
            height: '100%',
          }}
        >
          <Grid>
            <div item style={{ alignItems: 'center' }}>
              {loading ? (
                <>
                  <Loader type="dots" />
                  Loading...
                </>
              ) : (
                <>
                  <Heading size={2}>{data.title}</Heading>

                  <StyledEditorContainer style={{ background: '#fff', padding: '0px', border: '0' }}>
                    <Editor editorState={editorState} readOnly toolbarHidden />
                  </StyledEditorContainer>
                </>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ArticleReader;
