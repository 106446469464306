import { useState, useEffect } from 'react';

export { default as ModuleCustomValues } from './moduleCustomValues';
export { default as ModuleStatus } from './moduleStatus';
export { default as GetLink } from './getLink';
export { default as DeleteLink } from './deleteLink';

export const checkNodeExists = node => {
  const exsists = typeof node == 'undefined' || node <= 0 ? false : true;
  return exsists;
};

export const childCount = node => {
  const count = typeof node == 'undefined' || node <= 0 ? 0 : node.length;
  return count;
};

export const getImageURL = src => {
  const [image, setImage] = useState();

  useEffect(() => {
    imageUrlFetch(src);
  }, [src]);

  function imageUrlFetch(url) {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
      .then(response => response.blob())
      .then(images => {
        setImage(URL.createObjectURL(images));
      });
  }

  return image;
};

export const getFileURL = src => {
  const [image, setImage] = useState();

  useEffect(() => {
    imageUrlFetch(src);
  }, [src]);

  function imageUrlFetch(url) {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
      .then(response => response.blob())
      .then(images => {
        setImage(window.URL.createObjectURL(images));
      });
  }

  return image;
};
