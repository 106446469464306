import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import NoMatch from './NoMatch';

import LayoutDashboard from './LayoutDashboard';
import LayoutDefault from './LayoutDefault';
import Logout from '../containers/Auth/Logout';

import Auth from '../containers/Auth/Auth';

import LayoutDynamic from './v2/LayoutDynamic';

import Platform from './calibr/Platform';

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/logout" component={Logout} />

      <Route path="/portal/:id">
        <LayoutDashboard />
      </Route>
      <Route path="/peb">
        <Platform />
      </Route>
      <Route path="/calibr">
        <Platform />
      </Route>
      <Route path="/v2">
        <LayoutDynamic />
      </Route>
      <Route path="/">
        <LayoutDefault />
      </Route>
      <Route path="*">
        <>
          999
          <NoMatch />
        </>
      </Route>
    </Switch>
  </>
);

export default Routes;
