import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Box, Loader, Menu, Icon, Heading, Text, ThemeContainer, BaseTheme } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../settings';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Popover from '../ies-ui/Popover';
import { imageUrlFetch } from '../actions/higherorder';
import { getImageURL } from '../utils';

const StyledDropdown = styled.div`
  font-size: 14px;
  border: 1px solid #e1e4ea;
  position: absolute;
  top: 40px;
  right: 20px;
  /*width: 240px;*/
  background: #fff;
  box-shadow: 0 3px 9px 0px rgba(80, 80, 80, 0.5);
  /* padding: 16px;*/

  display: block;
  a {
    color: #333;
  }
`;

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    callback();
    /* if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }*/
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });
};

const GetAvatar = ({ src, size }) => {
  return <Avatar size={size} src={getImageURL(src)} />;
};

const AccountBadge = props => {
  const [visibility, setVisibility] = useState(false);
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (visible) setVisible(false);
  });

  const { loading, data } = props.profile;

  return (
    <>
      {loading ? (
        <Loader size="small" type="dots" />
      ) : (
        <Box justifyContent="flex-end" style={{ position: 'relative' }} onClick={() => setVisible(!visible)}>
          <GetAvatar src={`${baseURL}${data.avatar}`} />
          {visible && (
            <ThemeContainer theme={BaseTheme}>
              <Popover ref={ref}>
                <Menu.Container>
                  <Box justifyContent="space-between">
                    <GetAvatar size={'xlarge'} src={`${baseURL}${data.avatar}`} />
                    <div>
                      <h2 style={{ marginBottom: '0 !important', color: '#333' }}>{data.name}</h2>
                      <span style={{ marginTop: '0 !important', color: '#333' }}>{data.username}</span>
                    </div>
                  </Box>
                </Menu.Container>
                <Menu.Container>
                  <Menu>
                    <Menu.Item>
                      <Icon size={18} icon="userFilled" color="#555" /> <Link to="/profile">My Account</Link>
                    </Menu.Item>
                    <Link to="/profile">
                      <Menu.Item>
                        <Icon size={18} icon="userFilled" color="#555" /> Edit Profile
                      </Menu.Item>
                    </Link>
                    <Menu.Item>
                      <Icon size={18} icon="widgetsFilled" color="#555" /> <Link to="/">My Portals</Link>
                    </Menu.Item>
                  </Menu>
                </Menu.Container>
                <Menu.Container>
                  <Menu>
                    <Link to="/logout">
                      <Menu.Item>
                        <Icon size={18} icon="logoutFilled" color="#555" /> Logout
                      </Menu.Item>
                    </Link>
                  </Menu>
                </Menu.Container>
              </Popover>
            </ThemeContainer>
          )}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.session.profile,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //  fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountBadge);
