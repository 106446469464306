import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';
import { Loader } from '@ies-ds/react-ui';
import StatusBadge from '../../StatusBadge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchModule } from '../actions';
import { DeliverableEdit } from '../../Deliverable';
import moment from 'moment';
import { Text, Avatar } from '@ies-ds/react-ui';

import styled from 'styled-components';
import { checkNodeExists, getImageURL } from '../../../utils';

const StyledLink = styled.div`
  color: #4d58e8;
  ${props => (props.add ? `color: #4d58e8` : `color: #000`)}
  display: inline-block;
  padding: 1px;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: #333;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ConnectDeliverable = ({ module, type, ...props }) => {
  return checkNodeExists(props.isFetching || props.data.deliverable) ? (
    <>
      {props.isFetching ? null : (
        <DeliverableEdit
          type={type}
          module={props.data}
          trigger={
            <StyledLink>
              {type == 'owner' ? (
                <Avatar
                  size="small"
                  src={getImageURL(`${baseURL}/api/avatar/view/${props.data.deliverable.ownerId}`)}
                  title={`${props.data.deliverable.ownerName}`}
                />
              ) : (
                <Text size={'xsmall'}>{moment(props.data.deliverable[type]).format('Do MMM YYYY')}</Text>
              )}
            </StyledLink>
          }
        />
      )}
    </>
  ) : null;
};

const mapStateToProps = (state, myProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[myProps.module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    children,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectDeliverable);
