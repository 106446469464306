import React from 'react';
import ModuleLoader from '../../components/Module/ModuleLoader';

//ROUTING
import { useParams } from 'react-router-dom';

const ModuleConainter = ({ moduleId, ...props }) => {
  const { id } = useParams();

  return (
    <>
      <ModuleLoader moduleId={id} />
    </>
  );
};

export default ModuleConainter;
