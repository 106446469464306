import React, { useEffect } from 'react';
import { Heading, Text, Box, Grid, Button } from '@ies-ds/react-ui';
import { GetLink, DeleteLink } from './';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';

import { EditCustomField, DeleteCustomField, AddCustomField } from '../components/CustomField';

import { fetchJson } from '../actions/higherorder';
import { baseURL } from '../settings';
import BuildCFForm from './BuildCFForm';

const height = [0, '100px', '150px', '200px', '250px', '300px', '350px'];

const CFCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e4ea;
  background: #fff;
  height: ${props => height[props.size]};
  padding: 16px;
  h1 {
    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledTable = styled.table`
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #e1e4ea;
  width: 100%;
  background: #fff;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #545556;
    letter-spacing: 1px;
    background: #f9f9f9;
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid #e1e4ea;
    /* border-right: 1px solid #e1e4ea;*/

    :last-child {
      border-right: 0;
    }
  }
`;

const ModuleCustomValues = ({ module }) => {
  const { customFields } = module;

  return (
    <>
      <Text size={'xsmall'}>[TEST COMPONENT] </Text>
      <Heading size={3}>Custom Values</Heading>
      <br />

      {Object.keys(customFields).map(key => {
        const item = customFields[key];
        {
          switch (item.type) {
            case '--TEXT':
              return (
                <>
                  <Grid container fullwidth>
                    <Grid item sm={3}>
                      <SimpleCard item={item} module={module} size={3} />
                    </Grid>
                    <Grid item sm={4}>
                      <SimpleCard item={item} module={module} size={3} />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <CustomFormTable item={item} module={module} />
                </>
              );
            case '---DATE ':
              return <CustomFormTable item={item} module={module} />;
            default:
              return <CustomFormTable item={item} module={module} />;
          }
        }
      })}

      {/*
      <BuildCFForm
        module={module}
        apiURL={`${module.links.edit_custom_values.url}`}
        //onSuccess={onSuccess}
        //completeAction={() => closeHandler()}
        submitBtnLabel={'Update'}
        completeBtnLabel={'Close'}
        formDataMethodFix={false}
      />

      <GetLink module={module} data={{ url: `/api/custom-field/add/${module.id}`, label: 'Add CF' }} />
      <GetLink
        module={module}
        data={{ url: `/api/custom-field/view/${module.id}/23`, label: 'View CF 23 [no click - test only]' }}
      />
      <GetLink
        module={module}
        data={{ url: `/api/custom-field/list/${module.id}/Module`, label: 'List CF [no click - test only]' }}
      />
      <GetLink
        module={module}
        data={{ url: `/api/custom-field/edit/23`, label: 'Edit CF 23 [no click - test only]' }}
      />
      */}
    </>
  );
};

export default ModuleCustomValues;

/////

const SimpleCard = ({ item, module, size }) => {
  return (
    <CFCard size={size}>
      <div>{item.label}</div>
      <div>
        {item.values.map(item => {
          return (
            <EditCustomField
              module={module}
              item={item}
              trigger={
                <Heading className="link" size={1}>
                  {item.values.stringval}
                </Heading>
              }
            />
          );
        })}
      </div>
    </CFCard>
  );
};

///// BUILD TABLE

const CustomFormTable = ({ item, module }) => {
  const noHeader = item.type === 'PICKLIST' || item.type === 'TEXT' ? false : null;
  const stingVal = item.type !== 'PICKLIST' ? true : null;
  const dateVal = item.type === 'DATED_NUMERIC' || item.type === 'DATE' ? true : null;
  const optionLabel = item.type === 'PICKLIST' ? true : null;
  const numval = item.type === 'NUMERIC' || item.type === 'DATED_NUMERIC' ? true : null;
  return (
    <>
      <div style={{ border: '0px solid #eee', marginBottom: '16px', maxWidth: '900px' }}>
        <Box justifyContent={'space-between'}>
          <Heading size={4}>{item.label}</Heading>
          <Text size={'xsmall'}>
            Type: {item.type} | Multiple: {item.multiple ? 'Yes' : 'No'}
          </Text>
        </Box>

        <StyledTable>
          {noHeader ? (
            <tr>
              {dateVal ? <th>Date</th> : null}
              {stingVal ? <th>Description</th> : null}
              {optionLabel ? <th>Selected</th> : null}
              {numval ? <th>Value</th> : null}

              <th></th>
            </tr>
          ) : null}
          {item.values.map(data => {
            return (
              <tr>
                {dateVal ? (
                  <td style={{ minWidth: '150px', width: '20%' }}>
                    {moment(data.values.dateval).format('Do MMM YYYY')}
                  </td>
                ) : null}
                {stingVal ? <td style={{ minWidth: '50px', width: '20%' }}>{data.values.stringval}</td> : null}
                {optionLabel ? <td style={{ minWidth: '50px', width: '20%' }}> {data.optionLabel}</td> : null}
                {numval ? (
                  <td style={{ minWidth: '50px', width: '20%' }}>
                    {item.prefixSymbol ? item.symbol : null}
                    {data.values.numval}
                    {!item.prefixSymbol ? item.symbol : null}
                  </td>
                ) : null}

                <td style={{ minWidth: '150px', width: '20%' }}>
                  <Box justifyContent={'flex-end'} gap={4}>
                    <Text size={'xsmall'}>[USER ID: {data.userId}]</Text>

                    <EditCustomField module={module} item={data} trigger={<Button size={'xsmall'}>Edit</Button>} />
                    <DeleteCustomField module={module} item={data} trigger={<Button size={'xsmall'}>Delete</Button>} />
                  </Box>
                </td>
              </tr>
            );
          })}
          {true ? createLinks(item, module) : null}
        </StyledTable>
      </div>
    </>
  );
};

const createLinks = (item, module) => {
  {
    return typeof item.links == 'undefined'
      ? null
      : Object.keys(item.links).map(function(key) {
          switch (item.links[key].access) {
            case 'edit':
              return (
                <tr>
                  <td colspan={'5'}>
                    <AddCustomField
                      module={module}
                      item={item.links[key]}
                      trigger={
                        <Button color={'primary'} size={'xsmall'}>
                          Add {item.label}
                        </Button>
                      }
                    />
                  </td>
                </tr>
              );
          }
        });
  }
};
