import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Loader, Text } from '@ies-ds/react-ui';
import { baseURL } from '../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson } from '../actions/higherorder';
import { updateModule } from '../components/Module/actions';

import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const DeleteLink = ({ module, data, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = module;

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}${data.url}`)
      .then(data => {
        closeHandler();
        props.updateModule(id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  if (module.links.delete) {
    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <StyledLink size="small" onClick={() => openHandler()}>
            {data.label}
          </StyledLink>
        )}
        <Modal isOpen={modalOpen}>
          <h2>[{data.label}]</h2>
          <Text style={{ color: 'red', fontWeight: '600' }}>PLEASE CAREFUL WHEN DELETING ENTRIES.</Text>
          <Text style={{ color: 'red', fontWeight: '600' }}>ARE YOU SURE YOU WANT TO DELETE THIS.</Text>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Button
                color="danger"
                size="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {data.label}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  closeHandler();
                }}
              >
                No, Don't Delete
              </Button>
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => bindActionCreators({ updateModule }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLink);
