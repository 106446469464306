import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchModule } from '../../../../components/Module/actions';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './Column';

import { baseURL } from '../../../../settings';
import { fetchJson } from '../../../../actions/higherorder';

import { Container } from '@ies-ds/react-ui';

const column = {
  tasks: {
    'item-7': { id: 'item-7', name: 'Task 1' },
    'item-11': { id: 'item-11', name: 'Task Two' },
    'item-18': { id: 'item-18', name: 'Third Task' },
    'item-181': { id: 'item-181', name: 'Third Task111' },
  },
  columns: {
    'column-one': {
      id: 'column-one',
      title: 'Sortable List',
      taskIds: ['item-11', 'item-7', 'item-18', 'item-181'],
    },
  },
  columnOrder: ['column-one'],
};

const emptyArr = {
  tasks: {},
  columns: {
    'column-one': {
      id: 'column-one',
      title: 'Sortable List',
      taskIds: [],
    },
  },
  columnOrder: ['column-one'],
};

const SortableModules = ({ data, ...props }) => {
  const [drag, setDrag] = useState(emptyArr);
  const [dragDisabled, setDragDisabled] = useState(false);

  useEffect(() => {
    buildJson(data);
    return () => {};
  }, [data.children]);

  const updatePosition = (module, moduleAfter) => {
    fetchJson(`${baseURL}/api/module/reindex/${module}/${moduleAfter}`, {
      method: 'GET',
    })
      .then(d => {
        props.fetchModule(data.id);
        setDragDisabled(false);
      })
      .catch(function(error) {});
  };

  function buildJson(data) {
    var ids = [];
    var modules = [];

    data.children.map(data => {
      ids.push(`item-${data.id}`);
    });

    data.children.forEach(function(value) {
      modules[`item-${value.id}`] = { id: `item-${value.id}`, name: value.name, result: value };
    });

    const newColumnData = {
      ...emptyArr,
      tasks: modules,
      columns: {
        ...emptyArr.columns,
        'column-one': {
          ...emptyArr.columns['column-one'],
          taskIds: ids,
        },
      },
    };

    return setDrag(newColumnData);
  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.draggableId === source.draggableId && destination.index === source.index) {
      return;
    }

    setDragDisabled(true);

    const column = drag.columns[source.droppableId];
    const newTaskIds = Array.from(column.taskIds);
    newTaskIds.splice(source.index, 1);
    newTaskIds.splice(destination.index, 0, draggableId);

    const newColumn = {
      ...column,
      taskIds: newTaskIds,
    };

    const newState = {
      ...drag,
      columns: {
        ...drag.columns,
        [newColumn.id]: newColumn,
      },
    };

    let destIdx = source.index < destination.index ? destination.index : destination.index - 1;

    updatePosition(
      column.taskIds[source.index].replace('item-', ''),
      destination.index == 0 ? null : column.taskIds[destIdx].replace('item-', ''),
    );

    setDrag(newState);
  };

  const columns = drag.columns['column-one'];
  const tasks = columns.taskIds.map(taskId => drag.tasks[taskId]);

  return (
    <Container>
      <DragDropContext onDragEnd={result => onDragEnd(result)}>
        {/*drag.columns['column-one'].title*/}

        <Column
          key={'column-one'}
          column={drag.columns['column-one']}
          module={data}
          children={tasks}
          isDragDisabled={dragDisabled}
        />
      </DragDropContext>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SortableModules);
