import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Box, Loader, Text, Textfield, Modal } from '@ies-ds/react-ui';
import { baseURL } from '../settings';
import { TextInput, Checkbox, Select, Option } from '../ies-ui/form/';
import { checkStatus, fetchJson } from '../actions/higherorder';

import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const StyledInput = styled.input`
  padding: 8px;

  font-size: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  ${props => {
    if (props.errors) {
      return `border: 1px solid red; &:focus {outline:0;} `;
    } else {
      return `border: 1px solid #ccc;`;
    }
  }}
`;

const FormLabel = ({ element }) => {
  return (
    <StyledLabel htmlFor={element.name}>
      {element.label}
      {element.required ? <span class="required">*</span> : null}
    </StyledLabel>
  );
};

const FormInput = ({ element }) => {
  return (
    <StyledInput name={`${element.name}`} ref={register({ required: element.required })} defaultValue={element.value} />
  );
};

const BuildForm = ({
  apiURL,
  onSuccess,
  completeAction,
  submitBtnLabel,
  completeBtnLabel,
  method,
  formDataMethodFix,
}) => {
  const { handleSubmit, register, errors, setError } = useForm();
  const [formdata, setFormdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSuccess(false);
    setErrorMessage('');
    console.log('api url: ' + apiURL);
    fetchForm(apiURL);
  }, []);

  const fetchForm = apiURL => {
    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        setFormdata(data);
        setLoading(false);
      })
      .catch(function(error) {
        setSuccess(false);
        setErrorMessage('Error: ' + error);
        console.log(error);
      });
  };

  const onSubmit = values => {
    setUpdating(true);
    setSuccess(false);
    setErrorMessage('');

    const formData = new FormData();
    formDataMethodFix ? null : formData.append('_method', formdata.method);
    formdata.elements.map(elemt => {
      formData.append(elemt.name, typeof values[elemt.name] === 'object' ? values[elemt.name][0] : values[elemt.name]);
    });
    const request = new Request(`${baseURL}${formdata.action}`, {
      method: formDataMethodFix ? formdata.method : 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(checkStatus)
      .then(r => r.json())
      .then(data => {
        setUpdating(false);
        setLoading(false);
        setSuccess(true);
        onSuccess(data);
      })
      .catch(function(error) {
        setSuccess(false);
        setErrorMessage('' + error);
        setUpdating(false);
        setLoading(false);
        if (error.response && error.response.status === 422) {
          error.response.json().then(data => {
            if (data.elements) {
              data.elements.map(d => {
                if (d.errors) {
                  d.errors.forEach(error => {
                    setError(d.name, 'notMatch', error);
                  });
                }
              });
              setErrorMessage('');
            }
          });
        }
      });
  };

  const validation = attr => {
    attr.map(obj => {
      let rObj = {};
      rObj[obj.key] = obj.value;
      return rObj;
    });
  };

  if (loading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content>
        <div style={{ maxHeight: '500px', overflow: 'auto', padding: '0 15px 0 2px' }}>
          {formdata.elements.map(d => {
            if (d.type === 'compound') {
              return null;
            }
            if (d.type === 'hidden') {
              return <input type={d.type} name={d.name} value={d.value} ref={register} />;
            }
            if (d.type === 'text') {
              const iname = d.name;

              return (
                <div className="field">
                  <Textfield
                    name={`${d.name}`}
                    ref={register({ required: d.required })}
                    defaultValue={d.value}
                    errors={errors[d.name]}
                    disabled={d.disabled}
                    label={d.label}
                    required={d.required}
                  />
                  {errors[d.name] && (
                    <StyledErrorLabel>
                      {errors[d.name].message ? errors[d.name].message : 'Input is required'}
                    </StyledErrorLabel>
                  )}
                  {d.help ? (
                    <Text size="xsmall" style={{ color: '#7d8ca2' }}>
                      {d.help}
                    </Text>
                  ) : null}
                </div>
              );
            }
            if (d.type === 'date') {
              return (
                <div className="field">
                  <Textfield
                    type={d.type}
                    name={`${d.name}`}
                    ref={register({ required: d.required })}
                    defaultValue={d.value}
                    errors={errors[d.name]}
                    disabled={d.disabled}
                    label={d.label}
                    required={d.required}
                  />
                  {errors[d.name] && (
                    <StyledErrorLabel>
                      {errors[d.name].message ? errors[d.name].message : 'Date is required'}
                    </StyledErrorLabel>
                  )}
                  {d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            }
            if (d.type === 'email') {
              return (
                <div className="field">
                  <Textfield
                    type={d.type}
                    name={`${d.name}`}
                    ref={register({ required: d.required })}
                    defaultValue={d.value}
                    errors={errors[d.name]}
                    disabled={d.disabled}
                    label={d.label}
                    required={d.required}
                  />
                  {errors[d.name] && (
                    <StyledErrorLabel>
                      {errors[d.name].message ? errors[d.name].message : 'Email is required'}
                    </StyledErrorLabel>
                  )}
                  {d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            }
            if (d.type === 'file') {
              return (
                <div className="field">
                  <StyledInput
                    type="file"
                    name={`${d.name}`}
                    ref={register({ required: d.required })}
                    defaultValue={d.value}
                    errors={errors[d.name]}
                    disabled={d.disabled}
                    label={d.label}
                    required={d.required}
                  />
                  {errors[d.name] && (
                    <StyledErrorLabel>
                      {errors[d.name].message ? errors[d.name].message : 'File is required'}
                    </StyledErrorLabel>
                  )}
                  {d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            }
            if (d.type === 'select') {
              return (
                <div className="field">
                  <FormLabel element={d} />
                  <select
                    name={d.name}
                    ref={register({ required: d.required })}
                    required={d.required}
                    style={{ width: '100%', padding: '10px 2px', fontSize: '16x' }}
                  >
                    <option value="" disabled selected>
                      {d.placeholder}
                    </option>
                    {d.options.map(d => {
                      return (
                        <option value={d.value} selected={d.selected}>
                          {d.label}
                        </option>
                      );
                    })}
                  </select>
                  {d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            }
            if (d.type === 'textarea') {
              return (
                <div className="field">
                  <FormLabel element={d} />
                  <br />
                  <textarea
                    name={`${d.name}`}
                    ref={register({ required: d.required })}
                    defaultValue={d.value}
                    errors={errors[d.name]}
                    disabled={d.disabled}
                    style={{ width: '90%', minHeight: '85px', border: '1px solid #d7dde4', background: '#f7f9fc' }}
                  />
                  {errors[d.name] && (
                    <StyledErrorLabel>
                      {errors[d.name].message ? errors[d.name].message : 'Input is required'}
                    </StyledErrorLabel>
                  )}
                  {d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            } else {
              return (
                <div className="field">
                  <FormLabel element={d} />
                  [TODO: ADD {d.type}]{d.help ? <Text size="xsmall">{d.help}</Text> : null}
                </div>
              );
            }
          })}
          {success ? <Text style={{ color: 'green' }}>Data Transfer Successful</Text> : null}
          <Text style={{ color: 'red' }}>{errorMessage}</Text>
          {updating ? (
            <Text style={{ color: 'green' }}>
              <Loader type="dots" /> Sending Data{' '}
            </Text>
          ) : null}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Box justifyContent={'flex-start'}>
          <Button color="primary" type="submit" size="small">
            {submitBtnLabel ? submitBtnLabel : 'Submit'}
          </Button>
          {completeAction ? (
            <Button size="small" onClick={completeAction}>
              {completeBtnLabel ? completeBtnLabel : 'Close'}
            </Button>
          ) : null}
        </Box>
      </Modal.Footer>
    </form>
  );
};

export default BuildForm;
