import React from 'react';
import { ThemeContainer, Layout, Text, Loader } from '@ies-ds/react-ui';

const LoadingOverlay = () => {
  return (
    <ThemeContainer>
      <Layout
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Loader />
        <Text size="small">Loading...</Text>
      </Layout>
    </ThemeContainer>
  );
};

export default LoadingOverlay;
