import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchDeliverable = (moduleId, moduleType) => {
  return dispatch => {
    dispatch({
      type: `FETCH_DELIVERABLE`,
    });

    fetchJson(`${baseURL}/api/deliverable/find/${moduleId}`)
      .then(data => {
        dispatch(fetchDeliverableSuccess(data.item));
        dispatch(insertDeliverableEntity(moduleType, data.item));
      })
      .catch(error => {
        dispatch(fetchDeliverableFailure(error));
      });
  };
};

export const insertDeliverableEntity = (module, data) => {
  return {
    type: `INSERT_DELIVERABLE_ENTITY`,
    payload: {
      data,
      loading: false,
    },
    module,
  };
};

export const refeshDeliverable = moduleId => {
  return dispatch => {
    dispatch({
      type: `REFRESH_DELIVERABLE`,
    });

    fetchJson(`${baseURL}/api/deliverable/find/${moduleId}`)
      .then(data => {
        dispatch(fetchDeliverableSuccess(data.item));
      })
      .catch(error => {
        dispatch(fetchDeliverableFailure(error));
      });
  };
};

export const fetchDeliverableSuccess = data => {
  return {
    type: `FETCH_DELIVERABLE_SUCCESS`,
    data,
  };
};

export const fetchDeliverableFailure = error => {
  return {
    type: `FETCH_DELIVERABLE_FAILURE`,
    error,
  };
};

export const fetchDeliverableReset = () => {
  return {
    type: `FETCH_DELIVERABLE_RESET`,
  };
}; /*
export const updateDeliverable = data => {
  return {
    type: `UPDATE_DELIVERABLE`,
    data,
  };
};*/ /*
export const updateCommentList = data => {
  return {
    type: `UPDATE_COMMENT_LIST`,
    data,
  };
};
*/

/*
export const insertComment = data => {
  return {
    type: `INSERT_COMMENT`,
    data,
  };
};

export const deleteComment = data => {
  return {
    type: `DELETE_COMMENT`,
    data,
  };
};
*/ ///* REDUCES TO MODULES

export const createDeliverable = (dataItem, moduleId) => {
  return {
    type: `CREATE_DELIVERABLE`,
    dataItem,
    moduleId,
  };
};

export const updateDeliverable = dataItem => {
  return {
    type: `UPDATE_DELIVERABLE`,
    dataItem,
    moduleId,
    componentType,
  };
};
