import React, { useState, useEffect } from 'react';
import { Grid, Card, Layout, Loader, Text, Heading } from '@ies-ds/react-ui';
import { baseURL } from '../settings';
import { useParams, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import { fetchJson } from '../actions/higherorder';
import { ReportMetricAdd, ReportMetricList } from '../components/Report/Metric';
import { ReportDashboard } from '../components/Report';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchReportsList, fetchReportsReset } from '../components/Report/actions';
import { fetchModule } from '../components/Module/actions';
const Metrics = ({ ...props }) => {
  const { id } = useParams();

  useEffect(() => {
    // REPORT
    props.fetchModule(id);
    return () => {
      props.fetchReportsReset();
    };
  }, []);

  return (
    <Layout.Content>
      <h3>Portal Metrics</h3>
      {props.isFetching ? 'Loading' : <ReportDashboard module={props.data} />}
    </Layout.Content>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    doesExsist,
    lastUpdated,

    entities: state.entities,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchReportsList,
      fetchReportsReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
