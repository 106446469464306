import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../actions/auth';

const Logout = props => {
    console.log("logout");
    useEffect(() => {
        props.onLogout();
    }, []);

    return <Redirect to="/" />;
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.receiveLogout())
    };
};

export default connect(
    null,
    mapDispatchToProps
)(Logout);