import React, { useState } from 'react';
import { Button, Modal, Heading, Loader, Text } from '@ies-ds/react-ui';
import { baseURL } from '../../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson } from '../../../actions/higherorder';

import { deleteMetric } from '../actions';

const MetricDelete = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}/api/report/metric/delete/${module.id}/${item.id}`, {
      method: 'DELETE',
    })
      .then(data => {
        setModalopen(false);
        setLoading(false);
        props.deleteMetric(item.id, module.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {item.links.delete ? (
        <>
          {trigger ? (
            <span onClick={() => openHandler()}>{trigger}</span>
          ) : (
            <Button size="small" onClick={() => openHandler()}>
              Delete
            </Button>
          )}
          <Modal isOpen={modalOpen}>
            <h2> Delete </h2>
            <Text>Do you want to delete the following panel?</Text>
            <Heading size="4">{item.title}</Heading>
            {loading ? (
              <Loader />
            ) : (
              <>
                <br />
                <br />
                <Button color="primary" type="submit" size="small" onClick={() => handleSubmit()}>
                  Yes, Delete
                </Button>
                <Button size="small" onClick={() => closeHandler()}>
                  No, Cancel
                </Button>
              </>
            )}
          </Modal>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteMetric,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MetricDelete);
