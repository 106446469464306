import * as actionTypes from '../../actions/actionTypes';
import update from 'immutability-helper';

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

const initialState = {
  data: {},
  error: null,
  loading: true,
};

const profileGet = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const profileGetSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    error: null,
    loading: false,
  });
};

const profileGetFail = (state, action) => {
  return updateObject(state, {
    data: {},
    error: action.error,
    loading: false,
  });
};

const profileReset = (state, action) => {
  return updateObject(state, {
    data: {},
    loading: false,
    error: null,
  });
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_GET:
      return profileGet(state, action);
    case actionTypes.PROFILE_GET_SUCCESS:
      return profileGetSuccess(state, action);
    case actionTypes.PROFILE_GET_FAIL:
      return profileGetFail(state, action);
    case actionTypes.PROFILE_RESET:
      return profileReset(state, action);
    default:
      return state;
  }
};

export default profileReducer;
