import React from 'react';
import { Text, Button } from '@ies-ds/react-ui';
import { checkNodeExists } from '../../../utils';
import StatusBadge from '../../../components/StatusBadge';
import { DeliverableAdd, DeliverableEdit } from '../../Deliverable';
import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const Status = ({ module }) => {
  const { deliverable } = module;

  return (
    <>
      {checkNodeExists(deliverable) ? (
        <>
          {deliverable.links.add_deliverable ? (
            <DeliverableAdd
              module={module}
              moduleType={'PROJECT'}
              trigger={<Button size="xsmall" color="success" suffixIcon="add24">Add Status</Button>}
            />
          ) : (
            <>
              {deliverable.links.find && deliverable.links.edit ? (
                <DeliverableEdit
                  module={module}
                  moduleType={'PROJECT'}
                  trigger={
                    <StatusBadge size={'small'} status={deliverable.status.name}>
                      {deliverable.status.label}
                    </StatusBadge>
                  }
                />
              ) : (
                <StatusBadge disabled size={'small'} status={deliverable.status.name}>
                  {deliverable.status.label}
                </StatusBadge>
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default Status;
