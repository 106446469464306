import React from 'react';
import styled from 'styled-components';
import { bool, node, object } from 'prop-types';

const StyledPopover = styled.div`
  font-size: 14px;
  border: 1px solid #e1e4ea;
  position: absolute;
  top: 30px;
  right: 20px;
  min-width: 100px;
  background: #fff;
  box-shadow: 1px 2px 4px 0px rgba(80, 80, 80, 0.4);
  z-index: 1;
  /*padding: 16px;*/

  ${props => (props.show ? `display: block` : `display: none`)}

  a {
    color: #333;
  }
`;

const Popover = ({ trigger, children, show, ...props }) => {
  return (
    <StyledPopover show={show} {...props}>
      {children}
    </StyledPopover>
  );
};

Popover.propTypes = {
  children: node,
  show: bool,
  trigger: object,
};

Popover.defaultProps = {
  show: true,
};

export default Popover;
