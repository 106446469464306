import React from 'react';
import { Box } from '@ies-ds/react-ui';
import { InviteUser, MemberList, MembershipAdd } from '../../Membership';

import styled from 'styled-components';

const StyledAdd = styled.div`
  width: 28px;
  height: 28px;
  background: #dbdee2;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  color: #9e9e9e;
  transition: color 0.32s ease;
  &:hover {
    color: #555;
    cursor: pointer;
  }
`;

const Members = ({ module }) => {
  return (
    <>
      <Box style={{ alignItems: 'center' }} justifyContent="flex-end">
        <MemberList module={module} moduleId={module.id} data={module.memberships} style={{ marginLeft: '16px' }} />

        {module.links.edit ? (
          <>
            <MembershipAdd module={module} trigger={<StyledAdd> + </StyledAdd>} />
          </>
        ) : null}
      </Box>
    </>
  );
};

export default Members;
