import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';
import { Button, Modal, Loader } from '@ies-ds/react-ui';

import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ModalViewer = ({ label, articleId, moduleId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchArticle(`${baseURL}/api/article/view/${moduleId}/${articleId}`);
    return () => {
      /*cleanup*/
    };
  }, [articleId]);

  const fetchArticle = apiURL => {
    fetchJson(apiURL)
      .then(data => {
        setLoading(false);
        setData(data.item);
        updateContent(data.item.content);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateContent = data => {
    const output = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
    setEditorState(output);
  };

  const [editorState, setEditorState] = useState(null);

  return (
    <>
      <a
        href="javascript:void(0)"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {label}
      </a>
      <Modal
        size="xlarge"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        closeButton
      >
        {loading ? (
          <>
            <Loader type="dots" />
            Loading...
          </>
        ) : (
          <>
            <Modal.Header title={data.title} />
            <Modal.Content>
              <Editor editorState={editorState} readOnly toolbarHidden />
            </Modal.Content>
            <Modal.Footer>
              {' '}
              <Button onClick={() => setIsOpen(false)} icon="close">
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalViewer;
