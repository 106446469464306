import React, { useState } from 'react';
import { Button, Modal, Loader, Text, Heading, Popover } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson, postForm } from '../../actions/higherorder';
import { deleteComment } from './actions';
import { baseURL } from '../../settings';
import styled from 'styled-components';
import { checkStatus } from '../../api/user_api';

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: blue;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const CommentDelete = ({ url, trigger, module, moduleId, moduleType, fileName, item, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
    setSuccess(true);
    setMessage('');
  };

  const sendHandler = () => {
    setMessage('');
    setSending(true);
    postForm(`${baseURL}${item.links.delete.url}}`, 'DELETE')
      .then(() => {
        props.deleteComment(item.id, module.id);
        closeHandler();
      })
      .catch(error => {
        console.log(error);
        setSending(false);
        setSuccess(false);
        setMessage('There has been error deleting the comment. Please try again');
      });
  };

  if (item.links.delete) {
    return (
      <>
        {module.locked ? (
          <Popover trigger={trigger}>
            <p>File is locked </p>
          </Popover>
        ) : (
          <div onClick={() => openHandler()}>{trigger}</div>
        )}
        <Modal isOpen={modalOpen}>
          <h2>Delete Comment</h2>

          {loading ? (
            <Loader />
          ) : (
            <>
              <Text>Do you want to delete the comment?</Text>
              {sending && (
                <Text style={{ color: 'green' }}>
                  <Loader type="dots" /> Deleting File{' '}
                </Text>
              )}
              {success ? <StyledLabel>{message}</StyledLabel> : <StyledErrorLabel>{message}</StyledErrorLabel>}

              <br />
              <br />
              <Button color="primary" type="submit" size="small" onClick={() => sendHandler()}>
                Yes, Delete
              </Button>
              <Button size="small" onClick={() => closeHandler()}>
                No, Cancel
              </Button>
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteComment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommentDelete);
