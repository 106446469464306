import styled from 'styled-components';

const StyledTable = styled.table`
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #e1e4ea;
  width: 100%;
  background: #fff;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #545556;
    letter-spacing: 1px;
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid #e1e4ea;
    /* border-right: 1px solid #e1e4ea;*/

    :last-child {
      border-right: 0;
    }
  }
`;

export default StyledTable;
