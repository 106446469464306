import React from 'react';
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const DemoBar = ({ width, height, title, data }) => {
  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="cost" fill="#3f99e6" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DemoBar;
