import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory, Redirect, Link } from 'react-router-dom';
import {
  ThemeContainer,
  Layout,
  BaseTheme,
  Heading,
  Text,
  Button,
  Box,
  Card,
  Loader,
  Grid,
  Textfield,
} from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';
import * as actions from '../../actions/auth';

import { appdata } from '../../settings';

import styled from 'styled-components';

const hostname = window && window.location && window.location.hostname;

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const Login = props => {
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const [loading, setLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    console.log(data);
    setLoading(true);
    let creds = { username: data.username.trim(), password: data.password.trim() };
    props.onAuth(creds);
    setLoading(false);
    if (props.isAuthenticated) {
      history.replace(from);
    }
  };

  let errorMessage = '';

  if (props.error) {
    errorMessage = <p>{props.error}</p>;
  }

  return (
    <Grid container fullwidth>
      <Grid
        item
        sm={12}
        md={6}
        style={{ height: '100vh', background: '#f4f7fe', display: 'flex', position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card showBorder style={{ flex: 'unset', padding: '24px' }}>
            <Card.Content style={{ margin: 0 }}>
              <div
                style={{
                  margin: '16px 0 32px 0',
                  height: '64px',
                  width: '100%',
                  background: `url(${appdata.logo}) left center / contain no-repeat`,
                }}
              ></div>
              <h2>Login </h2>
              <Text>Complete your details to sign in</Text>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '350px' }}>
                <div className="field" style={{ margin: '8px 0 8px 0' }}>
                  <Textfield
                    type="text"
                    id="username"
                    name="username"
                    label="Username"
                    ref={register({
                      required: true,
                      pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    })}
                    style={{ maxWidth: '100%' }}
                  />

                  <StyledErrorLabel>
                    {errors.username && errors.username.type === 'required' && 'Username is required'}
                    {errors.username && errors.username.type === 'pattern' && 'Please enter a valid email address'}
                  </StyledErrorLabel>
                </div>

                <div className="field" style={{ fontSize: '12px', fontWeight: '500', paddingTop: '10px' }}>
                  <Textfield
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    ref={register({ required: true })}
                    style={{ maxWidth: '100%' }}
                  />

                  <StyledErrorLabel>
                    {errors.password && errors.password.type === 'required' && 'Password is required'}
                  </StyledErrorLabel>
                </div>

                <div>
                  <StyledErrorLabel>{errorMessage}</StyledErrorLabel>
                </div>

                <br />
                <div className="field">
                  <Button type="submit" color="secondary" style={{ justifyContent: 'center', width: '100%' }}>
                    {loading ? (
                      <>
                        <Loader size="small" type="dots" /> Loading
                      </>
                    ) : (
                      'Login'
                    )}
                  </Button>
                </div>
                <div style={{ marginTop: '1rem' }}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </form>
            </Card.Content>
          </Card>
        </div>

        {appdata.name == '4rineu' && (
          <Box style={{ position: 'fixed', bottom: '16px', left: '16px', width: '40%' }} gap="8">
            <img style={{ width: '75px' }} src="https://4rineu.eu/wp-content/uploads/2016/11/EU-flag_white.png" />
            <div>
              <Text size={'xsmall'}>
                This project has received funding from the European Union’s Horizon 2020 research and innovation
                programme under grant agreement No 723829 - All copyrights © 2016-20 reserved to 4RinEU Consortium
              </Text>
              <Text size={'small'}>
                <Link to="/disclaimer">Disclaimer</Link> | <Link to="/about">About</Link>
              </Text>
            </div>
          </Box>
        )}
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        style={{
          height: '100vh',
          background: `url(${appdata.loginimg}) center center / cover no-repeat`,
        }}
      >
        <Text size="xsmall" style={{ textAlign: 'right' }}>
          [{hostname}]
        </Text>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    error: state.session.auth.error,
    isAuthenticated: state.session.auth.isAuthenticated,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onAuth: creds => dispatch(actions.auth(creds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
