import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text, Heading, Loader, Box, Avatar, Icon } from '@ies-ds/react-ui';
import StatusBadge from '../components/StatusBadge';
import ProjectPopover from '../components/ProjectPopover';
import ModuleAdd from './Module/ModuleAdd';
import ModuleActionsLinks from './Module/ModuleActionsLinks';
import { baseURL } from '../settings';
import { imageUrlFetch } from '../actions/higherorder';
import ModuleEditors from './Module/ModuleEditors';
import { InviteUser, MemberList } from '../components/Membership';
import { DeliverableAdd, DeliverableEdit } from './Deliverable';

import moment from 'moment';

// ACTIONS
import { fetchType } from '../actions/higherorder.js';

// COMPONENTS
import DataTable from '../components/DataTable';

const TaskList = ({ module, moduleType, moduleId, store, ...props }) => {
  const { id, pid, taskid } = useParams();
  const { loading, data } = store;
  let { path, url } = useRouteMatch();

  useEffect(() => {
    //props.fetchType(`${moduleType}S`, `/api/module/detailed-list-by-type/4rineu_${moduleType}/${module.id}`);
  }, [moduleId]);

  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach(row => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        id: 'id',
        accessor: 'id',
        width: 15,
        Cell: ({ cell: { value } }) => <Text size="small">{value.id}</Text>,
      },
      {
        Header: 'Task',
        id: 'task',
        accessor: 'task',
        width: 250,
        Cell: ({ cell: { value } }) => (
          <div>
            {moduleType == 'subtask' ? (
              value.name
            ) : (
              <Heading size={'4'} noMargin>
                <Link to={`/portal/${id}/projects/${pid}/tasks/${value.id}/subtasks`} style={{ color: '#333' }}>
                  {' '}
                  {value.name}
                </Link>
              </Heading>
            )}

            <Text size="small" style={{ margin: '0px 0 0 0' }}>
              {value.description}
            </Text>
          </div>
        ),
      },
      /*
      {
        Header: 'Owner',
        accessor: 'owner',
        width: 50,
        Cell: ({ cell: { value } }) =>
          value.deliverable.owner_name ? (
            <Box>
              <Avatar
                size={'small'}
                src={`${baseURL}/api/avatar/view/${value.deliverable.ownerId}`}
                alt={value.deliverable.ownerName}
              />
            </Box>
          ) : null,
      },

      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({ cell: { value } }) =>
          value ? <Text size={'small'}>{moment(value).format('Do MMM YYYY')}</Text> : null,
      },
      {
        Header: 'Deadline',
        accessor: 'deadline',
        Cell: ({ cell: { value } }) =>
          value ? <Text size={'small'}>{moment(value).format('Do MMM YYYY')}</Text> : null,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 50,
        Cell: ({ cell: { value } }) => (
          <div style={{ width: '130px' }}>
            {value.deliverable.status.name ? (
              <DeliverableEdit
                module={value}
                moduleType={'TASK'}
                trigger={
                  <StatusBadge status={value.deliverable.status.name} size="small">
                    {value.deliverable.status.label}
                  </StatusBadge>
                }
              />
            ) : (
              <DeliverableAdd
                module={value}
                moduleType={'TASK'}
                trigger={
                  <StatusBadge status={value.deliverable.status.name} size="small">
                    Add Status
                  </StatusBadge>
                }
              />
            )}
          </div>
        ),
      },*/
      {
        id: 'edit',
        accessor: 'edit',
        Cell: ({ cell: { value } }) => (
          <Box justifyContent="flex-end">
            <ModuleActionsLinks item={value} moduleType={moduleType} />
          </Box>
        ),
      },
    ],
    [],
  );

  function tableData(loading, data) {
    if (false) {
      return [];
    } else {
      return data.list.map(d => {
        return {
          id: d,
          task: d,
          //  owner: d,
          //  startDate: d.deliverable.startDate,
          //  deadline: d.deliverable.deadline,
          // status: d,
          edit: d,
        };
      });
    }
  }

  if (loading) {
    return <Loader type="dots" />;
  }

  return (
    <>
      <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
        {module.links.add_task ? <ModuleAdd id={module.id} type={moduleType} /> : null}
        {module.links.add_subtask ? <ModuleAdd id={module.id} type={moduleType} /> : null}
        {module.links.add_4rineu_task ? <ModuleAdd id={module.id} type={moduleType} /> : null}
        {module.links.add_4rineu_subtask ? <ModuleAdd id={module.id} type={moduleType} /> : null}
      </Box>
      <DataTable columns={columns} data={tableData(loading, data)} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    tasks: state.tasks,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
