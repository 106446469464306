import React from 'react';
import { Text, Box, Loader, Icon, Button } from '@ies-ds/react-ui';
import prettyBytes from 'pretty-bytes';

import styled from 'styled-components';

import { FileUpload, FileDelete } from '../../../components/Vault/File';

import { getFileURL } from '../../../utils';
import { baseURL } from '../../../settings';

const GetLink = ({ file }) => {
  return (
    <a href={getFileURL(`${baseURL}${file.links.download.url}`)} download={file.filename}>
      <Text as="span" size="xsmall" weight="medium" color="dark">
        {file.title}
      </Text>
    </a>
  );
};

const StyledFile = styled(Box)`
  background: #fff;
  display: inline-flex;
  margin: 0 4px 4px 0;
  padding: 8px 9px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  &:hover {
    background: #f9fbfd;
    border: 1px solid #b9cdf9;
  }
`;

const File = ({ file, module }) => {
  return (
    <>
      <StyledFile gap="4" alignItems="center">
        <Icon icon="attach" size="xsmall" />
        <GetLink file={file} />({file.filename} {prettyBytes(file.filesize)})
        <a href="javascript:void(0)" title="Delete">
          <FileDelete
            module={module}
            item={file}
            trigger={<Button icon="close24" appearance="ghost" size="xsmall" color="warning" style={{ margin: '0' }} />}
          />
        </a>
      </StyledFile>
    </>
  );
};

const Files = ({ files, module }) => {
  return (
    <div>
      {files.length > 0 && 'Attachments'}
      <div style={{ marginTop: '4px' }}>
        {files.map(file => {
          return <File file={file} module={module} />;
        })}
        <FileUpload
          module={module}
          trigger={
            <Text color="lighter" as="a" size="xsmall" href="javascript:void(0)">
              <Icon icon="plusSmall" size="small" color="#aaa" />
              Add Attachment
            </Text>
          }
        />
      </div>
    </div>
  );
};

export default Files;
