import React, { useEffect } from 'react';
import _ from 'lodash';

//ROUTING
import { Route, Switch, useParams, useRouteMatch, Link } from 'react-router-dom';
import NoMatch from '../NoMatch';

// COMPONENTS
import {
  ThemeContainer,
  BaseTheme,
  ThemeProvider,
  light,
  dark,
  Frame,
  FrameHeader,
  FrameContent,
  FrameNav,
  FrameMast,
  Box,
  Heading,
  Grid,
  Button,
  Logo,
  Text,
} from '@ies-ds/react-ui';
//import Platform from './Platform';
//import ModuleContainer from './ModuleContainer';
//import RootModule from './RootModule';

import Project from './Project';
import ProjectList from './ProjectList';
import ProjectMenu from './ProjectMenu';
import ProjectMast from './ProjectMast';
import AcccountBadge from '../../components/AcccountBadge';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchModule,
  fetchPlatformSkeleton,
  fetchPlatformSkeletonReset,
  fetchListEntryModules,
  fetchListEntryModulesReset,
} from '../../components/Module/actions';

//import ModuleLoader from '../../components/Module/ModuleLoader';

const routeJson = {};

const Routes = () => {
  const { id, pid } = useParams();
  const { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${url}/`}>
          <p>Gavin</p>
        </Route>
        <Route path="*">
          <>
            999
            <NoMatch />
          </>
        </Route>
      </Switch>
    </>
  );
};

const Platform = ({ ...props }) => {
  const { id, pid } = useParams();
  const { path, url } = useRouteMatch();

  const { entry_modules } = props.session;

  useEffect(() => {
    // Fetch Entry Modules
    props.fetchListEntryModules();
    return () => {
      props.fetchListEntryModulesReset();
    };
  }, []);

  return (
    <ThemeProvider theme={light}>
      <Frame leadElement="Mast">
        <FrameHeader
          style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', padding: '0 32px' }}
        >
          <Heading noMargin size="3">
            {/*entry_modules.loading
              ? 'Loading'
              : entry_modules.data.map(item => {
                  return item.platform == 'calibr' && <Link to={`${path}/${item.id}`}>[{item.name} ]</Link>;
                })*/}
          </Heading>
          <div>
            <AcccountBadge />
          </div>
        </FrameHeader>
        <ThemeProvider theme={dark}>
          <FrameMast>
            <Box alignItems="center" style={{ height: '56px' }}>
              <Switch>
                <Route render={({ match }) => <ProjectMast match={match} />} path={`${path}/:portalId`} />
              </Switch>
            </Box>
          </FrameMast>
          <FrameNav>
            <Switch>
              <Route render={({ match }) => <ProjectMenu match={match} />} path={`${path}/:portalId`} />
            </Switch>
          </FrameNav>
        </ThemeProvider>
        <FrameContent id="containerElement">
          <Grid style={{ padding: '16px 24px' }}>
            <Grid item sm={12}>
              <Switch>
                <Route render={({ match }) => <Project match={match} />} path={`${path}/:portalId/:projectId`} />
                <Route render={({ match }) => <ProjectList match={match} />} path={`${path}/:portalId`} />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </FrameContent>
      </Frame>
    </ThemeProvider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { session: platform } = state;
  const { session } = state;
  /*const { isFetching, lastUpdated, data, data: children } = entities.modules[ownProps.moduleId] || {
    isFetching: true,
    items: [],
  };*/

  return {
    session,
    /* modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    lastUpdated,*/
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
      fetchListEntryModules,
      fetchListEntryModulesReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Platform);
