import React, { useEffect } from 'react';

//ROUTING
import { Route, Switch, useParams, useRouteMatch, Link } from 'react-router-dom';
import NoMatch from '../NoMatch';

// COMPONENTS
import {
  ThemeContainer,
  BaseTheme,
  ThemeProvider,
  light,
  Frame,
  FrameHeader,
  FrameContent,
  Box,
  Heading,
  Text,
  Grid,
  Button,
  Logo,
} from '@ies-ds/react-ui';
import Platform from './Platform';
import ModuleContainer from './ModuleContainer';
import RootModule from './RootModule';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchModule,
  fetchPlatformSkeleton,
  fetchPlatformSkeletonReset,
  fetchListEntryModules,
  fetchListEntryModulesReset,
} from '../../components/Module/actions';

import ModuleLoader from '../../components/Module/ModuleLoader';

const routeJson = {};

const Routes = () => {
  const { id, pid } = useParams();
  const { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${url}/`}>
          <p>Gavin</p>
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};

const LayoutDynamic = props => {
  const { id, pid } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    // Fetch Entry Modules
    props.fetchListEntryModules();
    return () => {
      props.fetchListEntryModulesReset();
    };
  }, []);

  return (
    <ThemeProvider theme={light}>
      <Frame leadElement="HeaderOnly">
        <FrameHeader style={{ justifyContent: 'space-between', padding: '0 16px' }}>
          <Box gap="4" alignItems={'center'}>
            <Logo logo="ies-dark" />
            <Text as="span">
              <Button appearance="ghost" as={Link} to={'/v2'} size="large">
                IES CDP
              </Button>
            </Text>
          </Box>
          <div>Login</div>
        </FrameHeader>
        <FrameContent>
          <Grid container>
            <Grid item sm={12}>
              <Switch>
                <Route path={`${url}/:platform/:rootid`}>
                  <RootModule />
                </Route>
                <Route path={`${url}`}>
                  <Platform />
                </Route>
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </FrameContent>
      </Frame>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
      fetchListEntryModules,
      fetchListEntryModulesReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDynamic);
