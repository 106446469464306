import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Heading, Text, Grid, Icon } from '@ies-ds/react-ui';
import { MetricDelete, MetricEdit, MetricSettings } from './Metric';
import IconButton from '../../ies-ui/IconButton';
import { GetLink } from '../../utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Popover from '../../ies-ui/Popover';

const StyledDropdown = styled.div`
  font-size: 14px;
  border: 1px solid #e1e4ea;
  position: absolute;
  top: 40px;
  right: 20px;
  /*width: 240px;*/
  background: #fff;
  box-shadow: 0 3px 9px 0px rgba(80, 80, 80, 0.5);
  /* padding: 16px;*/

  display: block;
  a {
    color: #333;
  }
`;

const handleColorType = color => {
  switch (color) {
    case 'green':
      return 'color: #fff; background: #00c49f;';
    case 'red':
      return 'color: #fff; background: #f56342;';
    case 'blue':
      return 'color: #fff; background: #0088fe;';
    case 'pink':
      return 'color: #fff; background: #ff6cd9;';
    case 'yellow':
      return 'color: #fff; background: #ffbb28;';
    default:
      return 'color: #000; background: #fff;';
  }
};

const StyledCardPanel = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px;
  margin: 8px;
  ${props => `width: ${props.width}px`}
  ${props => `height: ${props.height}px`}

  ${({ color }) => handleColorType(color)};

  .MyDragHandleClassName {
    &:hover {
      cursor: move;
    }
  }
  .actions {
    opacity: 0;
  }
  &:hover {
    .actions {
      opacity: 1;
    }
  }
`;

const StyledCardPanelHeader = styled.div`
  display: flex;
  padding: 0;
  height: 80px;
  align-items: flex-start;
`;
const StyledCardPanelContent = styled.div`
  flex: 1;
  padding: 16px;
`;

const StyledCardPanelFooter = styled.div`
  height: 40px;
  padding: 0 0 16px 0;
`;

const StyledCardDragHandle = styled.div`
  background: #fff;
  border-radius: 3px;
  cursor: move;
  width: 28px;
  height: 28px;
  display: none;
  align-items: center;
  margin: -16px 4px 0 -16px;
  justify-content: center;
  &:hover {
    background: #f5f5f5;
  }
`;

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    //callback();
    if (ref.current && !ref.current.contains(e.target)) {
      //callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });
};

const CardPanel = ({ module, moduleId, item, width, height, title, description, children, ...props }) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (visible) setVisible(false);
  });

  return (
    <StyledCardPanel height={height} {...props} color={props.layout == '' ? 'none' : JSON.parse(props.layout).bgColor}>
      <StyledCardPanelHeader>
        {item.links.edit ? (
          <StyledCardDragHandle className={'MyDragHandleClassName actions'}>
            <Icon icon="default" size={16} color={'#bbb'} />
          </StyledCardDragHandle>
        ) : null}
        <div className={item.links.edit ? 'MyDragHandleClassName' : null} style={{ paddingLeft: '8px' }}>
          <Heading size={3}>{title}</Heading>
          {/*<Text size={'xsmall'}>{description}</Text>*/}
        </div>
        <Box justifyContent="flex-end" className="actions">
          {/* <GetLink module={module} data={{ url: '/api/custom-field-value/getforms/1149/Module/1149', label: '+' }} />
          <MetricEdit
            module={module}
            item={item}
            trigger={<IconButton size="14" icon="cogFilled" title={`Settings`} />}
        />*/}
          <div style={{ position: 'relative', marginLeft: '4px', display: 'none' }}>
            <IconButton size="16" icon={'pen'} onClick={() => setVisible(!visible)} />
            {visible && (
              <Popover
                ref={ref}
                style={{ minWidth: '290px', left: '56px', top: '47px', position: 'fixed', right: 'unset' }}
              ></Popover>
            )}
          </div>
          <MetricSettings
            module={module}
            item={item}
            trigger={
              <Button size="small" appearance="ghost" icon="settingsSolid" title={`Settings`} style={{ margin: 0 }} />
            }
          />
          <MetricEdit
            module={module}
            item={item}
            trigger={<Button size="small" appearance="ghost" icon="pen" title={`Edit`} style={{ margin: 0 }} />}
          />
          <MetricDelete
            module={module}
            item={item}
            trigger={<Button size="small" appearance="ghost" icon="bin" title={`Delete`} style={{ margin: 0 }} />}
          />
        </Box>
      </StyledCardPanelHeader>
      <StyledCardPanelContent>{children}</StyledCardPanelContent>
      <StyledCardPanelFooter></StyledCardPanelFooter>
    </StyledCardPanel>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
    item,
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: vault, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  const { layout } = entities.modules[id].data.report.metrics.find(x => x.id === item.id);

  return {
    layout,
    modules,
    data,
    selectedSubreddit,
    vault,
    isFetching,
    doesExsist,
    lastUpdated,

    vault: state.entities.vault,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // refeshVault,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardPanel);
