import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseURL } from '../settings';
import { fetchType, imageUrlFetch } from '../actions/higherorder';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Avatar, Loader, Menu, Heading, Text } from '@ies-ds/react-ui';
import IconButton from '../ies-ui/IconButton';
import Popover from '../ies-ui/Popover';

import { getImageURL } from '../utils';

const StyledNavLink = styled(NavLink)`
  &.active {
    background: #e1eaff;
    display: block;
    color: #000;
  }
  &:hover {
    text-decoration: none;
  }
`;

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  font-weight: 700;
  font-size: 18px;
`;

const ListPortals = ({ portals }) => {
  const { id } = useParams();
  const { loading, data } = portals;

  return (
    <>
      {// Check if message failed
      loading ? (
        <Loader type="dots" />
      ) : (
        data.list.map(item => (
          <StyledNavLink to={`/portal/${item.id}/projects`}>
            <Menu.Item key={item.id}>
              <Avatar type="square" src={getImageURL(`${baseURL}/api/brand/logo/${item.brand.id}`)} />
              {item.name}
            </Menu.Item>
          </StyledNavLink>
        ))
      )}
    </>
  );
};

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    callback();
    /* if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }*/
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });
};

const GetAvatar = ({ src }) => {
  return <Avatar type="square" src={getImageURL(src)} style={{ marginRight: '16px' }} />;
};

const PortalChanger = props => {
  const [visible, setVisible] = useState(false);
  const ref = useRef();

  const { loading, data, error } = props.portal;
  const { id } = useParams();

  useOutsideClick(ref, () => {
    if (visible) setVisible(false);
  });

  React.useEffect(() => {
    props.fetchType('PORTAL', `/api/module/view/${id}`);
    //props.fetchType('PROJECTS', `/api/module/detailed-list-by-type/4rineu_project/${id}`);
  }, [id]);

  return (
    <>
      {error ? (
        `error: need access to moduleId: /api/module/view/${id}`
      ) : loading ? (
        <Box justifyContent="flex-start">
          <Avatar type="square" style={{ marginRight: '16px' }} />
          <Heading size="3" noMargin>
            <Loader type="dots" size={`xsmall`} />
          </Heading>
        </Box>
      ) : (
        <Box justifyContent="space-between" style={{ maxWidth: '260px' }}>
          <Box justifyContent="flex-start">
            <GetAvatar
              src={`${baseURL}/api/brand/logo/${data.item.brand.id}`}
              // src={data.item.brand ? `${baseURL}/api/brand/logo/${data.item.brand.id}` : null}
            />
            <div>
              <EllipsisText noMargin>{data.item.name}</EllipsisText>
              {/*<Text size="xsmall" style={{ margin: 0 }}>
                {data.item.description}
      </Text>*/}
            </div>
          </Box>
          <div style={{ position: 'relative', marginLeft: '4px' }}>
            <IconButton size="16" icon={'moreVerticalFilled'} onClick={() => setVisible(!visible)} />
            {visible && (
              <Popover
                ref={ref}
                style={{ minWidth: '290px', left: '56px', top: '47px', position: 'fixed', right: 'unset' }}
              >
                <Menu.Container style={{ padding: 0 }}>
                  <ListPortals portals={props.portals} />
                </Menu.Container>
              </Popover>
            )}
          </div>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    portal: state.portal,
    portals: state.portals,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PortalChanger);
