import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text, Heading, Loader, Box, Avatar, Icon } from '@ies-ds/react-ui';
import IconButton from '../../ies-ui/IconButton';
import _ from 'lodash';

import ConnectChecklist from '../Module/layout/connectChecklist';

import moment from 'moment';

import { ModuleAdd, ModuleEdit, ModuleDelete } from './';

// ACTIONS
import { fetchType } from '../../actions/higherorder';

// COMPONENTS
import DataTable from '../../components/DataTable';

const StyledCheckItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  display: flex;
  padding: 16px 8px;
  border-bottom: 1px solid #e1e4ea;
`;

const Checklist = ({ data, module, moduleType, moduleId, store, ...props }) => {
  const { id, pid, taskid } = useParams();
  let { path, url } = useRouteMatch();

  useEffect(() => {
    //props.fetchType(`${moduleType}S`, `/api/module/detailed-list-by-type/4rineu_${moduleType}/${module.id}`);
  }, [moduleId]);

  const CheckItem = ({ item }) => {
    return <ConnectChecklist module={item} />;
  };

  return (
    <>
      {createLinks(data)}

      {data.children.map(item => {
        return <CheckItem item={item} />;
      })}
    </>
  );
};

const actionLinks = data => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].route) {
            case 'api_module_edit':
              return <ModuleEdit module={data} trigger={<IconButton size="16" icon="edit" title={'Edit'} />} />;
            case 'api_module_delete':
              return <ModuleDelete module={data} trigger={<IconButton size="16" icon="bin" title={'Delete'} />} />;
          }
        });
  }
};

const createLinks = data => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].access) {
            case 'create':
              return (
                <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
                  <ModuleAdd
                    module={data}
                    type={key}
                    trigger={<Button size={'small'}>Add {data.links[key].label}</Button>}
                  />
                </Box>
              );
          }
        });
  }
};

const mapStateToProps = (state, myProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  /* const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[myProps.module.id] || {
    isFetching: true,
    items: [],
  };*/

  return {
    modules,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Checklist);
