import React, { useState, useEffect, useContext } from 'react';

import { ThemeContainer, Layout, BaseTheme, Heading, Text, Button, Box, Card, Loader, Grid } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';



import styled from 'styled-components';
//import { forgotPassword } from '../../actions/auth';
import { Link } from 'react-router-dom';

import {appdata} from '../../settings'


const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const MessageStyle = styled.span`
  font-size: 14px;
  color: blue;
`;

const Invitation = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    event.preventDefault();
    setLoading(true);
    //debugger;
    let email = {
      email: data.email.trim(),
    };
    /* forgotPassword(email)
      .then(data => {
        if (data.data.message) {
          setMessage(data.data.message);
        }
        setLoading(false);
      })
      .catch(function(error) {
        setError(error.response.data.error);
        setLoading(false);
      });*/
  };

  function handleChange(event) {
    setError('');
    setMessage('');
  }

  return (
    <div>
      <ThemeContainer>
        <Layout>
          <Grid container fullwidth>
            <Grid
              item
              sm={12}
              md={6}
              style={{ height: '100vh', background: '#f4f7fe', display: 'flex', position: 'relative' }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  right: '0',
                  left: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card showBorder style={{ flex: 'unset' }}>
                  <Card.Content>
                  <div style={{ margin: '16px 0 32px 0', height: '64px', width: '100%', background: `url(${appdata.logo}) left center / contain no-repeat`}}>
                        
                        </div>
                    <h2>Create Account</h2>
                    <Text>Complete the form to join</Text>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '350px' }}>
                      [form]
                    </form>
                  </Card.Content>
                </Card>
              </div>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                height: '100vh',
                background:
                `url(${appdata.loginimg}) center center / cover no-repeat`,
              }}
            ></Grid>
          </Grid>
        </Layout>
      </ThemeContainer>
    </div>
  );
};

export default Invitation;
