import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ies-ui/IconButton';
import { Button, Modal, Heading, Loader, Text } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteTypeArticle } from '../../actions/higherorder';

import { deleteArticle } from './actions';

const ArticleDelete = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);

    fetch(`${baseURL}${item.links.delete.url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(r => r.json())
      .then(data => {
        setModalopen(false);
        setLoading(false);
        props.deleteArticle(item.id, module.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {module.links.delete ? (
        <>
          {trigger ? (
            <span onClick={() => openHandler()}>{trigger}</span>
          ) : (
            <Button size="small" onClick={() => openHandler()}>
              Delete Article
            </Button>
          )}
          <Modal isOpen={modalOpen} onClose={closeHandler} closeButton>
            <Modal.Header title="Delete Article" />
            <Modal.Content>
              <Text>Do you want to delete this article:</Text>
              <Heading size={'3'}>{item.title}</Heading>
            </Modal.Content>
            <Modal.Footer>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Delete
                  </Button>{' '}
                  <Button
                    onClick={() => {
                      closeHandler();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
};

ArticleDelete.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteArticle,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDelete);
