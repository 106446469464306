import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refeshArticles } from '../../../../components/Article/actions';

import ModalViewer from '../ModalViewer';
import ArticleEdit from './Edit';
import ArticleAdd from './Add';
import ArticleDelete from '../../../../components/Article/Delete';

import { Button, Table, TableRow, TableCell } from '@ies-ds/react-ui';

const ArticleList = ({ module, ...props }) => {
  return (
    <>
      <ArticleAdd module={module} trigger={<Button size="small">Add Document</Button>} />

      <br />
      <br />
      {props.isFetching ? (
        'loading'
      ) : (
        <Table>
          <TableRow>
            <th style={{ width: '50px' }}>id</th>
            <th>Title</th>
            <th></th>
          </TableRow>
          {props.library.articles.map(data => {
            return (
              <TableRow>
                <TableCell>{data.id}</TableCell>
                <TableCell>
                  <ModalViewer articleId={data.id} moduleId={module.id} label={data.title} />
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  {/*<ArticleEdit
                    articleId={data.id}
                    moduleId={module.id}
                    trigger={<Button title="Edit" appearance="text" icon="pencil" size="small" style={{ margin: 0 }} />}
                  />*/}
                  <ArticleDelete
                    item={data}
                    module={module}
                    trigger={<Button title="Delete" appearance="text" icon="bin" size="small" style={{ margin: 0 }} />}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { entities, entities: modules } = state;
  const {
    isFetching,
    data: { library },
  } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    library,
    isFetching,
    articles: state.entities.articles,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refeshArticles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
