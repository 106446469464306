import React from 'react';
import styled from 'styled-components';
import Task from './Task';
import { ModuleAdd } from '../../../../components/Module';
import { Button } from '@ies-ds/react-ui';

import { Droppable } from 'react-beautiful-dnd';

const Container = styled.div`
  border: 1px solid #ddd;
  margin: 8px 0;
`;
const Title = styled.h3`
  padding: 8px;
`;

const TaskList = styled.div``;

const Column = ({ module, isDragDisabled, children, column }) => {
  return (
    <>
      <p>
        {Object.keys(module.links).map(function(key) {
          switch (module.links[key].access) {
            case 'create':
              if (module.links[key].operation === 'add') {
                return (
                  <>
                    <ModuleAdd
                      module={module}
                      type={key}
                      trigger={<Button size="small">Add {module.links[key].label}</Button>}
                    />
                  </>
                );
              }

            default:
              return null;
          }
        })}
      </p>
      <Droppable droppableId={column.id}>
        {provided => (
          <TaskList ref={provided.innerRef} {...provided.droppableProps}>
            {children.map((data, index) => {
              return <Task key={data.id} index={index} data={data} isDragDisabled={isDragDisabled} />;
            })}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </>
  );
};

export default Column;
