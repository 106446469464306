import update from 'immutability-helper';

const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default function createDataLoader(name = '') {
  return (state = INITIAL_STATE, action) => {
    let error;

    switch (action.type) {
      case `FETCH_${name}_REQUESTED`:
        return {
          ...state,

          data: [],
          error: null,
          loading: true,
        };

      case `FETCH_${name}_SUCCESS`:
        return {
          ...state,

          data: action.data,
          error: null,
          loading: false,
        };

      case `FETCH_${name}_FAILURE`:
        // error = action.err || '{message: action.data.message}';
        // error = action.error || action.error;

        error = action.error.response || { message: action.error.message };

        return {
          ...state,

          data: [],
          error,
          loading: false,
        };

      case `FETCH_${name}_RESET`:
        return {
          ...state,

          data: [],
          error: null,
          loading: false,
        };

      case `FETCH_${name}_INSERT`:
        return update(state, {
          data: { list: { $push: [action.data] } },
        });

      case `FETCH_${name}_DELETE`:
        return update(state, {
          data: { list: arr => arr.filter(item => item.id !== action.data) },
        });

      case `EDIT_${name}`:
        return update(state, {
          data: { item: { $merge: action.data.item } },
        });

      case `EDIT_${name}_LISTITEM`:
        return update(state, {
          data: {
            list: list =>
              list.map(item => {
                if (item.id !== action.data.item.id) return item;
                return update(item, { $merge: action.data.item });
              }),
          },
        });

      case `UPDATE_${name}_DELVIERABLE`:
        return update(state, {
          data: {
            list: list =>
              list.map(item => {
                if (item.id !== action.moduleid) return item;
                return update(item, {
                  $merge: { deliverable: action.data.item },
                });
              }),
          },
        });

      /*	case 'UPDATE_'+name+'_DELVIERABLE' : 

			return update(state, { 
				data: { list: 
		            list => list.map(item => {
		                if(item.id !== action.moduleid)  return  item;		                
		                return update(item,
			                {
			                	$merge: { deliverable: action.data.item}
			                }
		                )
		            })
				} 
			}) */

      case `UPDATE_${name}_LOGO`:
        console.log(action.data);

        return update(state, {
          data: {
            list: list =>
              list.map(item => {
                if (item.id !== action.moduleid) return item;
                if (item.brand) {
                  return update(item, {
                    brand: { $merge: { logo: action.data.logo } },
                  });
                }
                return update(item, {
                  $merge: { brand: action.data },
                });
              }),
          },
        });

      // /// COMMENTS

      case `INSERT_${name}_COMMENT`:
        return update(state, {
          data: {
            item: {
              discussion: {
                comments: { $push: [action.data] },
              },
            },
          },
        });

      case `DELETE_${name}_COMMENT`:
        return update(state, {
          // data: { list: arr => arr.filter(item => item.id != action.data)}

          data: {
            item: {
              discussion: {
                comments: arr => arr.filter(item => item.id !== action.data),
              },
            },
          },
        });

      case `UPDATE_${name}_COMMENT`:
        return update(state, {
          data: {
            item: {
              discussion: {
                comments: comments =>
                  comments.map(item => {
                    if (item.id !== action.commentId) return item;
                    return update(item, { $merge: action.data });
                  }),
              },
            },
          },
        });

      // /// ARTICLE

      case `INSERT_${name}_ARTICLE`:
        // TODO: CREATE RECURSIVE LOOP TO CHECK ALL NODES

        function recursive(item) {
          if (item.id !== action.data.parentId) {
            item.children.map(child => {
              recursive(child);
            });
            return item;
          }
          return update(item, { children: { $push: [action.data] } });
        }

        if (action.data.parentId === '') {
          return update(state, {
            data: {
              item: {
                library: {
                  articles: { $push: [action.data] },
                },
              },
            },
          });
        }
        return update(state, {
          data: {
            item: {
              library: {
                articles: articles => articles.map(item => recursive(item)),
              },
            },
          },
        });

      case `DELETE_${name}_ARTICLE`:
        return update(state, {
          // data: { list: arr => arr.filter(item => item.id != action.data)}

          data: {
            item: {
              library: {
                articles: arr => arr.filter(item => item.id !== action.data),
              },
            },
          },
        });

      case `UPDATE_${name}_ARTICLE`:
        return update(state, {
          data: {
            item: {
              library: {
                articles: articles =>
                  articles.map(item => {
                    if (item.id !== action.articleId) return item;
                    return update(item, { $merge: action.data });
                  }),
              },
            },
          },
        });

      // /// FILES

      case `INSERT_${name}_FILE`:
        return update(state, {
          data: {
            item: {
              vault: {
                files: { $push: [action.data] },
              },
            },
          },
        });

      case `DELETE_${name}_FILE`:
        return update(state, {
          // data: { list: arr => arr.filter(item => item.id != action.data)}

          data: {
            item: {
              vault: {
                files: arr => arr.filter(item => item.id !== action.data),
              },
            },
          },
        });

      case `UPDATE_${name}_FILE`:
        return update(state, {
          data: {
            item: {
              vault: {
                files: files =>
                  files.map(item => {
                    if (item.id !== action.fileId) return item;
                    return update(item, { $merge: action.data });
                  }),
              },
            },
          },
        });

      // /// REFERENCES

      case `INSERT_${name}_REFERENCE`:
        return update(state, {
          data: {
            item: {
              vault: {
                references: { $push: [action.data] },
              },
            },
          },
        });

      case `DELETE_${name}_REFERENCE`:
        return update(state, {
          // data: { list: arr => arr.filter(item => item.id != action.data)}

          data: {
            item: {
              vault: {
                references: arr => arr.filter(item => item.id !== action.data),
              },
            },
          },
        });

      case `UPDATE_${name}_REFERENCE`:
        return update(state, {
          data: {
            item: {
              vault: {
                references: references =>
                  references.map(item => {
                    if (item.id !== action.fileId) return item;
                    return update(item, { $merge: action.data });
                  }),
              },
            },
          },
        });

      // /// DELIVERABLE

      case `INSERT_${name}_DELIVERABLE`:
        // return state;
        return update(state, {
          data: {
            item: {
              deliverable: { $merge: action.data },
            },
          },
        });

      /* case 'DELETE_'+name+'_DELIVERABLE' : 
			return update(state, { 
				//data: { list: arr => arr.filter(item => item.id != action.data)}

				data: { 
					item: { 
						vault : { 
							files: arr => arr.filter(item => item.id != action.data)}
						} 
					}
				}
			) */

      case `INSERT_${name}_LISTITEM_DELIVERABLE`:
        return update(state, {
          data: {
            list: list =>
              list.map(item => {
                if (item.id !== action.moduleid) return item;
                return update(item, {
                  deliverable: { $merge: action.data.item },
                });
              }),
          },
        });

      // /// MEMBERSHIP

      case `INSERT_${name}_MEMBERSHIP`:
        // return state;
        return update(state, {
          data: {
            item: {
              memberships: { $merge: action.data },
            },
          },
        });

      case `UPDATE_${name}_MEMBERSHIP`:
        return update(state, {
          data: {
            item: {
              memberships: memberships =>
                memberships.map(item => {
                  if (item.id !== action.data.id) return item;
                  return update(item, { $merge: action.data });
                }),
            },
          },
        });

      case `CREATE_${name}_MEMBERSHIP`:
        return update(state, {
          data: {
            item: {
              memberships: memberships =>
                memberships.map(item => {
                  if (item.userId !== action.data.userId) return item;
                  return update(item, { $merge: action.data });
                }),
            },
          },
        });

      case `DELETE_${name}_MEMBERSHIP`:
        return update(state, {
          data: {
            item: {
              memberships: memberships =>
                memberships.map(item => {
                  if (item.userId !== action.data.userId) return item;
                  return update(item, { $merge: action.data });
                }),
            },
          },
        });

      case `INSERT_${name}_LISTITEM_MEMBERSHIP`:
        return update(state, {
          data: {
            list: list =>
              list.map(item => {
                console.log('item.id');
                console.log(item.id);
                console.log(action.data.id);
                if (item.id !== action.data.id) return item;
                return update(item, {
                  memberships: {
                    memberships: memberships =>
                      memberships.map(item => {
                        console.log('item.idqqq');
                        console.log(item.id);
                        if (item.id !== action.data.id) return item;
                        return update(item, { $merge: action.data });
                      }),
                  },
                });
              }),
          },
        });

      /*return update(state, {
          data: {
            list: list =>
              list.map(item => {
                console.log(item.memberships);

                item.memberships.map(data => {
                  console.log(data);
                  if (data.id !== action.data.id) return data;

                  return update(item, {
                    memberships: { $merge: action.data },
                  });
                });
              }),
          },
        });*/

      case `TEST_${name}_TEST`:
        console.log(action.data);
        return state;

      // / work in progress
      /* case 'FETCH_'+name+'_APPEND' :
		console.log(action.data) 
			return update(state, { 
				    data: { 
				    	deliverable : {
				    		deadline: {$set: action.data}
				    	}
				      
				    }
				  }) */

      case `FETCH_${name}_APPEND`:
        console.log(action.data);
        // return update(state, {data: { list: { $splice: [[index, 1]] } }});
        break;
      default:
        return state;
    }
  };
}
