import React from 'react';
import StaticLayout from './Layout';
import { Heading, Text } from '@ies-ds/react-ui';

const PageAbout = () => {
  return (
    <StaticLayout>
      <Heading>About</Heading>
      <p>
        Despite the low energy performances of the European building stock, the yearly renovation rate and the choice to
        perform a building deep renovation is strongly affected by uncertainties in terms of costs and benefits in the
        life cycle.
      </p>

      <p>
        The project 4RinEU faces these challenges, offering technology solutions and strategies to encourage the
        existing building stock transformation, fostering the use of renewable energies, and providing reliable business
        models to support a deep renovation.
      </p>

      <p>
        4RinEU project minimises failures in design and implementation, manages different stages of the deep renovation
        process - from the preliminary audit up to the end-of-life - and provides information on energy, comfort,
        users&rsquo; impact, and investment performance.
      </p>

      <p>The 4RinEU deep renovation strategy is based on 3 pillars:</p>
      <ul>
        <li>
          technologies - driven by robustness - to decrease net primary energy use (60 to 70% compared to
          pre-renovation), allowing a reduction of life cycle costs over 30 years (15% compared to a typical
          renovation);
        </li>

        <li>
          methodologies - driven by usability - to support the design and implementation of the technologies,
          encouraging all stakeholders&rsquo; involvement and ensuring the reduction of the renovation time;
        </li>

        <li>
          business models - driven by reliability - to enhance the level of confidence of deep renovation investors,
          increasing the EU building stock transformation rate.
        </li>
      </ul>

      <p>
        4RinEU technologies, tools and procedures are expected to generate significant impacts: energy savings,
        reduction of renovation time, improvement of occupants indoor environmental conditions, optimisation of
        renewable energy use, acceleration of EU residential building renovation rate. This will bring a revitalisation
        of the EU construction sector, making renovation easier, quicker and more sustainable.
      </p>

      <p>
        The present <strong>Collaborative Design Platform</strong> is a result of the 4RinEU project. It is intended to
        be a private and secure web platform for deep renovation support, implementing a participative approach,
        facilitating communication within the renovation project team and implementing methodologies and workflows
        developed within the 4RinEU project. In particular, it includes the 4RinEU methodology for deep renovation,
        presented below.
      </p>

      <h2>4RinEU methodology for deep renovation</h2>

      <p>
        This methodology for deep renovation <strong>focuses on transforming the renovation process</strong> to make
        residential refurbishments cheaper and easier, and, as consequence, to incentivise building owners to make
        properties more environmentally friendly and, at the same time, creating an improved indoor environment for
        occupants.&#8239;
      </p>

      <p>
        This is achieved through the{' '}
        <a target="_blank" href="https://4rineu.eu/">
          4RinEU
        </a>{' '}
        cost-effective deep renovation approach. This approach supports all people involved in the process of deep
        renovation through <strong>meaningful motivations</strong> to start the process and decide to invest.
      </p>

      <p>
        Deep renovation is one of the building life cycle stages that must start at the right moment according to the
        <strong>running conditions and performances.</strong> The core tasks are:
      </p>
      <ul>
        <li>PHASE 0: To decide if building to be renovated (or maintained)</li>

        <li>PHASE I: To perform energy audit to create the existing baseline</li>

        <li>PHASE II: To undertake the knowledge-based renovation decision process</li>

        <li>PHASE III: To install the appropriate technological package</li>

        <li>PHASE IV: To perform measurement and verification, to acquire the new (improved) baseline.</li>
      </ul>

      <h2>How to use the Collaborative Design Platform (CDP)</h2>

      <p>
        The 4RinEU methodology is implemented in the 4RinEU CDP as a replicable project template. The renovation project
        manager can use this starting point to create a 4RinEU-compliant project within the platform, as a collection of
        tasks (representing the phases) and subtasks (the various steps to be undertaken in each phase). Projects and
        tasks can be assigned to different users, and owners can set deadlines and status.
      </p>

      <p>
        Together with the project structure, the template also includes a set of knowledgebase articles which are
        included in the platform together with each task, and supporting files and references. When implementing the
        project, the users can add further files and references to store the documentation related to the specific
        project. A comments section allows to discuss the specific task.
      </p>

      <p>
        At project and task level it is also possible to track metrics to monitor the progress of the implementation of
        the deep renovation project according to the 4RinEU methodology, in particular with respects to improving energy
        consumption, CO2 emissions, and time on site. These metrics can be visualised in a custom dashboard for the
        project.
      </p>
    </StaticLayout>
  );
};

export default PageAbout;
