import { combineReducers } from 'redux';
import update from 'immutability-helper';

//import { INVALIDATE_SUBREDDIT, REQUEST_POSTS, RECEIVE_POSTS } from './actions';

function module(
  state = {
    doesExsist: false,
    isFetching: false,
    didInvalidate: false,
    data: [],
    error: null,
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_MODULE':
      return Object.assign({}, state, {
        doesExsist: true,
        isFetching: true,
      });
    case 'FETCH_MODULE_SUCCESS':
      return Object.assign({}, state, {
        doesExsist: true,
        isFetching: false,
        data: action.data,
        lastUpdated: action.receivedAt,
      });
    case 'FETCH_MODULE_FAILURE':
      return Object.assign({}, state, {
        doesExsist: false,
        isFetching: false,
        error: action.data,
      });
    case 'UPDATE_MODULE':
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        lastUpdated: action.receivedAt,
      });
    case 'DELETE_MODULE':
      return Object.assign({}, state, {
        doesExsist: false,
        isFetching: false,
        data: [],
      });
    default:
      return state;
  }
}

const modules = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_MODULE_SUCCESS':
    case 'FETCH_MODULE':
    case 'UPDATE_MODULE':
    case 'DELETE_MODULE':
    case 'FETCH_MODULE_FAILURE':
      return update(state, {
        [`${action.moduleId}`]: { $set: module(state[action.subreddit], action) },
      });

    case 'UPDATE_LIST_MODULE':
      if (action.data.parentId == '') {
        return state;
      } else {
        return update(state, {
          [`${action.data.parentId}`]: {
            data: {
              children: children =>
                children.map(item => {
                  if (item.id !== action.data.id) return item;
                  return update(item, { $merge: action.data });
                }),
            },
          },
        });
      }
    case 'ADD_MODULE':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            children: { $push: [action.data] },
          },
        },
      });
      {
        /*case 'DELETE_MODULE':
    return state;*/
      }
    case 'DELETE_LIST_MODULE':
      return update(state, {
        [`${action.module.parentId}`]: {
          data: {
            children: arr => arr.filter(item => item.id !== action.module.id),
          },
        },
      });

    case 'UPDATE_COMPONENT':
      return update(state, {
        [`${action.moduleId}`]: {
          data: { [action.componentType]: { $merge: action.data.item } },
        }, // MERGE
        // [`${action.moduleId}`]: { data: { [action.componentType]: { [action.subComponentType]: { $set: action.data } } } }, // SET
      });

    /**
     * Component: Vault
     * SubComponents: File, Reference
     * {CREATE,UPDATE,DELETE}
     *
     *  */

    case 'CREATE_VAULT_ITEM':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            vault: {
              [action.componentType]: { $push: [action.dataItem] },
            },
          },
        },
      });

    case 'UPDATE_VAULT_ITEM':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            vault: {
              [action.componentType]: items =>
                items.map(item => {
                  if (item.id !== action.dataItem.id) return item;
                  return update(item, { $merge: action.dataItem });
                }),
            },
          },
        },
      });

    case `DELETE_VAULT_ITEM`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            vault: {
              [action.componentType]: arr => arr.filter(item => item.id !== action.itemId),
            },
          },
        },
      });

    /**
     * Component: Membership
     * {CREATE,UPDATE,DELETE}
     *
     *  */

    case 'CREATE_MEMBERSHIP':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            memberships: { $push: [action.dataItem] },
          },
        },
      });

    case 'UPDATE_MEMBERSHIP':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            memberships: items =>
              items.map(item => {
                if (item.id !== action.dataItem.id) return item;
                return update(item, { $merge: action.dataItem });
              }),
          },
        },
      });

    case `DELETE_MEMBERSHIP`:
      return update(state, {
        // data: { list: arr => arr.filter(item => item.id != action.data)}
        [`${action.moduleId}`]: {
          data: {
            memberships: arr => arr.filter(item => item.userId !== action.itemId),
          },
        },
      });

    /**
     * Component: Library
     * SubComponent: Artilces
     * {}
     *
     *  */

    case `CREATE_ARTICLE`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            library: { articles: { $push: [action.dataItem] } },
          },
        },
      });

    case 'UPDATE_ARTICLE':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            library: {
              articles: items =>
                items.map(item => {
                  if (item.id !== action.dataItem.id) return item;
                  return update(item, { $merge: action.dataItem });
                }),
            },
          },
        },
      });

    case `DELETE_ARTICLE`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            library: {
              articles: arr => arr.filter(item => item.id !== action.itemId),
            },
          },
        },
      });

    /**
     * Component: Discussion
     * SubComponent: Comment
     * {INSERT,UPDATE}
     *
     *  */

    case `CREATE_COMMENT`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            discussion: { comments: { $push: [action.dataItem] } },
          },
        },
      });

    case 'UPDATE_COMMENT':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            discussion: {
              comments: items =>
                items.map(item => {
                  if (item.id !== action.dataItem.id) return item;
                  return update(item, { $merge: action.dataItem });
                }),
            },
          },
        },
      });

    case `DELETE_COMMENT`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            discussion: {
              comments: arr => arr.filter(item => item.id !== action.itemId),
            },
          },
        },
      });

    /**
     * Component: Report
     * SubComponent: Metric
     *
     *  */

    case `ADD_METRIC`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            report: {
              metrics: { $push: [action.dataItem] },
            },
          },
        },
      });

    case 'UPDATE_METRIC':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            report: {
              metrics: items =>
                items.map(item => {
                  if (item.id !== action.dataItem.id) return item;
                  return update(item, { $merge: action.dataItem });
                }),
            },
          },
        },
      });

    case 'UPDATE_REPORT_LAYOUT':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            report: {
              layout: { $set: action.dataItem },
            },
          },
        },
      });

    case `DELETE_METRIC`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            report: {
              metrics: arr => arr.filter(item => item.id !== action.itemId),
            },
          },
        },
      });

    case 'UPDATE_METRIC_LAYOUT':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            report: {
              metrics: items =>
                items.map(item => {
                  if (item.id !== action.dataItem.id) return item;
                  return update(item, { $set: action.dataItem });
                  /*{
                  layout: { $set: action.dataItem },
                },*/
                }),
            },
          },
        },
      });
    /**
     * CustomField for Module
     *
     *
     *  */

    case `CREATE_CUSTOMFIELD`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            customFields: {
              [action.dataItem.attribute]: {
                values: { $push: [action.dataItem] },
              },
            },
          },
        },
      });

    case 'UPDATE_CUSTOMFIELD':
      return update(state, {
        [`${action.dataItem.moduleId}`]: {
          data: {
            customFields: {
              [action.dataItem.attribute]: {
                values: items =>
                  items.map(item => {
                    if (item.id !== action.dataItem.id) return item;
                    return update(item, { $merge: action.dataItem });
                  }),
              },
            },
          },
        },
      });

    case `DELETE_CUSTOMFIELD`:
      return update(state, {
        [`${action.item.moduleId}`]: {
          data: {
            customFields: {
              [`${action.item.attribute}`]: {
                values: arr => arr.filter(item => item.id !== action.item.id),
              },
            },
          },
        },
      });

    /**
     * Component: Deliverable
     * SubComponent: n/a
     *
     *  */

    case `CREATE_DELIVERABLE`:
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            deliverable: { $merge: action.dataItem },
          },
        },
      });

    case 'UPDATE_DELIVERABLE':
      return update(state, {
        [`${action.moduleId}`]: {
          data: {
            deliverable: items =>
              items.map(item => {
                if (item.id !== action.dataItem.id) return item;
                return update(item, { $merge: action.dataItem });
              }),
          },
        },
      });

    default:
      return state;
  }
};

export default modules;
