import React from 'react';
import { Heading, Text, Box, Grid } from '@ies-ds/react-ui';
import styled from 'styled-components';
import DemoPie from './democharts/pie';
import DemoBar from './democharts/bar';
import DemoRadar from './democharts/radar';

const piedata = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const datademo = [
  {
    name: 'Page A',

    cost: 2400,
  },
  {
    name: 'Page B',

    cost: 2210,
  },
  {
    name: 'Page C',

    cost: 2290,
  },
  {
    name: 'Page D',

    cost: 2000,
  },
  {
    name: 'Page E',

    cost: 2181,
  },
  {
    name: 'Page F',

    cost: 2500,
  },
  {
    name: 'Page G',

    cost: 2100,
  },
  {
    name: 'Page B',

    cost: 2210,
  },
  {
    name: 'Page C',

    cost: 2290,
  },
  {
    name: 'Page D',

    cost: 2000,
  },
  {
    name: 'Page E',

    cost: 2181,
  },
  {
    name: 'Page F',

    cost: 2500,
  },
  {
    name: 'Page G',

    cost: 2100,
  },
];

const StyledTable = styled.table`
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid #e1e4ea;
  width: 100%;
  background: #fff;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #545556;
    letter-spacing: 1px;
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid #e1e4ea;
    /* border-right: 1px solid #e1e4ea;*/

    :last-child {
      border-right: 0;
    }
  }
`;

const outputTable = data => {
  return (
    <StyledTable>
      <tr>
        <th>id</th>
        <th>field_id</th>
        <th>option_id</th>
        <th>user_id</th>
        <th>attribute</th>
        <th>stringval</th>
        <th>entity_type</th>
        <th>entity_id</th>
        <th>entity_name</th>
        <th>moduleId</th>
        <th>moduleName</th>
        <th>label</th>
        <th>type</th>
      </tr>
      {data.map(d => {
        return (
          <tr>
            <td>{d.id}</td>
            <td>{d.field_id}</td>
            <td>{d.option_id}</td>
            <td>{d.user_id}</td>
            <td>{d.attribute}</td>
            <td>{d.stringval}</td>
            <td>{d.entity_type}</td>
            <td>{d.entity_id}</td>
            <td>{d.entity_name}</td>
            <td>{d.moduleId}</td>
            <td>{d.moduleName}</td>
            <td>{d.label}</td>
            <td>{d.type}</td>
          </tr>
        );
      })}
    </StyledTable>
  );
};

const outputTableAggregated = data => {
  return (
    <StyledTable>
      <tr>
        <th>max_value</th>
        <th>min_value</th>
        <th>avg_value</th>
        <th>sum_value</th>
        <th>row_count</th>
        <th>max_value_formatted</th>
        <th>min_value_formatted</th>
        <th>avg_value_formatted</th>
        <th>sum_value_formatted</th>
      </tr>
      {data.map(d => {
        return (
          <tr>
            <td>{d.max_value}</td>
            <td>{d.min_value}</td>
            <td>{d.avg_value}</td>
            <td>{d.sum_value}</td>
            <td>{d.row_count}</td>
            <td>{d.max_value_formatted}</td>
            <td>{d.min_value_formatted}</td>
            <td>{d.avg_value_formatted}</td>
            <td>{d.sum_value_formatted}</td>
          </tr>
        );
      })}
    </StyledTable>
  );
};

const outputTableGrouped = data => {
  return (
    <StyledTable>
      <tr>
        <th>moduleId</th>
        <th>moduleName</th>
        <th>freq</th>
        <th>total</th>
      </tr>
      {data.map(d => {
        return (
          <tr>
            <td>{d.moduleId}</td>
            <td>{d.moduleName}</td>
            <td>{d.freq}</td>
            <td>{d.total}</td>
          </tr>
        );
      })}
    </StyledTable>
  );
};

const outputTableGroupedUser = data => {
  return (
    <StyledTable>
      <tr>
        <th>user_id</th>
        <th>freq</th>
        <th>total</th>
        <th>user.name</th>
        <th>user.email</th>
      </tr>
      {data.map(d => {
        return (
          <tr>
            <td>{d.user_id}</td>
            <td>{d.freq}</td>
            <td>{d.total}</td>
            <td>{d.user.name}</td>
            <td>{d.user.email}</td>
          </tr>
        );
      })}
    </StyledTable>
  );
};

const ReportMetricTypeList = ({ data }) => {
  return (
    <>
      {/*<Grid container fullwidth>
        <Grid item sm={6}>
          <DemoRadar />
        </Grid>
        <Grid item sm={6}>
          <DemoPie />
        </Grid>
      </Grid>*/}
      <Box gap={16}>
        <DemoBar data={datademo} width={600} height={400} title={'Task by Cost'} />
        <DemoPie data={piedata} width={400} height={400} />
        <DemoRadar width={400} height={400} title={'Demo Radar'} />
      </Box>
      <Heading size={3}>TYPE: {data.type}</Heading>
      <Heading size={3}>Values ({data.values.length})</Heading>
      <Text>List all the Values</Text>
      {outputTable(data.values)}
      <br />
      <Heading size={3}>Aggregated Values ({data.aggregatedValues.length})</Heading>
      <Text>List all the Aggrigated Values</Text>
      {outputTableAggregated(data.aggregatedValues)}
      <br />
      <Heading size={3}>Grouped Values ({data.groupedValues.length})</Heading>
      <Text>List all the Grouped Values</Text>
      {outputTableGrouped(data.groupedValues)}
      <br />
      <Heading size={3}>Grouped By User ({data.groupedByUser.length})</Heading>
      <Text>List all the Grouped By User </Text>
      {outputTableGroupedUser(data.groupedByUser)}
      <br />
      <br />
      <br />
    </>
  );
};

export default ReportMetricTypeList;
