import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateComment } from './actions';
import BuildForm from '../BuildForm';

const CommentEdit = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateComment(data.item, module.id);
    closeHandler();
  };
  return (
    <>
      {item.links.edit ? (
        <>
          {trigger ? (
            <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
              {trigger}
            </span>
          ) : (
            <Button size="small" onClick={() => openHandler()}>
              Edit Comment [{module.id}]
            </Button>
          )}
          <Modal isOpen={modalOpen}>
            <h2> Edit Comment</h2>
            {error}
            {loading ? (
              <Loader />
            ) : (
              <>
                <BuildForm
                  apiURL={`${item.links.edit.url}`}
                  onSuccess={onSuccess}
                  completeAction={() => closeHandler()}
                  submitBtnLabel={'Update'}
                  completeBtnLabel={'Close'}
                />
              </>
            )}
          </Modal>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateComment,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommentEdit);
