import React from 'react';

export const TextInput = ({ name, register, ref, ...props }) => {
  return <input name={name} ref={register} {...props} />;
};

export const Checkbox = ({ name, label, defaultChecked, ref, register, ...props }) => {
  return (
    <label>
      <input type="checkbox" name={name} defaultChecked={defaultChecked} ref={register} {...props} /> {label}
    </label>
  );
};

export const Select = ({ name, children, register }) => {
  return (
    <select name={name} ref={register}>
      {children}
    </select>
  );
};

export const Option = ({ value, children }) => {
  return <option value={value}>{children}</option>;
};

export const BuildForm = ({ data, register }) => {
  return (
    <p>
      {data.map(d => {
        if (d.type === 'text') {
          return <TextInput name={d.value} register={register} />;
        }
        if (d.type === 'checkbox') {
          return <Checkbox name={d.value} register={register} label={d.label} />;
        }
        if (d.type === 'select') {
          return (
            <Select>
              {d.options.map(d => {
                return <Option>{d.value}</Option>;
              })}
            </Select>
          );
        }
      })}
    </p>
  );
};
