import React, { useState } from 'react';
import { Button, Modal, Loader, Popover } from '@ies-ds/react-ui';
import BuildForm from '../../BuildForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateVaultItem } from '../actions';
//import FileUpload from '../File/FileUpload';

const ReferenceEdit = ({ url, trigger, module, item, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateVaultItem(data.item, module.id, 'references');
    closeHandler();
  };

  if (item.links.edit) {
    return (
      <>
        {item.locked ? (
          <Popover trigger={trigger}>
            <p>Reference is locked </p>
          </Popover>
        ) : (
          <div onClick={() => openHandler()}>{trigger}</div>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Edit Reference</h2>
          <p>Complete the form below to edit file.</p>

          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={item.links.edit.url}
                onSuccess={onSuccess}
                completeBtnLabel={'Close'}
                submitBtnLabel={`Update`}
                completeAction={() => closeHandler()}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVaultItem,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceEdit);
