import React from 'react';
import { Text } from '@ies-ds/react-ui';

const Description = ({ module }) => {
  return (
    <>
      <div style={{ background: '#fff', border: '1px solid #e1e4ea', padding: '16px', borderRadius: '3px' }}>
        <Text size="small">{module.description}</Text>
      </div>
    </>
  );
};

export default Description;
