import React, { useEffect, useState } from 'react';
import { Switch, Route, useParams, useRouteMatch, Link } from 'react-router-dom';
import ModuleLoader from '../../components/Module/ModuleLoader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { fetchModule } from '../../components/Module/actions';

import ArticleViewer from './Viewer';
import { CloneList, CloneAdd } from '../../components/Module/Clone';

import Step from './Step';
import Chat from './components/Chat';
import Breadcrumbs from './components/Breadcrumbs';

import {
  Heading,
  Text,
  Container,
  Box,
  Icon,
  Sidepanel,
  Button,
  Grid,
  Avatar,
  AvatarGroup,
  Modal,
} from '@ies-ds/react-ui';

import { Link as ScrollLink } from 'react-scroll';
import { baseURL } from '../../settings';
import { getImageURL } from '../../utils';
import { fetchJson } from '../../actions/higherorder';
import { lte } from 'lodash';

const StlyedScrollLink = styled(ScrollLink)`
  padding: 4px 12px;
  border-radius: 4px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #757b90 !important;
  &:hover {
    cursor: pointer;
    color: black !important;
    text-decoration: none;
  }
  &.active {
    color: black !important;
    background: #dce3ea;
  }
`;

const Project = ({ ...props }) => {
  const { projectId } = useParams();
  const { path, url } = useRouteMatch();

  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    props.fetchModule(projectId);
    return () => {
      {
        /*clean*/
      }
    };
  }, [projectId]);

  const GetAvatar = ({ data }) => {
    let name = data.userName;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    return (
      <Avatar size="small" name={data.userName} src={getImageURL(`${baseURL}/api/avatar/view/${data.userId}`)}>
        {((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase()}
      </Avatar>
    );
  };

  return (
    <>
      {props.isFetching ? (
        'Loading'
      ) : (
        <>
          <Grid>
            <Grid item sm={10}>
              <Text size="small" color="light">
                <Breadcrumbs data={props.data} />
              </Text>
              <Box justifyContent="space-between">
                <Box justifyContent="flex-start" gap="8">
                  <Text component="h2" style={{ margin: '0' }}>
                    {props.data.name}
                  </Text>
                  <AvatarGroup size="xsmall">
                    {props.data.memberships.map(data => {
                      return <GetAvatar data={data} />;
                    })}
                  </AvatarGroup>
                </Box>
                <Box justifyContent="flex-end" gap="4">
                  <Button
                    size="xsmall"
                    color="primary"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Clone
                  </Button>
                  <Modal
                    size="xlarge"
                    isOpen={isOpen}
                    onClose={() => {
                      setIsOpen(false);
                    }}
                    closeButton
                  >
                    <Modal.Header title={`Clone ${props.data.name}`} />
                    <Modal.Content>
                      <CloneAdd module={props.data} trigger={<Button size="small">Clone from template</Button>} />
                      <br />
                      <br />
                      <CloneList module={props.data} />
                    </Modal.Content>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Button
                    size="xsmall"
                    color="primary"
                    as={Link}
                    // to={`/portal/${props.data.parentId}/projects/${props.data.id}`}
                    to={`/v2/${props.data.platform}/${props.data.parentId}/${props.data.id}`}
                  >
                    Edit Project
                  </Button>
                </Box>
              </Box>
              <Text color="light" style={{ marginBottom: '16px' }}>
                {props.data.description}
              </Text>

              <div>
                {props.data.children.map(item => {
                  return (
                    <div name={item.id} id={item.id} style={{ padding: '24px 0' }}>
                      <Container style={{ width: '100%' }}>
                        <Box justifyContent="space-between">
                          <Text component="h3">{item.name}</Text>
                          <div>
                            <Chat module={item} />
                          </div>
                        </Box>

                        <Text color="light">{item.description}</Text>
                        <Step module={item} />
                      </Container>
                    </div>
                  );
                })}
              </div>
              <div style={{ height: '85vh' }}></div>
            </Grid>
            <Grid item sm={2}>
              <div style={{ padding: '0px 12px', position: 'fixed' }}>
                {props.data.children.map(item => {
                  return (
                    <StlyedScrollLink to={item.id} spy={true} smooth={true} containerId="containerElement">
                      {item.name}
                    </StlyedScrollLink>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      <Switch>
        <Route path={`${url}/doc/:moduleId/:articleId`}>
          <ArticleViewer backURL={url} />
        </Route>
      </Switch>
    </>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  },
) => {
  const { entities } = state;
  const { isFetching, data } = entities.modules[projectId] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Project);
