import React, { useState, useEffect } from 'react';
import { baseURL } from '../../settings';
import { fetchJson } from '../../actions/higherorder';

import { NavLink, Link, useParams, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Heading, Text, Grid, Button, Loader, Box, Modal } from '@ies-ds/react-ui';

const StyledEditorContainer = styled.div`
  .wysiwyg-wrapper {
    min-height: 300px;
  }
  .editor-panel {
    min-height: 280px;
    padding: 0 12px 16px 12px;
    &:hover {
      cursor: text;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  &:hover {
    cursor: pointer;
    color: #ccc;
    text-decoration: underline;
  }
`;

const ArticleViewer = ({ module, backURL, ...props }) => {
  const { articleId, moduleId } = useParams();
  let { path, url } = useRouteMatch();
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    fetchArticle(`${baseURL}/api/article/view/${moduleId}/${articleId}`);
    return () => {
      /*cleanup*/
    };
  }, [articleId]);

  function closeModal() {
    history.push(backURL);
  }

  const fetchArticle = apiURL => {
    fetchJson(apiURL)
      .then(data => {
        setLoading(false);
        setData(data.item);
        updateContent(data.item.content);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateContent = data => {
    const output = EditorState.createWithContent(convertFromRaw(JSON.parse(data)));
    setEditorState(output);
  };

  const [editorState, setEditorState] = useState(null);

  return (
    <>
      {loading ? (
        <>
          <Loader type="dots" />
          Loading...
        </>
      ) : (
        <>
          <Modal isOpen={true} onClose={() => closeModal()} closeButton size="xlarge">
            <Modal.Header title={data.title} />
            <Modal.Content>
              <Editor editorState={editorState} readOnly toolbarHidden />
            </Modal.Content>
            <Modal.Footer>
              {' '}
              <Button to={backURL} as={Link} appearance="ghost" icon="close">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ArticleViewer;
