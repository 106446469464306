import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';

import BuildForm from '../../components/BuildForm';

const InviteUser = ({ module, trigger, onCallback, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
    onCallback();
  };

  const onSuccess = data => {
    closeHandler();
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" color="primary" onClick={() => openHandler()}>
          Invite User
        </Button>
      )}
      <Modal isOpen={modalOpen}>
        <h2> Invite new user to join</h2>
        <p>Complete the form below to add a new user. </p>
        {error}
        {loading ? (
          <Loader />
        ) : (
          <>
            <BuildForm
              apiURL={`/api/invitation/invite-colleague/${module.id}`}
              onSuccess={onSuccess}
              completeAction={() => closeHandler()}
              submitBtnLabel={`Send Invite`}
              completeBtnLabel={'Close'}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default InviteUser;
