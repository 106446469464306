import React from 'react';
import { CloneAdd, CloneConfigAdd, CloneList } from './';
import { Button, Heading, Text } from '@ies-ds/react-ui';

const ClonePanel = ({ module }) => {
  return (
    <>
      {module.links.edit ? (
        <>
          <Heading size={'2'} noMargin>
            Copy: {module.name}
          </Heading>
          <Text>Create a new version from a preconfigured template</Text>
          <CloneAdd
            module={module}
            trigger={
              <Button size={'small'} color={'primary'}>
                Create New Version from Template
              </Button>
            }
          />

          <br />
          <br />
          <CloneList module={module} />
        </>
      ) : (
        'No permissions to make a new version'
      )}
    </>
  );
};

export default ClonePanel;
