import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import testReducer from './testReducer';
//import authReducer from './session/auth';
import profileReducer from './session/profile';
import createDataLoader from './higherOrderReducer';

//import comments from './comments';

import { session } from './session';
import { entities } from './entities';

/*const entities = combineReducers({
  profile: profileReducer,
});*/

const data = [{ test: 'dfd' }];

export const modules = combineReducers({
  '4rineu_portal': createDataLoader('4rineu_portal'),
  '4rineu_project': createDataLoader('4rineu_project'),
  '4rineu_task': createDataLoader('4rineu_task'),
});

const createRootReducer = history =>
  combineReducers({
    session: session,
    entities: entities,
    router: connectRouter(history),
    profile: createDataLoader('PROFILE'),
    portals: createDataLoader('PORTALS'),
    portal: createDataLoader('PORTAL'),
    projects: createDataLoader('PROJECTS'),
    project: createDataLoader('PROJECT'),
    tasks: createDataLoader('TASKS'),
    task: createDataLoader('TASK'),
    subtasks: createDataLoader('SUBTASKS'),
    subtask: createDataLoader('SUBTASK'),
    testState: testReducer,
    modules: modules,

    //comments: comments,
    //auth: authReducer,
  });

export default createRootReducer;
