import React from 'react';
import styled from 'styled-components';
import DemoPie from './democharts/pie';

const piedata = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const ReportMetricTypePicklist = ({ data }) => {
  const dataArry = [];

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, []);
  };

  const personGroupedByColor = groupBy(data.values, 'option_label');

  Object.keys(personGroupedByColor).map(function(key, index) {
    dataArry.push({ name: `${key} (${personGroupedByColor[key].length})`, value: personGroupedByColor[key].length });
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DemoPie data={dataArry} width={300} height={300} />
    </div>
  );
};

export default ReportMetricTypePicklist;
