import React from 'react';
import { Grid, Text, Box } from '@ies-ds/react-ui';
import { Title, Status, Deliverable, Description, Members, Image } from './';
import { checkNodeExists } from '../../../utils';

const Header = ({ module }) => {
  return (
    <Grid container fullwidth>
      <Grid item lg={6} sm={12}>
        <Box gap={8} style={{ alignItems: 'flex-start' }}>
          <Image module={module} />

          <div>
            <Text size={'xsmall'} style={{ margin: '-4px 0px 4px 0px' }}>
              {module.typeLabel} ID:{module.id}
            </Text>
            <Title module={module} />
            <div style={{ marginTop: '8px' }}>
              <Status module={module} />
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item lg={6} sm={12}>
        <Members module={module} />
      </Grid>
      {checkNodeExists(module.deliverable) ? (
        <Grid item sm={12}>
          <Deliverable module={module} />
        </Grid>
      ) : null}
      <Grid item sm={12}>
        <Description module={module} />
      </Grid>
    </Grid>
  );
};

export default Header;
