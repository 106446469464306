import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Loader, Box, Heading, Text, Icon } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import moment from 'moment';
import IconButton from '../../ies-ui/IconButton';
import { imageUrlFetch, fetchJson } from '../../actions/higherorder';
import { checkNodeExists } from '../../utils';

import { NavLink, Link, useParams, Route, Switch, useRouteMatch } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refeshArticles } from './actions';

import { ArticleDelete, ArticleReader, ArticleEditor, ArticleAdd, ArticleViewer } from './';

import Nestable from 'react-nestable';

const StyledRow = styled.div`
  background: #fff;
  border-bottom: 1px solid #e1e4ea;
  /*border-left: 2px solid #e1e4ea;*/
  padding: 12px;
  /* margin-top: 1px;*/
  &:hover {
    /*background: rgba(0, 0, 0, 0.031);*/
  }
`;

const ActionLinks = ({ module, item }) => {
  let { path, url } = useRouteMatch();

  return item.links ? (
    <>
      {module.links.view ? (
        <Link to={`${url}/${item.id}`}>
          <IconButton size="16" icon="file" title={'View'} />
        </Link>
      ) : null}
      {module.links.edit ? (
        <Link to={`${url}/${item.id}/edit`}>
          <IconButton size="16" icon="edit" title={'Edit'} />
        </Link>
      ) : null}

      <ArticleDelete
        module={module}
        item={item}
        articleId={item.id}
        trigger={<IconButton size="16" icon="bin" title={'Delete'} />}
      />
    </>
  ) : null;
};

const ArticleRow = ({ module, item, child }) => {
  let { path, url } = useRouteMatch();
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    visible ? setVisible(false) : setVisible(true);
  };

  const hasChild = item.children.length > 0;

  return (
    <div style={{ background: '#eaeef7' }}>
      <StyledRow>
        <Box justifyContent={'space-between'} style={{ alignItems: 'flex-start' }}>
          <Box>
            <div style={{ width: '40px' }}>
              {hasChild && (
                <Button
                  appearance="ghost"
                  size={'xsmall'}
                  onClick={toggleVisibility}
                  icon={visible ? 'minus24' : 'add24'}
                />
              )}
            </div>
            <div>
              <Text weight="bold" as="div">
                <Link to={`${url}/${item.id}`} style={{ color: '#333' }}>
                  {item.title}
                </Link>
              </Text>
              <Box></Box>
              <Text as="div" size="xsmall" style={{ margin: '0' }} color="lighter" weight="medium">
                Author: {item.createdBy} | Created: {moment(item.created).format('Do MMM YYYY')}
                {/* [id: {item.id} | Parent ID: {item.parentId}  Child Count: {item.children.length}{' '}]*/} <br />
                {item.links.edit && (
                  <Link to={`${url}/new/${item.id}`}>
                    <Text as="span" color="lightest">
                      + Add Knowledge
                    </Text>
                  </Link>
                )}
              </Text>
            </div>
          </Box>

          <div style={{ textAlign: 'right' }}>
            {/** ADD LINKS BACK ONCE CHILDREN HAVE LINEKS*/} <ActionLinks item={item} module={module} />
          </div>
        </Box>
      </StyledRow>
      {visible && hasChild ? (
        <div style={{ paddingLeft: '32px' }}>
          {item.children.map(item => (
            <div style={{ paddingLeft: '0' }}>
              <ArticleRow item={item} module={module} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const ArticleList = ({ module, ...props }) => {
  let { path, url } = useRouteMatch();
  const { articleid } = useParams();

  const items = [
    { id: 0, text: 'Andy' },
    {
      id: 1,
      text: 'Harry',
      children: [{ id: 2, text: 'David' }],
    },
    { id: 3, text: 'Lisa' },
  ];

  const renderItem = ({ item }) => <div style={{ border: '1px solid #ddd', background: '#fff' }}> {item.title}</div>;

  console.log(props.library.articles);

  return (
    <>
      {(module.library.links.add_article || module.library.links.add) && (
        <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
          <Button size={'small'} as={Link} to={`${url}/new/`}>
            Add Knowledge
          </Button>
          <Link to={`${url}/reader/`}>.</Link>
        </Box>
      )}

      <Switch>
        <Route path={`${url}/new/:articleId`}>
          <ArticleAdd backURL={url} module={module} />
        </Route>
        <Route path={`${url}/new`}>
          <ArticleAdd backURL={url} module={module} />
        </Route>

        <Route path={`${url}/reader/:articleId`}>
          <ArticleReader backURL={url} module={module} />
        </Route>

        <Route exact path={`${url}/:articleId/edit`}>
          <ArticleEditor backURL={url} module={module} />
        </Route>
        <Route path={`${url}/:articleId`}>
          <ArticleViewer backURL={url} module={module} />
        </Route>
      </Switch>

      {props.library.articles.map(item => (
        <ArticleRow item={item} url={url} module={module} />
      ))}

      <br />
      <br />
      <br />

      {/*<Nestable items={props.library.articles} renderItem={renderItem} />*/}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const {
    isFetching,
    lastUpdated,
    data: { library },
    doesExsist,
  } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    selectedSubreddit,
    library,
    isFetching,
    doesExsist,
    lastUpdated,

    articles: state.entities.articles,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refeshArticles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
