export const insertTypeMembership = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const updateTypeMembership = (typeConstant, data) => {
  return {
    type: `UPDATE_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const createTypeMembership = (typeConstant, data) => {
  return {
    type: `CREATE_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const deleteTypeMembership = (typeConstant, data) => {
  return {
    type: `DELETE_${typeConstant.toUpperCase()}_MEMBERSHIP`,
    data,
  };
};

export const insertTypeListeMembership = (typeConstant, data) => {
  return {
    type: `INSERT_${typeConstant.toUpperCase()}_LISTITEM_MEMBERSHIP`,
    data,
  };
};
