import React, { useState } from 'react';
import { Button, Modal, Loader, Heading, Text } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { addModule } from './actions';
import BuildForm from '../../BuildForm';

const CloneConfigAdd = ({ module, trigger, onComplete, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    // props.addModule(data.item, module.id);
    onComplete(data.item);
    setLoading(false);
    setModalopen(false);
  };

  if (true /*module.links[type]*/) {
    const { route, url, label, access } = module.links;

    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Add Clone
          </Button>
        )}
        <Modal isOpen={modalOpen}>
        <Heading size="2">Add Configuration Template </Heading>
          <Text>
            Create Template for: <strong>{module.name} </strong>
          </Text>

          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`${`/api/clone-config/add/${module.id}`}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Create'}
                completeBtnLabel={'Cancel'}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //  addModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CloneConfigAdd);
