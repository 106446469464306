import React, { useEffect, useState } from 'react';
import { checkNodeExists, childCount, ModuleCustomValues } from '../utils';
import ModuleStatus from '../utils/moduleStatus';

import { NavLink, Link, useParams, Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { Avatar, Box, Text, Loader, Button, Card, Heading, Grid } from '@ies-ds/react-ui';
import { baseURL } from '../settings';
import ModuleActionLinks from '../components/Module/ModuleActionsLinks';
import { DeliverableAdd, DeliverableEdit } from '../components/Deliverable';
import StatusBadge from '../components/StatusBadge';
import { InviteUser, MemberList } from '../components/Membership';
import IconButton from '../ies-ui/IconButton';
import { Articles } from '../components/Article/';
import { FileList } from '../components/Vault/File/';
import { ReferenceList } from '../components/Vault/Reference';
import GanttChart from '../components/Module/GanttChart';
import { MembershipDatatable, MembershipAdd, MembershipEdit, MembershipDelete } from '../components/Membership';
import { ReportDashboard } from '../components/Report';

import { CommentList, CommentAdd } from '../components/Comment/';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchType } from '../actions/higherorder';
import { fetchModule } from '../components/Module/actions';

import TaskList from '../components/TaskList';
import { ModuleList } from '../components/Module';
import ListLoader from '../components/Module/ListLoader';
import { ClonePanel } from '../components/Module/Clone';

import FeedList from '../components/FeedList';

import { Checklist } from '../components/Module';

import styled from 'styled-components';

import { Header } from '../components/Module/layout';

import pluralize from 'pluralize';

const StyledNavLinkContainer = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
`;
const StyledNavLink = styled(NavLink)`
  font-size: 14px;
  font-weight: 700;
  color: #999;
  padding: 16px;
  display: inline-block;
  margin-bottom: -1px;
  &.active {
    border-bottom: 2px solid #4d58e8;
    color: #000;
  }
`;

const Task = ({ returnURL, ...props }) => {
  const { id, pid, tid } = useParams();
  //const { loading, data } = props.task;
  let { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(tid);
    //props.fetchType('TASK', `/api/module/view/${tid}`);
  }, [tid]);

  const getPlatformNode = moduleType => {
    const { data } = props.platform;

    function iterate(obj, key) {
      var result;
      for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (property === key) {
            return obj[key]; // returns the value
          } else if (typeof obj[property] === 'object') {
            // in case it is an object
            result = iterate(obj[property], key);
            if (typeof result !== 'undefined') {
              return result;
            }
          }
        }
      }
    }

    return data.name == moduleType ? data : iterate(data.children, moduleType);
  };

  const moduleTabs = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return Object.keys(platformNode.children).map(function(key) {
        const { label } = platformNode.children[key];

        return <StyledNavLink to={`${url}/${labelUrl(label)}`}>{labelName(label)}</StyledNavLink>;
      });
    }
  };

  const moduleDefaultRoute = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(platformNode.children).map(function(key) {
            const { label } = platformNode.children[key];
            return <Redirect exact from={`${url}/`} to={`${url}/${labelUrl(label)}`} />;
          });
    }
  };

  const moduleRoutes = data => {
    {
      const platformNode = getPlatformNode(data.type);
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(platformNode.children).map(function(key) {
            const { label } = platformNode.children[key];
            return (
              <Route path={`${path}/${labelUrl(label)}`}>
                {/*<ModuleList data={data} moduleType={getType(data.links[key].url)} />*/}
                <ListLoader data={data} />
                <Checklist data={data} />
                <br />
                <br />
                <ModuleCustomValues module={data} />
              </Route>
            );
          });
    }
  };

  const moduleSubRoutes = (data, path) => {
    {
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(data.links).map(function(key) {
            switch (data.links[key].access) {
              case 'create':
                return `${labelUrl(data.links[key].label)}|`;
            }
          });
    }
  };

  const getType = url => {
    const lastslashindex = url.lastIndexOf('/');
    const result = url.substring(lastslashindex + 1);
    return result;
  };

  const labelName = label => {
    return (label = pluralize(label.replace('Add ', '')));
  };

  const labelUrl = label => {
    return (label = pluralize(
      label
        .replace('Add ', '')
        .toLowerCase()
        .replace(' ', '-'),
    ));
  };

  // const task = data.item;

  return (
    <div style={{ position: 'fixed', background: 'rgba(0,0,0,0.7)', top: 0, left: 0, right: 0, bottom: 0 }}>
      <Link
        style={{
          color: '#fff',
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '80%',
          display: 'block',
          textAlign: 'right',
          padding: '16px',
        }}
        to={returnURL}
      >
        X CLOSE
      </Link>
      <div
        style={{
          position: 'absolute',
          background: '#f5f5f5',
          top: '0',
          left: '20%',
          right: '0',
          bottom: '0',
          padding: '16px',
          overflow: 'auto',
        }}
      >
        {props.isFetching ? (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Loader />
            <Text size="small">Task Loading...</Text>
          </div>
        ) : (
          <div>
            <Header module={props.data}>{props.data.name}</Header>
            <Grid container fullwidth>
              <Grid item sm={12}>
                <StyledNavLinkContainer>
                  {/*CHILDREN MODULES*/}
                  {moduleTabs(props.data)}

                  {/*COMPONENTS OF MODULE*/}

                  {checkNodeExists(props.data.library) ? (
                    <StyledNavLink to={`${url}/knowledge`}>
                      Knowledge ({childCount(props.data.library.article)})
                    </StyledNavLink>
                  ) : null}

                  {checkNodeExists(props.data.vault) ? (
                    <StyledNavLink to={`${url}/files`}>Files ({childCount(props.data.vault.files)})</StyledNavLink>
                  ) : null}

                  {checkNodeExists(props.data.vault) ? (
                    <StyledNavLink to={`${url}/references`}>
                      References ({childCount(props.data.vault.references)})
                    </StyledNavLink>
                  ) : null}

                  {checkNodeExists(props.data.discussion) ? (
                    <StyledNavLink to={`${url}/comments`}>
                      Comments ({childCount(props.data.discussion.comments)})
                    </StyledNavLink>
                  ) : null}

                  {checkNodeExists(props.data.report) ? (
                    <StyledNavLink to={`${url}/metric`}>
                      Reports / Metrics ({childCount(props.data.report.metrics)})
                    </StyledNavLink>
                  ) : null}

                  {/*DATA FROM MODULE*/}
                  <StyledNavLink to={`${url}/editors`}>Editors</StyledNavLink>
                  <StyledNavLink to={`${url}/feed`}>Feed</StyledNavLink>
                  {/*EXTRA TBC*/}
                  {false ? <StyledNavLink to={`${url}/gantt-chart`}>Gantt Chart</StyledNavLink> : null}
                </StyledNavLinkContainer>

                <Switch>
                  {moduleDefaultRoute(props.data, url)}
                  {moduleRoutes(props.data, path)}
                  <Route path={`${path}/knowledge`}>
                    <Articles module={props.data} moduleType={'PROJECT'} />
                  </Route>
                  <Route exact path={`${path}/files`}>
                    <FileList module={props.data} moduleType={`project`} />
                  </Route>
                  <Route exact path={`${path}/references`}>
                    <ReferenceList module={props.data} />
                  </Route>
                  <Route exact path={`${path}/comments`}>
                    <CommentList module={props.data} data={props.data.discussion} />
                    <CommentAdd moduleId={props.data.id} moduleType={'project'} cols="100" />
                  </Route>
                  <Route exact path={`${path}/feed`}>
                    <FeedList id={props.data.id} />
                  </Route>
                  <Route exact path={`${path}/gantt-chart`}>
                    <GanttChart moduleID={props.data.id} />
                  </Route>
                  <Route exact path={`${path}/editors`}>
                    <MembershipDatatable moduleId={props.data.id} data={props.data.memberships} />
                  </Route>
                  <Route path={`${path}/metric`}>
                    <ReportDashboard module={props.data} moduleId={props.data.id} />
                  </Route>

                  <Route path={`${path}/clone`}>
                    <ClonePanel module={props.data} />
                  </Route>
                </Switch>
                {/* //// STATUS TEST   
                <ModuleStatus module={props.data} />*/}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { tid },
    },
  },
) => {
  const { selectedSubreddit, entities, entities: modules, session } = state;
  const { isFetching, lastUpdated, data, data: children } = entities.modules[tid] || {
    isFetching: true,
    items: [],
  };

  const { platform } = session;

  return {
    platform,
    modules,
    data,
    selectedSubreddit,
    children,
    isFetching,
    lastUpdated,

    subtasks: state.subtasks,
    task: state.task,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Task);
