import axios from 'axios';
import * as actionTypes from './actionTypes';
import { baseURL } from '../settings';
import { checkStatus, fetchJson } from './higherorder';

export const profileGet = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.PROFILE_GET,
    });

    fetch(`${baseURL}/api/profile/currentuser`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    })
      .then(checkStatus)
      .then(r => r.json())
      .then(data => {
        dispatch(profileGetSuccess(data.item));
      })
      .catch(error => {
        dispatch(profileGetFail(error));
      });
  };
};

export const profileGetSuccess = data => {
  return {
    type: actionTypes.PROFILE_GET_SUCCESS,
    data: data,
  };
};

export const profileGetFail = error => {
  return {
    type: actionTypes.PROFILE_GET_FAIL,
    error: error,
  };
};

export const profileReset = () => {
  return {
    type: actionTypes.PROFILE_RESET,
  };
};
