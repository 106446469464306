import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Heading, Grid, Card, Text, Loader, Layout, Button } from '@ies-ds/react-ui';
import { Link } from 'react-router-dom';
import { fetchType, imageUrlFetch } from '../actions/higherorder';
import { baseURL } from '../settings';
import { checkNodeExists, getImageURL } from '../utils';

import { SaveLogo } from '../components/Brand';

const Portals = props => {
  const { loading, data } = props.portals;

  if (loading) return <Loader size="small" />;

  function setURL(item) {
    if (item.platform == 'calibr') {
      return `/calibr/${item.id}`;
    } else if (item.platform == 'peb') {
      return `/peb/${item.id}`;
    } else {
      return `/portal/${item.id}/projects`;
    }
  }

  return (
    <Grid container>
      <Grid item sm={12}>
        <Heading size="2">Portals</Heading>
      </Grid>
      {// Check if message failed
      data.list === undefined || data.list.length == 0 ? (
        <Grid item sm={12}>
          <Text>You have not been assigned yet. Please contact your administrator</Text>
        </Grid>
      ) : (
        data.list.map(item => (
          <Grid item sm={12} md={6} lg={3} style={{ position: 'relative' }}>
            {item.links.edit && (
              <SaveLogo
                module={item}
                moduleId={item.id}
                trigger={
                  <Button
                    color="primary"
                    size={'xsmall'}
                    style={{ position: 'absolute', top: '0', right: '8px', color: 'rgba(255,255,255,0.8)' }}
                  >
                    Edit
                  </Button>
                }
              />
            )}

            <Link
              key={item.id}
              to={setURL(item)}
              style={{ width: '100%', height: '275px', textDecoration: 'none', color: '#343' }}
              onClick={event => {
                event.stopPropagation();
              }}
            >
              <Card
                showBorder
                style={{
                  width: '100%',
                  height: '255px',
                  flex: '1',
                }}
              >
                <Card.Image
                  src={
                    checkNodeExists(item.brand) ? `${getImageURL(`${baseURL}/api/brand/logo/${item.brand.id}`)}` : null
                  }
                />

                <Card.Header>
                  <Heading size="3">{`${item.name} ${item.id}`}</Heading>
                </Card.Header>
                <Card.Content>
                  <Text size={'small'}>{item.description}</Text>
                </Card.Content>
              </Card>
            </Link>
          </Grid>
        ))
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    portals: state.portals,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Portals));
