import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import App from './App';
import {ThemeProvider} from '@ies-ds/react-ui'

import RootContext from './routes/RootContext';

const store = configureStore(/* initial state (if any) */);

const domTarget = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  domTarget,
);

//serviceWorker.unregister();
