import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import BuildForm from '../../BuildForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { insertTypeFile } from '../../../actions/higherorder';

import { fetchModule } from '../../Module/actions';

import { createVaultItem } from '../actions';

const VaultUpload = ({ module, moduleType, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  /*const {
    vault: { files, links },
  } = module;*/

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.createVaultItem(data.item, module.id, 'files');
    closeHandler();
  };

  if (true) {
    return (
      <>
        {trigger ? (
          <div onClick={() => openHandler()}>{trigger}</div>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Upload File
          </Button>
        )}
        <Modal isOpen={modalOpen}>
          <h2> Upload new file</h2>
          <p>Complete the form below to add a new file. </p>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`/api/vault/upload/${module.id}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={`Upload`}
                completeBtnLabel={'Close'}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createVaultItem,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VaultUpload);
