import React, { useState, useEffect } from 'react';
import BrandSaveLogo from '../../Brand/SaveLogo';
import { baseURL } from '../../../settings';
import { Avatar } from '@ies-ds/react-ui';
import { checkNodeExists, getImageURL } from '../../../utils';
//import { blogUrlFetch } from '../../../actions/higherorder';

import styled from 'styled-components';

const StyledImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 3px;
  background-size: cover;
  background: #e4e7ec;
`;

const Image = ({ module }) => {
  return (
    <>
      {checkNodeExists(module.brand) ? (
        <>
          {module.brand.links.view_logo ? (
            module.brand.links.save_logo ? (
              <BrandSaveLogo
                module={module}
                trigger={
                  <StyledImage
                    style={{
                      background: `#e4e7ec url(${getImageURL(
                        `${baseURL}/api/brand/logo/${module.brand.id}?${module.brand.logo.filesize}`,
                      )}) center center / cover no-repeat`,
                    }}
                  />
                }
              />
            ) : (
              <StyledImage
                style={{
                  background: `#e4e7ec url(${getImageURL(
                    `${baseURL}/api/brand/logo/${module.brand.id}?${module.brand.logo.filesize}`,
                  )}) center center / cover no-repeat`,
                }}
              />
            )
          ) : (
            <BrandSaveLogo module={module} trigger={<StyledImage size="xxlarge" type="square" />} />
          )}
        </>
      ) : null}
    </>
  );
};

export default Image;
