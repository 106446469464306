import React from 'react';
import ArticleList from './List';
import ArticleView from './View';
import ArticleEdit from './Edit';
import ArticleAdd from './Add';

import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

const Articles = ({ module, moduleType }) => {
  let { path, url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/`}>
          <ArticleList module={module} moduleType={module.type} />
        </Route>
        <Route path={`${path}/add/:parentArticleId`}>
          <ArticleAdd returnURL={url} moduleId={module.id} />
        </Route>
        <Route path={`${path}/add`}>
          <ArticleAdd returnURL={url} moduleId={module.id} />
        </Route>

        <Route path={`${path}/:articleId/edit`}>
          <ArticleEdit returnURL={url} moduleId={module.id} />
        </Route>
        <Route path={`${path}/:articleId`}>
          <ArticleView returnURL={url} moduleId={module.id} />
        </Route>
      </Switch>
    </>
  );
};

export default Articles;
