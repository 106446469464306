import React, { useState, useEffect, useContext } from 'react';

import { ThemeProvider, Layout, BaseTheme, Heading, Text, Button, Box, Card, Loader, Grid, Textfield } from '@ies-ds/react-ui';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import {forgotPassword} from "../../actions/auth";
import {Link} from "react-router-dom";

import {appdata} from '../../settings'

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

const MessageStyle = styled.span`
  font-size: 14px;
  color: blue;
`;

const Password = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
    const [message, setMessage] = useState('');

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
      event.preventDefault();
      setLoading(true);
      //debugger;
      let email = {
          email: data.email.trim()
      };
      forgotPassword(email)
          .then(data => {
             if (data.data.message){
                 setMessage(data.data.message);
             }
              setLoading(false);
          })
          .catch(function(error) {
              setError(error.response.data.error);
              setLoading(false);
          });
  };

    function handleChange(event){
        setError('');
        setMessage('');
    }

  return (
    <div>
      <ThemeProvider>
        <Layout>
          <Grid container fullwidth>
            <Grid
              item
              sm={12}
              md={6}
              style={{ height: '100vh', background: '#f4f7fe', display: 'flex', position: 'relative' }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  right: '0',
                  left: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card showBorder style={{ flex: 'unset' }}>
                  <Card.Content>
                  <div style={{ margin: '16px 0 32px 0', height: '64px', width: '100%', background: `url(${appdata.logo}) left center / contain no-repeat`}}>
                        
                        </div>
                    <h2>Forgot Password</h2>
                    <Text>Enter your email to send you the password link</Text>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '350px' }}>
                      <div className="field" style={{ margin: '8px 0 8px 0' }}>

                        <Textfield
                          type="text"
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="email address"
                          ref={register({
                            required: true,
                            pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                          })}
                          style={{maxWidth: '100%'}}
                          onChange={handleChange}
                        />
              
                        <StyledErrorLabel>
                          {errors.email && errors.email.type === 'required' && 'Email is required'}
                          {errors.email &&
                            errors.email.type === 'pattern' &&
                            'Please enter a valid email address'}
                        </StyledErrorLabel>
                      </div>
                      <div>
                        <StyledErrorLabel><p>{error}</p></StyledErrorLabel>
                        <MessageStyle><p>{message}</p></MessageStyle>
                      </div>


                      <div className="field">
                        <Button type="submit" color="secondary" style={{justifyContent: 'center', width: '100%'}}>

                          {loading ? (
                            <>
                              <Loader size="small" type="dots" /> Sending
                            </>
                          ) : (
                            'Submit'
                          )}
                        </Button>

                      </div>
                        <br />
                        <div >
                            <Link to="/login">Back to Login</Link>
                        </div>
                    </form>
                  </Card.Content>
                </Card>
              </div>
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                height: '100vh',
                background:
                  `url(${appdata.loginimg}) center center / cover no-repeat`,
              }}
            ></Grid>
          </Grid>
        </Layout>
      </ThemeProvider>
    </div>
  );
};



export default Password;
