import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTypeMembership } from '../../actions/membership';
import { createMembership } from './actions';

import BuildForm from '../../components/BuildForm';

import { InviteUser } from './';

const MembershipAdd = ({ module, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.createMembership(data.item, module.id);
    //props.createTypeMembership(`PROJECT`, data.item);
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()} title="Add User">
          {trigger}
        </div>
      ) : (
        <Button title="Add User" size="small" onClick={() => openHandler()}>
          Add
        </Button>
      )}
      <Modal isOpen={modalOpen}>
        {error}
        {loading ? (
          <Loader />
        ) : (
          <>
            <a
              style={{ position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', color: '#a7a7a7' }}
              onClick={() => closeHandler()}
            >
              x
            </a>
            <h2>Add Exsisting User </h2>
            <p>Complete the form below to add user. </p>
            <BuildForm
              apiURL={`/api/membership/add/${module.id}`}
              onSuccess={onSuccess}
              completeAction={() => closeHandler()}
              submitBtnLabel={`Add User`}
              completeBtnLabel={'Cancel'}
              formDataMethodFix={true}
            />
          </>
        )}
        <hr style={{ border: '0', borderBottom: '1px solid #ccc', margin: '16px 0 16px 0' }} />
        <h3>New User </h3>
        <p>Invite a new user to the application</p>
        <InviteUser module={module} onCallback={() => closeHandler()} />
        <br />
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({ createMembership, createTypeMembership }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MembershipAdd);
