import React from 'react';
import { Text, Box, Loader, Icon, Button } from '@ies-ds/react-ui';
import prettyBytes from 'pretty-bytes';

import styled from 'styled-components';

import { ReferenceAdd, ReferenceDelete } from '../../../components/Vault/Reference';

import { getFileURL } from '../../../utils';
import { baseURL } from '../../../settings';

const GetLink = ({ file }) => {
  return (
    <a href={getFileURL(`${baseURL}${file.links.download.url}`)} download={file.filename}>
      <Text as="span" size="xsmall" weight="medium" color="dark">
        {file.title}
      </Text>
    </a>
  );
};

const StyledFile = styled(Box)`
  background: #fff;
  display: inline-flex;
  margin: 0 4px 4px 0;
  padding: 8px 9px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  &:hover {
    background: #f9fbfd;
    border: 1px solid #b9cdf9;
  }
`;

const Link = ({ link, module }) => {
  return (
    <>
      <StyledFile gap="4" alignItems="center">
        <svg
          style={{ width: '14px' }}
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="video"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          class="svg-inline--fa fa-video fa-w-18 fa-3x"
        >
          <path
            fill="currentColor"
            d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"
            class=""
          ></path>
        </svg>

        <a href={link.url} target="_blank">
          <Text as="span" size="xsmall" weight="medium" color="dark">
            {link.title} [{link.resourceType}]
          </Text>
          {/* <div>{link.description}</div>*/}
        </a>
        {/*
        <GetLink file={link} />
        <a href="javascript:void(0)" title="Delete">
          <ReferenceDelete
            module={module}
            item={link}
            trigger={<Button icon="close24" appearance="ghost" size="xsmall" color="warning" style={{ margin: '0' }} />}
          />
        </a>*/}
      </StyledFile>
    </>
  );
};

const Links = ({ links, module }) => {
  return (
    <div>
      {links.length > 0 && 'Links'}
      <div style={{ marginTop: '4px' }}>
        {links.map(link => {
          return <Link link={link} module={module} />;
        })}
        <ReferenceAdd
          module={module}
          item={module}
          trigger={
            <Text color="lighter" as="a" size="xsmall" href="javascript:void(0)">
              + Add Link
            </Text>
          }
        />
      </div>
    </div>
  );
};

export default Links;
