import React, { useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch, Link } from 'react-router-dom';

import ModuleContainer from './ModuleContainer';
import ModuleLoader from '../../components/Module/ModuleLoader';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPlatformSkeleton, fetchPlatformSkeletonReset } from '../../components/Module/actions';

const RootModule = ({ ...props }) => {
  const { rootid } = useParams();
  const { url } = useRouteMatch();

  useEffect(() => {
    props.fetchPlatformSkeleton(rootid);
    return () => {
      props.fetchPlatformSkeletonReset(rootid);
    };
  }, [rootid]);

  return (
    <>
      <Switch>
        <Route path={`${url}/:id4/:id3/:id2/:id`}>
          <ModuleContainer />
        </Route>
        <Route path={`${url}/:id3/:id2/:id`}>
          <ModuleContainer />
        </Route>
        <Route path={`${url}/:id2/:id`}>
          <ModuleContainer />
        </Route>
        <Route path={`${url}/:id`}>
          <ModuleContainer />
        </Route>
        <Route path={`${url}/`}>
          <ModuleLoader moduleId={rootid} />
        </Route>
      </Switch>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { session: platform } = state;
  return {
    platform,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPlatformSkeleton,
      fetchPlatformSkeletonReset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RootModule);
