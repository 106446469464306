import React, { useState } from 'react';
import { Button, Modal, Loader, Heading } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateModule, updateListModule } from './actions';
import BuildForm from '../BuildForm';

const ModuleEdit = ({ link, module, trigger, item, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateModule(data.item);
    props.updateListModule(data.item);
    closeHandler();
  };

  if (module.links.edit) {
    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Edit [{module.links.edit.label}]
          </Button>
        )}
        <Modal isOpen={modalOpen} onClose={() => closeHandler()} closeButton>
          <Modal.Header title={`Edit ${module.links.edit.label}`} />

          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`${module.links.edit.url}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Update'}
                completeBtnLabel={'Close'}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateModule,
      updateListModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEdit);
