import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ data, url }) => {
  const { platform, fqn, path, fullPath } = data;

  const fqnArray = fqn.split('/');
  var result = fullPath.substring(1, fullPath.length - 1);
  const fullPathArray = result.split('/');

  let currenRoute = '';

  var currentPage = fqnArray[fqnArray.length - 1];

  return (
    <>
      <Link to={'/'}>Home</Link> /{' '}
      {fqnArray.slice(0, -1).map((routeName, i) => {
        currenRoute = currenRoute + `/${fullPathArray[i]}`;
        return (
          <>
            <Link key={i} to={`/${platform}${currenRoute}`}>
              {routeName}
            </Link>{' '}
            /
          </>
        );
      })}
      {currentPage}
    </>
  );
};

export default Breadcrumbs;
