import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchVault = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_VAULT`,
    });

    fetchJson(`${baseURL}/api/vault/view/${moduleId}`)
      .then(data => {
        dispatch(fetchVaultSuccess(data.item));
      })
      .catch(error => {
        dispatch(fetchVaultFailure(error));
      });
  };
};

export const refeshVault = moduleId => {
  return dispatch => {
    dispatch({
      type: `REFRESH_VAULT`,
    });

    fetchJson(`${baseURL}/api/vault/view/${moduleId}`)
      .then(data => {
        dispatch(fetchVaultSuccess(data.item));
      })
      .catch(error => {
        dispatch(fetchVaultFailure(error));
      });
  };
};

export const fetchVaultSuccess = data => {
  return {
    type: `FETCH_VAULT_SUCCESS`,
    data,
  };
};

export const fetchVaultFailure = error => {
  return {
    type: `FETCH_VAULT_FAILURE`,
    error,
  };
};

export const fetchVaultReset = () => {
  return {
    type: `FETCH_VAULT_RESET`,
  };
};

///* REDUCES TO MODULES

export const createVaultItem = (dataItem, moduleId, componentType) => {
  return {
    type: `CREATE_VAULT_ITEM`,
    dataItem,
    moduleId,
    componentType,
  };
};

export const updateVaultItem = (dataItem, moduleId, componentType) => {
  return {
    type: `UPDATE_VAULT_ITEM`,
    dataItem,
    moduleId,
    componentType,
  };
};

export const deleteVaultItem = (itemId, moduleId, componentType) => {
  return {
    type: `DELETE_VAULT_ITEM`,
    moduleId,
    itemId,
    componentType,
  };
};
