import { baseURL } from '../settings';

export function fetchJson(url, options) {
  return fetch(
    url,
    Object.assign(
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        credentials: 'include',
      },
      options,
    ),
  )
    .then(checkStatus)
    .then(response => {
      return response.json();
    });
}

export function checkStatus(response) {
  if (response.status === 302) {
  }
  if (response.status >= 200 && response.status < 400 && response.status !== 302) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function getProfile() {
  return fetchJson(`${baseURL}/api/profile/currentuser`, {
    method: 'GET',
  }).then(data => data.item);
}

export function getProfileForm() {
  return fetchJson(`${baseURL}/api/profile/edit`, {
    method: 'GET',
  });
}

export function updateUser(userData) {
  return fetchJson(`${baseURL}/api/profile/edit`, {
    method: 'PUT',
    body: JSON.stringify(userData),
  });
  //.then(data=>data.item);
}
