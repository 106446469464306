import React, { useState } from 'react';
import styled from 'styled-components';
import DemoBar from './democharts/bar';
import { Heading, Button, Text } from '@ies-ds/react-ui';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Datatable as NewDatatable } from './table';

const CFCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 25px;
  padding: 16px;
  h1 {
    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledDatatable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  td,
  th {
    padding: 16px 8px;
    text-align: left;
  }
  th {
    border-bottom: 1px solid #aaa;
    font-weight: 700;
  }
  td {
    border-bottom: 1px solid #ccc;
  }
`;

const ListMetric = () => {
  return (
    <>
      {/* <br />
      <h3>Cost Overview</h3>

      <KpiCard label={'Max Value'} symbol={data.symbol} data={data.aggregatedValues[0].max_value}></KpiCard>
      <KpiCard label={'Min Value'} symbol={data.symbol} data={data.aggregatedValues[0].min_value}></KpiCard>
      <KpiCard label={'Avg Value'} symbol={data.symbol} data={data.aggregatedValues[0].avg_value}></KpiCard>
      <KpiCard label={'Sum Value'} symbol={data.symbol} data={data.aggregatedValues[0].sum_value}></KpiCard>
      <br />

      {data.groupedValues.map(d => dataArry.push({ name: d.moduleName, cost: d.total }))}

      <h3>Task by Cost</h3>
      <DemoBar data={dataArry} width={800} height={600} />

      <h3>Task by Cost</h3>
      <div>
        <table style={{ background: '#fff', padding: '16px', border: '1px solid #e5e9f1' }}>
          {data.groupedValues.map(d => {
            return (
              <>
                <tr>
                  <td>
                    {d.moduleName} [ {d.moduleId}]
                  </td>
                  <td style={{ width: '680px' }}>
                    <Bar
                      data={d.total}
                      label={`${data.symbol} ${d.total}`}
                      max={data.aggregatedValues[0].max_value}
                    />
                  </td>
                </tr>
              </>
            );
          })}
        </table>
      </div>*/}
    </>
  );
};

const KpiCardStyled = styled.div`
  background: #fff;
  padding: 16px 32px;
  min-width: 200px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px 16px 0;
  border: 1px solid #e5e9f1;
  .value {
    font-weight: 700;
    font-size: 40px;
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;
    position: relative;
    color: #1a2b4b;
  }
  .symbol {
    font-size: 28px;
    margin: 4px;
    color: #999;
    position: absolute;
    left: -23px;
  }
  .label {
    font-size: 16px;
    color: #999;
    margin: 4px 0;
  }
`;

const KpiCard = ({ data, label, symbol }) => {
  return (
    <KpiCardStyled>
      <div className="value">
        <span className="symbol">{symbol}</span>
        {data ? data : '-'}
      </div>
      <div className="label">{label}</div>
    </KpiCardStyled>
  );
};

const Bar = ({ data, label, max }) => {
  const width = (data / max) * 98;

  return (
    <div
      style={{
        marginLeft: '15px',
        width: `${width}%`,
        background: '#3f99e6',
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: '700',
        padding: '4px',
        color: '#fff',
      }}
    >
      {label}
    </div>
  );
};

const DataTable = ({ data }) => {
  return (
    <>
      {' '}
      <Text size={'small'}>Grouped Values</Text>
      <StyledDatatable>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Qty.</th>
          <th>Total</th>
        </tr>
        {data.map(item => {
          return (
            <tr>
              <td>{item.module_id}</td>
              <td>{item.module_name}</td>
              <td>{item.freq}</td>
              <td>{item.total}</td>
            </tr>
          );
        })}
      </StyledDatatable>
    </>
  );
};

const DataTableAll = ({ data }) => {
  return (
    <>
      {' '}
      <Text size={'small'}>All Values</Text>
      <StyledDatatable>
        <tr>
          <th>ID</th>
          <th>Mod</th>
          <th>Item.</th>
          <th>Total</th>
        </tr>
        {data.map(item => {
          return (
            <tr>
              <td>{item.module_id}</td>
              <td>{item.entity_name}</td>
              <td>{item.stringval}</td>
              <td>{item.value_formatted}</td>
            </tr>
          );
        })}
      </StyledDatatable>
    </>
  );
};

const SimpleCard = ({ data, size, label, node }) => {
  return (
    <CFCard size={size}>
      <div>
        <Heading size={1} noMargin>
          {data.aggregatedValues[0][node]}
        </Heading>
      </div>
      <p style={{ margin: '0', fontSize: '14px', fontWeight: '500' }}>{label}</p>
    </CFCard>
  );
};

const ReportMetricTypeNumeric = ({ data, module, item, ...props }) => {
  const [view, setView] = useState(item.description);

  const dataArry = [];

  data.groupedValues.map(d => dataArry.push({ name: d.module_name, cost: parseFloat(d.total) }));

  const viewSwitcher = view => {
    switch (view) {
      case 'avg':
        return <SimpleCard data={data} node={'avg_value_formatted'} label={'Average Value'} />;
      case 'min':
        return <SimpleCard data={data} node={'min_value_formatted'} label={'Min Value'} />;
      case 'max':
        return <SimpleCard data={data} node={'max_value_formatted'} label={'Max Value'} />;
      case 'tot':
        return <SimpleCard data={data} node={'sum_value_formatted'} label={'Total Value'} />;
      case 'table':
        console.log(data);
        return (
          <>
            <DataTable data={data.groupedValues} />
          </>
        );
      case 'all':
        console.log(data);
        return <DataTableAll data={data.values} />;
      case 'bar':
        return (
          <>
            {}
            <DemoBar data={dataArry} width={300} height={300} />
          </>
        );
      case 'datatable':
        return (
          <>
            <NewDatatable data={data.values} />
          </>
        );

      default:
        return <SimpleCard data={data} node={'sum_value_formatted'} label={'Total'} />;
    }
  };

  return (
    <>
      {props.layout == '' ? <>pp</> : <>{viewSwitcher(JSON.parse(props.layout).panelType)}</>}
      {/*viewSwitcher(view)*/}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
    item,
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: vault, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  const { layout } = entities.modules[id].data.report.metrics.find(x => x.id === item.id);

  return {
    layout,
    modules,
    // data,
    selectedSubreddit,
    vault,
    isFetching,
    doesExsist,
    lastUpdated,

    vault: state.entities.vault,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // refeshVault,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportMetricTypeNumeric);
