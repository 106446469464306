import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';

export const fetchModule = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_MODULE`,
    });

    fetchJson(`${baseURL}/api/module/view/${moduleId}`)
      .then(data => {
        dispatch(fetchModuleSuccess(data.item, moduleId));
      })

      .catch(error => {
        dispatch(fetchModuleFailure(error));
      });
  };
};

export const fetchModuleList = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_MODULE_LIST`,
    });

    fetchJson(`${baseURL}/api/module/list/${moduleId}`)
      .then(data => {
        dispatch(fetchModuleListSuccess(data.list, moduleId));
      })

      .catch(data => {
        dispatch(fetchModuleListFailure(data, moduleId));
      });
  };
};

export const fetchModuleSuccess = (data, moduleId) => {
  return {
    type: `FETCH_MODULE_SUCCESS`,
    data,
    moduleId,
  };
};

export const fetchModuleFailure = (data, moduleId) => {
  return {
    type: `FETCH_MODULE_FAILURE`,
    data,
    moduleId,
  };
};

/*
 * UPDATE MODULE
 *
 */

export const updateModule = data => {
  return {
    type: `UPDATE_MODULE`,
    data,
    moduleId: data.id,
  };
};

export const updateListModule = data => {
  return {
    type: `UPDATE_LIST_MODULE`,
    data,
    moduleId: data.id,
  };
};

export const addModule = (data, moduleId) => {
  return {
    type: `ADD_MODULE`,
    data,
    moduleId,
  };
};

export const deleteModule = data => {
  return {
    type: `DELETE_MODULE`,
    data,
    moduleId: data.id,
  };
};

export const deleteListModule = module => {
  return {
    type: `DELETE_LIST_MODULE`,
    module,
  };
};

export const fetchModuleListSuccess = (data, moduleId) => {
  return {
    type: `FETCH_MODULE_LIST_SUCCESS`,
    data,
    moduleId,
  };
};

export const fetchModuleListFailure = error => {
  return {
    type: `FETCH_MODULE_LIST_FAILURE`,
    error,
  };
};

export const buildModulesSuccess = data => {
  return {
    type: `SET_MODULES_SUCCESS`,
    data,
  };
};

export const fetchPlatformSkeleton = moduleId => {
  return dispatch => {
    dispatch({
      type: `FETCH_PLATFORM_SKELETON`,
    });

    fetchJson(`${baseURL}/api/meta/platform-skeleton/${moduleId}`)
      .then(data => {
        dispatch(fetchPlatformSkeletonSuccess(data.list));
        // dispatch(buildModulesSuccess(data.list));
      })

      .catch(error => {
        dispatch(fetchPlatformSkeletonFailure(error));
      });
  };
};

export const fetchPlatformSkeletonSuccess = data => {
  return {
    type: `FETCH_PLATFORM_SKELETON_SUCCESS`,
    data,
  };
};

export const fetchPlatformSkeletonFailure = error => {
  return {
    type: `FETCH_PLATFORM_SKELETON_FAILURE`,
    error,
  };
};

export const fetchPlatformSkeletonReset = () => {
  return {
    type: `FETCH_PLATFORM_SKELETON_RESET`,
  };
};

export const fetchListEntryModules = () => {
  return dispatch => {
    dispatch({
      type: `FETCH_ENTRY_MODULES`,
    });

    fetchJson(`${baseURL}/api/membership/list-entry-modules`)
      .then(data => {
        dispatch(fetchListEntryModulesSuccess(data.list));
        // dispatch(buildModulesSuccess(data.list));
      })

      .catch(error => {
        dispatch(fetchListEntryModulesFailure(error));
      });
  };
};

export const fetchListEntryModulesSuccess = data => {
  return {
    type: `FETCH_ENTRY_MODULES_SUCCESS`,
    data,
  };
};

export const fetchListEntryModulesFailure = error => {
  return {
    type: `FETCH_ENTRY_MODULES_FAILURE`,
    error,
  };
};

export const fetchListEntryModulesReset = () => {
  return {
    type: `FETCH_ENTRY_MODULES_RESET`,
  };
};

export const updateComponent = (data, moduleId, componentType) => {
  return {
    type: `UPDATE_COMPONENT`,
    data,
    moduleId,
    componentType,
  };
};

export const updateSubComponent = (data, moduleId, componentType, subComponentType) => {
  return {
    type: `UPDATE_SUBCOMPONENT`,
    data,
    moduleId,
    componentType,
    subComponentType,
  };
};
