import * as actionTypes from '../../actions/actionTypes';
import update from 'immutability-helper';

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

const initialState = {
  isAuthenticated: false,
  error: null,
  isFetching: true,
  token: null,
};

const authStart = state => {
  return updateObject(state, { isAuthenticated: false, token: null, error: null, isFetching: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    isAuthenticated: true,
    error: null,
    isFetching: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    error: action.error,
    isFetching: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    token: null,
    isFetching: false,
    authRedirectPath: '/login',
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path,
  });
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default authReducer;
