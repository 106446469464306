import React, { useState } from 'react';
import { baseURL } from '../../../../settings';
import styled from 'styled-components';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { insertArticle } from '../../../../components/Article/actions';

import { Text, Button, Loader, Box, Modal, Textfield } from '@ies-ds/react-ui';

const content = {
  entityMap: {},
  blocks: [
    {
      key: '637gr',
      text: 'Initialized from content state.ccc',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
};

const StyledEditorContainer = styled.div`
  background: #fff;
  padding: 8px;
  border: 1px solid rgb(225, 228, 234);
  margin-bottom: 16px;
  .wysiwyg-wrapper {
    min-height: 300px;
  }
  .editor-panel {
    min-height: 280px;
    padding: 0 12px 16px 12px;
    &:hover {
      cursor: text;
    }
  }
`;

const StyledError = styled.span`
  color: red;
  font-size: 12px;
`;

const ArticleAdd = ({ module, trigger, backURL, ...props }) => {
  const [updating, setUpdating] = useState(false);
  const { handleSubmit, register, errors, setError } = useForm();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = values => {
    setUpdating(true);
    const convertedData = convertToRaw(editorState.getCurrentContent());
    const formData = new FormData();
    formData.append('content', JSON.stringify(convertedData));
    formData.append('title', values.title);

    const apiURL = `${baseURL}/api/article/add/${module.id}`;

    const request = new Request(apiURL, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        props.insertArticle(data.item, module.id);
        setIsOpen(false);
        setUpdating(false);
        setSuccess(true);
        setIsOpen(false);
      })
      .catch(function(error) {});
  };

  const onEditorStateChange = state => {
    // const convertedData = convertToRaw(editorState.getCurrentContent());
    ////  console.log(editorState);
    setEditorState(state);
  };

  return (
    <>
      {!isOpen ? (
        trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => setIsOpen(true)}>
            {trigger}
          </span>
        ) : (
          <Button size="small" onClick={() => setIsOpen(true)}>
            {label}
          </Button>
        )
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text>Complete the form below to add new content</Text>
          <div className="field">
            <Textfield
              label="Title"
              name={`title`}
              ref={register({ required: true, maxLength: 100 })}
              errors={errors[`title`]}
            />
            {errors.title && <StyledError>Max 100 characters</StyledError>}
          </div>

          <Text size={'xsmall'}></Text>
          <StyledEditorContainer>
            <Editor
              //editorState={editorState}
              // initialContentState={contentState}
              wrapperClassName={'wysiwyg-wrapper'}
              editorClassName={'editor-panel'}
              toolbarClassName="toolbar-class"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: false },
                link: { inDropdown: true },
                history: { inDropdown: false },
                // image: { uploadCallback: this.uploadImageCallBack, alt: { present: true, mandatory: true } },
              }}
            />
          </StyledEditorContainer>
          {updating && (
            <p>
              <Loader type={'dots'} /> adding{' '}
            </p>
          )}

          <Button color="primary" type="submit" size="small">
            Add
          </Button>

          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            size={'small'}
          >
            Cancel
          </Button>
        </form>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      insertArticle,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAdd);
