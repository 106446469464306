import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../ies-ui/IconButton';
import { Button, Modal, Heading, Loader, Text } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson } from '../../actions/higherorder';

import { deleteTypeMembership } from '../../actions/membership';

import { deleteMembership } from './actions';

const MembershipDelete = ({
  module,
  userId,
  moduleId,
  membershipId,
  moduleType,
  trigger,
  completeAction,
  ...props
}) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}/api/membership/delete/${moduleId}/${membershipId}`, {
      method: 'DELETE',
    })
      .then(data => {
        props.deleteMembership(userId, module.id);
        setModalopen(false);
        setLoading(false);
        () => completeAction;
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => openHandler()}>{trigger}</div>
      ) : (
        <Button size="small" onClick={() => openHandler()}>
          Delete Membership
        </Button>
      )}
      <Modal isOpen={modalOpen}>
        <h2> Delete </h2>
        <Text style={{ color: 'red', fontWeight: '600' }}>PLEASE CAREFUL WHEN DELETING ENTRIES.</Text>
        <Text style={{ color: 'red', fontWeight: '600' }}>SOME ITEMS ARE NEEEDED FOR DEV.</Text>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button
              color="danger"
              size="small"
              onClick={() => {
                handleSubmit();
              }}
            >
              Delete {moduleType}
            </Button>
            <Button
              size="small"
              onClick={() => {
                closeHandler();
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

MembershipDelete.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteMembership,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MembershipDelete);
