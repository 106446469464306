import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Heading, Grid, Icon, Text, Loader, ThemeContainer, BaseTheme, Layout, Box } from '@ies-ds/react-ui';
import AcccountBadge from '../components/AcccountBadge';
import AuthenticatedRoute from './AuthenticatedRoute';
import { Link, Switch, Route } from 'react-router-dom';
import { fetchType } from '../actions/higherorder';
import { baseURL } from '../settings';
import Profile from '../pages/profile';
import Portals from '../pages/portals';
import Logout from '../containers/Auth/Logout';
import { appdata } from '../settings';

import { PageDisclaimer, PageAbout } from '../pages/static';

import { Frame, FrameHeader, FrameNav, FrameContent } from '@ies-ds/react-ui';

const LayoutDefault = props => {
  const { loading, data } = props.portals;

  return (
    <>
      <Frame leadElement="HeaderOnly">
        <FrameHeader>
          <Box justifyContent="space-between" alignItems="center" style={{ height: '56px', padding: '0 16px' }}>
            <div>
              <Heading size="3" noMargin>
                <Link to="/" style={{ color: '#2a585a' }}>
                  <div
                    style={{
                      margin: '0',
                      height: '40px',
                      width: '200px',
                      background: `url(${appdata.logo}) left center / contain no-repeat`,
                    }}
                  ></div>
                </Link>
              </Heading>
            </div>
            <AcccountBadge />
          </Box>
        </FrameHeader>
        <FrameContent>
          <Grid container>
            <Grid item sm={12}>
              <Switch>
                <Route exact path="/disclaimer" component={PageDisclaimer} />
                <Route exact path="/about" component={PageAbout} />
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/">
                  <Portals />
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </FrameContent>
      </Frame>
    </>
  );
};

const mapStateToProps = state => {
  return {
    portals: state.portals,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LayoutDefault));
