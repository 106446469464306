import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Heading, Loader, Text, Avatar, Button, Grid, Modal} from '@ies-ds/react-ui';
import { baseURL } from '../settings';
import {updateUser, getProfileForm} from '../api/user_api';
import {useForm} from 'react-hook-form';

import { Link } from 'react-router-dom';

import styled from 'styled-components';
import BuildForm from "../components/BuildForm";

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  .required {
    color: red;
  }
`;

const StyledErrorLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;


const Profile = props => {
    const {
        loading,
        data,
    } = props.profile;

    const [fetching, setFetching] = useState(true);

    if (loading) return <Loader />;

    const [validationErrorMessage, setValidationErrorMessage] = useState('');

    const [modalOpen, setModalopen] = useState(false);

    const [isSuccessMessage, setIsSuccessMessage] = useState(false);

    const [message, setMessage] = useState(false);

    const { register, handleSubmit, watch, errors } = useForm();

    const [profileForm, setProfileForm] = useState([]);


    const [fileUpload, setFileUpload] = useState(false);

    const [avatar, setAvatar] = useState('');


    useEffect(() => {
       getProfileForm()
            .then((data)=>{
                console.log(data);
                setProfileForm(data.elements);
                if (loading) setFetching(false);

            })
            .catch(function(error) {
                console.log(error);

            });

    }, []);

    const handleFormSubmit = (formData, e) =>{
        setMessage('');
        setValidationErrorMessage('');
        e.preventDefault();

        console.log(formData);
        updateUser(formData)
            .then((data) => {
                setProfileForm(data.elements);
                setIsSuccessMessage(true);
                setMessage('Changes saved!');
                setModalopen(true);

            })
            .catch(error => {
                console.log(error);
                if (error.response.status===400) {
                    error.response.json().then(errorsData => {
                        console.log(errorsData);
                        const errors = errorsData.errors;
                        setValidationErrorMessage(errors);
                       });
                }
                else{
                    setMessage("There has been error saving data in the server. Please try again!")
                }
                setIsSuccessMessage(false);
                setModalopen(true);

            });
    };

    const closeHandler = () => {
       setModalopen(false);
       setIsSuccessMessage(false);
    }



    function renderErrorMessage() {
        if (validationErrorMessage) {
            return Object.entries(validationErrorMessage).map(([key, value], i) => {
                return (
                    <li key={key}>
                        {value}
                    </li>
                )
            })
        }
    }

    const fileUploadHandler = () => {
        setMessage('');
        setIsSuccessMessage(false);
        setFileUpload(true);
    };

    const fileUploadSubmit = (formData) => {
        setAvatar(`${baseURL}${formData.item.url}`);
    };

    const cancelHandler = () => {
        setFileUpload(false);
        setIsSuccessMessage(false);
        setMessage('');
    };



    return (
        <>
            <Modal isOpen={modalOpen}>
                {isSuccessMessage && !fileUpload && <div className="" style={{
                    fontSize: '16px',
                    color: 'blue',
                }}>
                    <Text><Heading size="3"> Success</Heading></Text>
                    <Text><StyledLabel> {message}</StyledLabel></Text>
                </div>}

                {!isSuccessMessage && <div className="alert alert-danger" style={{
                    fontSize: '16px',
                    color: 'red',
                }}>
                    <i className="fa fa-warning"></i><Heading size="3"> Error</Heading>
                    <Text><StyledErrorLabel>{message}</StyledErrorLabel></Text>
                    <ul>
                        {renderErrorMessage()}
                    </ul>
                </div>}


               <Text> <Button onClick={()=>{closeHandler()}} size="small" style={{
                    textAlign: "right"
               }}> OK </Button></Text>

            </Modal>
            <Heading size="2" style={{
                textAlign: "center"
            }}>My Profile [ID: {data.id}]</Heading>
            <Grid container>

                <Grid item md={6}>
                    <form className="form-inline" onSubmit={handleSubmit(handleFormSubmit)} noValidate>

                        {' '}
                        {
                            profileForm.map(fieldData => {
                                if (fieldData.type === 'text') {
                                    return (
                                        <div className="form-group">
                                            <Text>
                                                <div className="col-md-12">
                                                    <label htmlFor={fieldData.id} style={{}}>
                                                        {fieldData.label}
                                                        {fieldData.required ? <span class="required" style={{
                                                            color: 'red'
                                                        }}>*</span> : null}
                                                    </label>
                                                    <br/>
                                                    <input
                                                        type={fieldData.type}
                                                        id={fieldData.id}
                                                        name={fieldData.name}
                                                        defaultValue={fieldData.value}
                                                        disabled={fieldData.disabled}
                                                        ref={register({required:fieldData.required})}
                                                        style={{
                                                            padding: '8px',
                                                            border: '1px solid #ccc',
                                                            fontSize: '16px',
                                                            backgroundColor: '#fff',
                                                            width: '100%',
                                                            boxSizing: 'border-box',
                                                            maxWidth: '500px',
                                                        }}
                                                    />
                                                    {fieldData.help ? (<Text size="xsmall">{fieldData.help}</Text>) : null}
                                                    {errors[fieldData.name] && (<p><StyledErrorLabel>{fieldData.label} is required</StyledErrorLabel></p>)}

                                                </div>
                                            </Text>
                                        </div>
                                    )
                                }
                                else if(fieldData.type === 'select'){
                                    return (
                                        <div className="form-group">
                                            <Text>
                                                <div className="col-md-12">
                                                    <label className={fieldData.name} htmlFor={fieldData.name}>
                                                        {fieldData.label}

                                                    </label>
                                                    <select
                                                        id={fieldData.name}
                                                        name={fieldData.name}
                                                        ref={register}
                                                        className="form-control"
                                                        style={{ width: '70%', padding: '5px 5px', fontSize: '16x' }}
                                                    >

                                                        <option value='' disabled>{fieldData.placeholder}</option>
                                                        {fieldData.options.map(d => {
                                                            return (
                                                                <option value={d.value} selected={d.selected}>
                                                                    {d.label}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </Text>
                                        </div>
                                    )
                                }
                            })}
                        <br/>
                        <Text>
                            <Button size="medium">Update Profile</Button>
                        </Text>

                    </form>
                </Grid>

                <Grid item md={3}>
                    <Text>
                        <Avatar src={`${baseURL}${data.avatar}`} size="xxlarge"/>
                    </Text>
                    <Text>
                        <Button size="xsmall" onClick={() => fileUploadHandler()} style={{marginLeft: '0rem'}}>
                            UPLOAD FILE
                        </Button>
                    </Text>

                    <Modal isOpen={fileUpload}>
                        <Heading size={3}> File Upload</Heading>
                        <BuildForm apiURL={'/api/avatar/edit'} onSuccess={fileUploadSubmit} />
                        <Text>
                        <Button size="small" onClick={() => cancelHandler()}>
                            Cancel
                        </Button>
                        </Text>
                    </Modal>

                </Grid>
            </Grid>
        </>
    )




};
const mapStateToProps = state => {
    return {
        profile: state.session.profile,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            /*fetchType,*/

        },
        dispatch,
    );


export default connect(mapStateToProps, mapDispatchToProps)(Profile);

