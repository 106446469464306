import React from 'react';
import { Text, Box, Loader, Icon, Button } from '@ies-ds/react-ui';
import prettyBytes from 'pretty-bytes';

import styled from 'styled-components';

import { ReferenceAdd, ReferenceDelete } from '../../../components/Vault/Reference';

import { getFileURL } from '../../../utils';
import { baseURL } from '../../../settings';

const GetLink = ({ file }) => {
  return (
    <a href={getFileURL(`${baseURL}${file.links.download.url}`)} download={file.filename}>
      <Text as="span" size="xsmall" weight="medium" color="dark">
        {file.title}
      </Text>
    </a>
  );
};

const StyledFile = styled(Box)`
  background: #fff;
  display: inline-flex;
  margin: 0 4px 4px 0;
  padding: 8px 9px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  &:hover {
    background: #f9fbfd;
    border: 1px solid #b9cdf9;
  }
`;

const Link = ({ link, module }) => {
  return (
    <>
      <Box gap="8" alignItems="center">
        {link.resourceType == 'VIDEO' ? (
          <Icon icon="video" size="xsmall" />
        ) : (
          <>
            <Icon icon="link" size="xsmall" />
          </>
        )}

        <a href={link.url} target="_blank" title={link.description}>
          <Text as="span" size="small" color="blue">
            {link.title}
          </Text>
        </a>
      </Box>
    </>
  );
};

const LinkList = ({ links, module }) => {
  return (
    <>
      {links.length > 0 && (
        <div>
          <br />
          <Text as="div" size="small">
            Links
          </Text>
          {links.map(link => {
            return <Link link={link} module={module} />;
          })}
        </div>
      )}
    </>
  );
};

export default LinkList;
