import React, { useState } from 'react';
import { Button, Modal, Loader } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BuildForm from '../BuildForm';
import { updateComponent } from '../Module/actions';
import { types } from '@babel/core';

const DeliverableEdit = ({ module, trigger, type, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [apiType, setApiType] = useState('edit');

  const { deliverable } = module;

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    props.updateComponent(data, module.id, 'deliverable');
    setLoading(false);
    setModalopen(false);
    console.log(data);
  };

  const getType = data => {
    let apiType = '';

    switch (data) {
      case 'startDate':
        apiType = 'edit-start-date';
        break;

      case 'deadline':
        apiType = 'edit-deadline';
        break;
      case 'commencedDate':
        apiType = 'edit-commence-date';
        break;
      case 'completionDate':
        apiType = 'edit-completion-date';
        break;
      default:
        apiType = 'edit';
    }

    return apiType;
  };

  if (deliverable.links.edit) {
    return (
      <>
        {trigger ? (
          <div onClick={() => openHandler()}>{trigger}</div>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Edit
          </Button>
        )}
        <Modal isOpen={modalOpen}>
          <h2> {deliverable.links.edit.label}</h2>
          <p>Complete the form below to edit the deliverable. </p>
          {error}
          {loading ? (
            <Loader />
          ) : (
            <>
              <BuildForm
                apiURL={`/api/deliverable/${getType(type)}/${module.id}/${module.deliverable.id}`}
                onSuccess={onSuccess}
                completeAction={() => closeHandler()}
                submitBtnLabel={'Update'}
                completeBtnLabel={'Close'}
              />
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateComponent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableEdit);
