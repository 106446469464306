import React, { useState, useEffect } from 'react';
import { Button, Modal, Loader, Text, Box, Heading } from '@ies-ds/react-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { addModule } from './actions';
import BuildForm from '../../BuildForm';
import { baseURL } from '../../../settings';
import { fetchType, fetchJson, fetchTypeSuccess } from '../../../actions/higherorder';
import { useForm } from 'react-hook-form';

const AddForms = ({ module, item }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(`/api/clone-config/manage/${module.id}/${item.id}`);
  }, []);

  const fetchData = apiURL => {
    fetchJson(`${baseURL}${apiURL}`, {
      method: 'GET',
    })
      .then(data => {
        setLoading(false);
        setData(data.forms);
      })
      .catch(function(error) {});
  };

  const getData = () => {
    fetchData(`/api/clone-config/manage/${module.id}/${item.id}`);
  };

  return (
    <div style={{ position: 'relative', minHeight: '100px' }}>
      {loading && (
        <Box
          style={{ margin: '0 0 8px 0' }}
          gap={4}
          alignItems="center"
          justifyContent="center"
          style={{ position: 'absolute', height: '100%', width: '100%', background: 'rgba(255,255,255,0.8)' }}
        >
          <Loader /> <Text weight="bold">Calculating dependencies....</Text>
        </Box>
      )}
      <div
        style={{
          maxHeight: '400px',
          overflowY: 'auto',
          padding: '8px',
          border: '1px solid #cfe1e2',
          background: '#f6f6f7',
        }}
      >
        {data.map(items => {
          const data = JSON.parse(items);
          console.log(data);

          if (data == null) {
            return;
          }
          //return <CheckboxForm data={data} onSuccess={data => getData()} onCallback={() => setLoading(true)} />;
          return data.elements[1].value != '' ? (
            <CheckboxForm module={module} data={data} onSuccess={() => getData()} onCallback={() => setLoading(true)} />
          ) : null;
        })}
      </div>
    </div>
  );
};

const CheckboxForm = ({ data, onSuccess, onCallback, module }) => {
  const { handleSubmit, register, errors, setError } = useForm();
  const [formdata, setFormdata] = useState([]);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updating, setUpdating] = useState(false);

  const [checked, setChecked] = useState(data.elements[3].checked);

  const toggleCheckbox = () => {
    //checked ? setChecked(false) : setChecked(true);
  };

  const onSubmit = values => {
    onCallback();
    setUpdating(true);
    setSuccess(false);
    setErrorMessage('');

    // toggleCheckbox();

    console.log('checked: ' + checked);

    console.log(values);

    const formData = new FormData();

    data.elements[3].checked ? null : formData.append('include', '1');

    formData.append('entityId', values['entityId']);
    formData.append('entityType', values['entityType']);
    formData.append('id', values['id']);
    formData.append('_token', values['_token']);
    formData.append('_method', 'post');

    const request = new Request(`${baseURL}${data.action}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      // .then(checkStatus)
      .then(r => r.json())
      .then(data => {
        /* setUpdating(false);
        setLoading(false);
        setSuccess(true);*/
        onSuccess(data);
      })
      .catch(function(error) {});
  };

  return (
    <form>
      {data.elements.map(item => {
        if (item.type == 'hidden') {
          return <input type="hidden" name={item.name} value={item.value} ref={register} />;
        } else if (item.type == 'checkbox') {
          let count = (item.label.match(/\//g).length - 1) * 24;
          return (
            <>
              <Box
                gap={4}
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ margin: '4px 0', paddingLeft: `${count}px` }}
              >
                <input
                  id={item.label}
                  type="checkbox"
                  checked={item.checked}
                  name={item.name}
                  value={checked ? 0 : 1}
                  onClick={handleSubmit(onSubmit)}
                  //ref={register}
                  style={{ marginTop: '4px' }}
                />
                <Text size="small" as="div">
                  <label for={item.label}>
                    {' '}
                    {item.label.substring(item.label.lastIndexOf('/') + 1).replace(`${module.name}:`, '')}{' '}
                  </label>
                </Text>
              </Box>
            </>
          );
        } else {
          return null;
        }
      })}
    </form>
  );
};

const CloneConfigManage = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState([{}]);

  const openHandler = () => {
    setError('');
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  const onSuccess = data => {
    // props.addModule(data.item, module.id);
    setForms(data);
    setLoading(false);
    setModalopen(false);
  };

  if (true /*module.links[type]*/) {
    //const { route, url, label, access } = module.links;

    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <Button size="small" onClick={() => openHandler()}>
            Add Clone
          </Button>
        )}
        <Modal isOpen={modalOpen} size="xlarge" onClose={() => closeHandler()} closeButton>
          <Modal.Header title="Configure Template Options" />

          <Modal.Content>
            <Text>
              Add/Remove item(s) to be included in this template. Related dependencies will be added/removed as
              required.
            </Text>
            <Text as="div" size="small">
              Module:
            </Text>

            <Text as="p" size="large" weight="bold">
              {module.name}
            </Text>

            {error}
            {loading ? <Loader /> : <AddForms module={module} item={item} {...props} />}
          </Modal.Content>
          <Modal.Footer>
            <Button color="primary" size="small" onClick={() => closeHandler()}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //  addModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CloneConfigManage);
