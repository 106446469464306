import React from 'react';
import ModuleEdit from './ModuleEdit';
import ModuleDelete from './ModuleDelete';
import IconButton from '../../ies-ui/IconButton';

import { Button } from '@ies-ds/react-ui';

const ModuleActionLinks = ({ item, url, moduleType }) => {
  const { links } = item;

  if (item.links.view) {
    return (
      <>
        <div>{links.view ? null : null}</div>
        <div style={{ margin: 0 }}>
          {links.edit ? (
            <ModuleEdit
              module={item}
              moduleType={moduleType}
              trigger={<IconButton size="16" icon="edit" title={`Edit`} />}
            />
          ) : null}
        </div>

        <div style={{ margin: 0 }}>
          {links.delete ? (
            <ModuleDelete
              module={item}
              moduleType={moduleType}
              trigger={<IconButton size="16" icon="bin" title={`Delete`} />}
            />
          ) : null}
        </div>
      </>
    );
  } else {
    return (
      <>
        {' '}
        NEEDS UPDATED: <ModuleEdit module={item} moduleType={moduleType} trigger={'[EDIT]'} />
        {links.map(d => {
          switch (d.label) {
            case 'View':
              return <div>{links.view ? '[View]' : null}</div>;
              break;
            case 'Edit':
              return (
                <div>{links.edit ? <ModuleEdit module={item} moduleType={moduleType} trigger={'[EDIT]'} /> : null}</div>
              );
              break;
            case 'Delete':
              return <div>{links.delete ? '[Delete].' : null}</div>;
              break;
            default:
              null;
          }
        })}
      </>
    );
  }
};

export default ModuleActionLinks;
