import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Heading, Text, Loader } from '@ies-ds/react-ui';

import IconButton from '../../../ies-ui/IconButton';
import moment from 'moment';

import { FileEdit, FileUpload, FileDelete, FileLock } from './';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refeshVault } from '../actions';

import { checkNodeExists, childCount, getFileURL } from '../../../utils';
import StyledTable from '../../../ies-ui/StyledTable';
import { baseURL } from '../../../settings';

function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
}

const GetLink = ({ src, item }) => {
  return (
    <a href={getFileURL(src)} download={item.filename}>
      <IconButton size="16" icon="file" title="Download" />
    </a>
  );
};

const FileList = ({ module, moduleType, ...props }) => {
  return (
    <>
      {module.vault.links.add_file ? (
        <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
          <FileUpload module={module} moduleType={moduleType} />
        </Box>
      ) : null}

      {checkNodeExists(props.data.vault) ? (
        <>
          <StyledTable>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>File</th>
              <th>Size</th>
              {/*<th>File Type</th>*/}
              <th>Date Added</th>
              <th>Created By</th>
              <th></th>
            </tr>
            {props.data.vault.files.length <= 0 ? (
              <tr>
                <td colspan="5">No files Yet </td>
              </tr>
            ) : (
              props.data.vault.files.map(item => {
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{item.filename}</td>
                    <td>{bytesToSize(item.filesize)}</td>
                    {/*<td>{item.mimetype}</td>*/}
                    <td>{moment(item.created).format('Do MMM YYYY')}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Box justifyContent="flex-end">
                        {item.links.download ? (
                          <GetLink src={`${baseURL}${item.links.download.url}`} item={item} />
                        ) : null}
                        <FileEdit
                          module={module}
                          item={item}
                          trigger={
                            <IconButton size="16" icon="pencil" title={item.locked ? 'Fie is locked' : 'Edit'} />
                          }
                        />
                        {item.links.delete ? (
                          <FileDelete
                            module={module}
                            item={item}
                            trigger={
                              <IconButton size="16" icon="bin" title={item.locked ? 'Fie is locked' : 'Delete'} />
                            }
                          />
                        ) : null}
                        {item.links.edit && <FileLock module={module} moduleType={moduleType} item={item} />}
                      </Box>
                    </td>
                  </tr>
                );
              })
            )}
          </StyledTable>
        </>
      ) : (
        'no references'
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: vault, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,
    selectedSubreddit,
    vault,
    isFetching,
    doesExsist,
    lastUpdated,

    vault: state.entities.vault,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refeshVault,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
