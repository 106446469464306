import React, { useContext, useState, useEffect } from 'react';
import LoadingOverlay from './components/LoadingOverlay';
import LandingPage from './routes/LandingPage';
import { connect } from 'react-redux';
import * as actions from './actions/auth';
import Login from './containers/Auth/Auth';
import LoginPage from './routes/LoginPage';

import {appdata} from './settings'

const App = props => {
  useEffect(() => {
    props.onTryAutoSignup();
    document.title = appdata.title
  }, []);

  if (props.loading) {
    return <LoadingOverlay />;
  }

  if (props.isAuthenticated) {
    return <LandingPage />;
  } else {
    return <LoginPage {...props} />;
  }
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.session.auth.token !== null,
    loading: state.session.auth.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
