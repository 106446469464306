const INITIAL_STATE = {
  data: [],
  error: null,
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  let error;

  switch (action.type) {
    case 'FETCH_USER_REQUESTED':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    case 'FETCH_USER_SUCCESS':
      return {
        ...state,

        data: action.data,
        error: null,
        loading: false,
      };

    case 'FETCH_USER_FAILURE':
      // error = action.err || '{message: action.data.message}';
      // error = action.error || action.error;

      error = action.error.response || { message: action.error.message };

      return {
        ...state,

        data: [],
        error,
        loading: false,
      };

    case 'FETCH_USER_RESET':
      return {
        ...state,

        data: [],
        error: null,
        loading: true,
      };

    default:
      return state;
  }
};
