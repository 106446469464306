import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Loader, Heading, Text, Button } from '@ies-ds/react-ui';
import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';
import _ from 'lodash';

import IconButton from '../../../ies-ui/IconButton';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { refeshVault } from '../actions';

import { CloneConfigAdd, CloneConfigManage } from './';

import { checkNodeExists, childCount } from '../../../utils';

import StyledTable from '../../../ies-ui/StyledTable';

const CloneList = ({ module, ...props }) => {
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchList(`${baseURL}/api/clone-config/list/${module.id}`);

    //_.isUndefined(props.data.cloneList) ? fetchList(`${baseURL}/api/clone-config/list/${module.id}`) : null;

    setLoading(true);
    setSuccess(false);
    setErrorMessage('');
  }, []);

  const pushItem = item => {
    setListdata(listdata => [...listdata, item]);
  };

  const fetchList = apiURL => {
    fetchJson(apiURL, {
      method: 'GET',
    })
      .then(data => {
        setListdata(data.list);
        setLoading(false);
        console.log(listdata);
      })
      .catch(function(error) {
        setSuccess(false);
        setErrorMessage('Error: ' + error);
        console.log(error);
      });
  };

  return (
    <>
      {module.links.edit ? (
        <Box justifyContent={'space-between'} style={{ marginBottom: '4px' }}>
          <Heading size={'3'} noMargin>
            Templates
          </Heading>
          <CloneConfigAdd
            module={module}
            trigger={<Button size={'small'}> Add New Template</Button>}
            onComplete={data => pushItem(data)}
          />
        </Box>
      ) : null}

      {!loading ? (
        <>
          <StyledTable>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Module Id</th>
              <th>Created By</th>
              <th></th>
            </tr>
            {listdata.length <= 0 ? (
              <tr>
                <td colspan="5">No Templates </td>
              </tr>
            ) : (
              listdata.map(item => {
                return (
                  <tr>
                    <td style={{ maxWidth: '600px' }}>{item.name}</td>

                    <td>{item.description}</td>
                    <td>{item.module}</td>
                    <td>{item.createdBy}</td>
                    <td>
                      <Box justifyContent="flex-end">
                        <CloneConfigManage
                          module={module}
                          item={item}
                          trigger={<IconButton size="16" icon="pen" title="Configuration" />}
                        />
                      </Box>
                    </td>
                  </tr>
                );
              })
            )}
          </StyledTable>
        </>
      ) : (
        <Loader type="dots" />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: vault, doesExsist } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    modules,
    data,
    selectedSubreddit,
    vault,
    isFetching,
    doesExsist,
    lastUpdated,

    vault: state.entities.vault,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // refeshVault,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CloneList);
