import React, { useEffect } from 'react';
import { useParams, useRouteMatch, Link, NavLink } from 'react-router-dom';
import ModuleLoader from '../../components/Module/ModuleLoader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchModule } from '../../components/Module/actions';

import { Button } from '@ies-ds/react-ui';

import Step from './Step';

const ProjectMast = ({ ...props }) => {
  const { portalId } = useParams();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    props.fetchModule(portalId);
    return () => {
      {
        /*clean*/
      }
    };
  }, [portalId]);

  return (
    <>
      {props.isFetching ? (
        '...'
      ) : (
        <Button appearance="ghost" icon="home" size="large" as={Link} to={`/${props.data.platform}/${props.data.id}`}>
          {props.data.name}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { portalId },
    },
  },
) => {
  const { entities } = state;
  const { isFetching, data } = entities.modules[portalId] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    isFetching,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchModule,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMast);
