import React from 'react';
import { Heading, Button, Box } from '@ies-ds/react-ui';
import { ModuleEdit, ModuleDelete } from '../';
import { NavLink, useParams, Route, Switch, useRouteMatch, Link, Redirect } from 'react-router-dom';

const Title = ({ module }) => {
  let { path, url } = useRouteMatch();

  return (
    <Box gap={8}>
      <Heading appearance="h3" noMargin style={{ margin: '0' }}>
        {module.name}
      </Heading>
      <ModuleEdit module={module} trigger={<Button size={'xsmall'}>Edit</Button>} />
      <ModuleDelete module={module} trigger={<Button size={'xsmall'}>Delete</Button>} />
      {module.links.edit && (
        <Button as={Link} to={`${url}/clone`} size={'xsmall'}>
          Copy
        </Button>
      )}
    </Box>
  );
};

export default Title;
