import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Loader, Text } from '@ies-ds/react-ui';
import { baseURL } from '../../settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchJson } from '../../actions/higherorder';
import { deleteCustomField } from './actions';
import { fetchModule } from '../Module/actions';
import { checkNodeExists } from '../../utils';

import styled from 'styled-components';

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const DeleteCustomField = ({ module, item, trigger, ...props }) => {
  const [modalOpen, setModalopen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);

    fetchJson(`${baseURL}${item.links.delete.url}`)
      .then(data => {
        closeHandler();
        props.deleteCustomField(item, module.id);
        props.fetchModule(module.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const openHandler = () => {
    setLoading(false);
    setModalopen(true);
  };

  const closeHandler = () => {
    setLoading(false);
    setModalopen(false);
  };

  if (checkNodeExists(item.links.delete) && typeof item.links != 'undefined') {
    return (
      <>
        {trigger ? (
          <span style={{ display: 'inline-block', margin: '0' }} onClick={() => openHandler()}>
            {trigger}
          </span>
        ) : (
          <StyledLink size="small" onClick={() => openHandler()}>
            {item.links.delete.label}
          </StyledLink>
        )}
        <Modal isOpen={modalOpen}>
          <h2>[{item.links.delete.label}]</h2>
          <Text style={{ color: 'red', fontWeight: '600' }}>PLEASE CAREFUL WHEN DELETING ENTRIES.</Text>
          <Text style={{ color: 'red', fontWeight: '600' }}>ARE YOU SURE YOU WANT TO DELETE THIS.</Text>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Button
                color="danger"
                size="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {item.links.delete.label}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  closeHandler();
                }}
              >
                No, Don't Delete
              </Button>
            </>
          )}
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => bindActionCreators({ deleteCustomField, fetchModule }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCustomField);
