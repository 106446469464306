import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../settings';
import { fetchJson } from '../../../actions/higherorder';
import { Loader, Text, Heading } from '@ies-ds/react-ui';
import StatusBadge from '../../StatusBadge';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchModule } from '../actions';
import Status from './status';
import _ from 'lodash';

import { ModuleAdd, ModuleEdit, ModuleDelete } from '../';
import styled from 'styled-components';
import IconButton from '../../../ies-ui/IconButton';

import { updateCustomField, createCustomField } from '../../CustomField/actions';

const StyledHeading = styled.h4`
  margin: 0;
  ${props => (props.isCompleted ? `text-decoration: line-through;` : null)}
`;

const StyledCheckItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  display: flex;
  padding: 16px 8px;
  border-bottom: 1px solid #e1e4ea;
`;

const ConnectChecklist = ({ module, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState();

  useEffect(() => {}, []);

  const actionLinks = data => {
    {
      return typeof data.links == 'undefined'
        ? null
        : Object.keys(data.links).map(function(key) {
            switch (data.links[key].route) {
              case 'api_module_edit':
                return <ModuleEdit module={data} trigger={<IconButton size="16" icon="edit" title={'Edit'} />} />;
              case 'api_module_delete':
                return <ModuleDelete module={data} trigger={<IconButton size="16" icon="bin" title={'Delete'} />} />;
            }
          });
    }
  };

  const isCompleted = data => {
    if (data.customFields.checklist_status.values.length == 0) {
      return false;
    } else {
      return data.customFields.checklist_status.values[0].values.stringval == 'completed' ? true : false;
    }
  };

  const Checkbox = ({ data }) => {
    setChecked(isCompleted(data));

    return loading ? <Loader size={'xsmall'} /> : <input type="checkbox" checked={checked} onClick={() => getForm()} />;
  };

  const getForm = () => {
    setLoading(true);

    const apiURL =
      props.data.customFields.checklist_status.links.length != 0
        ? props.data.customFields.checklist_status.links.add_checklist_status_value.url
        : props.data.customFields.checklist_status.values[0].links.edit.url;

    const request = new Request(`${baseURL}${apiURL}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        updateStatus(data);
        console.log(data);
      })
      .catch(function(error) {});
  };

  const updateStatus = data => {
    setLoading(true);

    const formData = new FormData();
    formData.append('_method', data.elements[0].value);
    formData.append(
      'option',
      data.elements[1].options[0].selected ? data.elements[1].options[1].value : data.elements[1].options[0].value,
    );
    formData.append('_token', data.elements[2].value);

    const request = new Request(`${baseURL}${data.action}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      .then(r => r.json())
      .then(data => {
        //  props.createCustomField(data.item, module.id);
        props.updateCustomField(data.item);
        setLoading(false);
      })
      .catch(function(error) {});
  };

  const toggleCheckitem = () => {
    // TODO: Build complete checking solution
  };

  return props.isFetching ? (
    <StyledCheckItem>
      <Loader type="dots" size="small" />{' '}
    </StyledCheckItem>
  ) : _.isUndefined(props.data.customFields.checklist_status) ? (
    <StyledCheckItem>
      <div style={{ flex: 1 }}>
        <StyledHeading size={4} noMargin>
          {props.data.name} [{props.data.id}]
        </StyledHeading>
        <Text size={'small'} style={{ margin: '4px 0 0 0' }}>
          {props.data.description}
        </Text>
      </div>

      <div style={{ width: '220px', textAlign: 'right' }}>{actionLinks(props.data)}</div>
    </StyledCheckItem>
  ) : (
    <StyledCheckItem isCompleted={isCompleted(props.data)}>
      <div style={{ width: '50px', display: 'flex', justifyContent: 'center', padding: '4px' }}>
        <Checkbox data={props.data} />
      </div>

      <div style={{ flex: 1 }} onClick={() => toggleCheckitem()}>
        <StyledHeading size={4} noMargin isCompleted={isCompleted(props.data)}>
          {props.data.name}
        </StyledHeading>
        <Text size={'small'} style={{ margin: '4px 0 0 0' }}>
          {props.data.description}
        </Text>
      </div>

      <div style={{ width: '220px', textAlign: 'right' }}>{actionLinks(props.data)}</div>
    </StyledCheckItem>
  );
};

const mapStateToProps = (state, myProps) => {
  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, lastUpdated, data, data: children, doesExsist } = entities.modules[myProps.module.id] || {
    isFetching: true,
    items: [],
  };

  return {
    data,
    children,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ updateCustomField, createCustomField }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectChecklist);
