import React, { useState } from 'react';
import { baseURL } from '../../settings';
import { fetchJson } from '../../actions/higherorder';
import { NavLink, Link, useParams, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { insertArticle } from './actions';

import { Heading, Text, Grid, Button, Loader, Box } from '@ies-ds/react-ui';

const StyledEditorContainer = styled.div`
  .wysiwyg-wrapper {
    min-height: 300px;
  }
  .editor-panel {
    min-height: 280px;
    padding: 0 12px 16px 12px;
    &:hover {
      cursor: text;
    }
  }
`;

const StyledInput = styled.input`
  padding: 8px;

  font-size: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  ${props => {
    if (props.errors) {
      return `border: 1px solid red; &:focus {outline:0;} `;
    } else {
      return `border: 1px solid #ccc;`;
    }
  }}
  .error {
    color: red;
    font-size: 12px;
  }
`;

const StyledError = styled.span`
  color: red;
  font-size: 12px;
`;

const ArticleAdd = ({ module, backURL, ...props }) => {
  const { articleId } = useParams();
  let { path, url } = useRouteMatch();
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const { handleSubmit, register, errors, setError } = useForm();

  //const [data, setData] = useState({});

  const [editorState, setEditorState] = useState(null);
  const history = useHistory();

  const onSubmit = values => {
    //alert('submit');
    setUpdating(true);
    setSuccess(false);
    setLoading(true);
    /*setErrorMessage('');*/

    console.log('values');
    // console.log(values['title']);

    const convertedData = convertToRaw(editorState.getCurrentContent());

    const formData = new FormData();
    formData.append('content', JSON.stringify(convertedData));
    formData.append('title', values.title);

    articleId ? formData.append('parentArticleId', articleId) : null;
    /*if (typeof parentArticleId != 'undefined') {
      formData.append('parentArticleId', parentArticleId);
    }*/

    const apiURL = `${baseURL}/api/article/add/${module.id}`;

    const request = new Request(apiURL, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      credentials: 'include',
    });

    fetch(request)
      // .then(checkStatus)
      .then(r => r.json())
      .then(data => {
        props.insertArticle(data.item, module.id);
        setUpdating(false);
        setLoading(false);
        setSuccess(true);
        history.push(backURL);
      })
      .catch(function(error) {});
  };

  const onEditorStateChange = editorState => {
    const convertedData = convertToRaw(editorState.getCurrentContent());
    console.log(JSON.stringify(convertedData));
    setEditorState(editorState);
  };

  return (
    <>
      <div style={{ position: 'fixed', background: 'rgba(0,0,0,0.7)', top: 0, left: 0, right: 0, bottom: 0 }}>
        <div
          style={{
            position: 'relative',
            background: '#f5f5f5',
            top: '0px',
            maxWidth: '1200px',
            bottom: '0px',
            overflow: 'auto',
            padding: '32px 32px 64px 32px',
            margin: '16px auto',
            height: '100%',
          }}
        >
          <Box justifyContent={'flex-end'}>
            <Button size={'small'} as={Link} to={backURL}>
              Close
            </Button>
          </Box>
          <Grid>
            <div item style={{ alignItems: 'center' }}>
              {loading ? (
                <>
                  <Loader type="dots" />
                  Adding...
                </>
              ) : null}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ margin: '16px 0' }}>
                  <Heading size="3">Add new Knowledge content</Heading>
                  <Text>Complete the form below to add new content to the Knowledge</Text>
                </div>

                <div className="field">
                  <Text size={'xsmall'}>TITLE</Text>
                  <StyledInput
                    name={`title`}
                    ref={register({ required: true, maxLength: 100 })}
                    defaultValue={data.title}
                    errors={errors[`title`]}
                  />
                  {errors.title && <StyledError>Max 100 characters</StyledError>}
                </div>

                <Text size={'xsmall'}>CONTENT</Text>
                <StyledEditorContainer
                  style={{ background: '#fff', padding: '8px', border: '1px solid rgb(225, 228, 234)' }}
                >
                  <Editor
                    style={{ background: '#ccc', padding: '8px', border: '1px solid rgb(225, 228, 234)' }}
                    editorState={editorState}
                    // initialContentState={contentState}
                    wrapperClassName={'wysiwyg-wrapper'}
                    editorClassName={'editor-panel'}
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                    //onContentStateChange={onContentStateChange}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: false },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                      // image: { uploadCallback: this.uploadImageCallBack, alt: { present: true, mandatory: true } },
                    }}
                  />
                </StyledEditorContainer>
                {updating ? (
                  <p>
                    <Loader type={'dots'} /> adding{' '}
                  </p>
                ) : null}
                {success ? <p>ADDED</p> : null}
                <p>
                  <Button color="primary" type="submit" size="small">
                    Add Content
                  </Button>

                  <Link to={`${backURL}`}>
                    <Button size={'small'}>Cancel</Button>
                  </Link>
                </p>
              </form>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      insertArticle,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAdd);
