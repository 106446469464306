import React, { useEffect, useState, useRef } from 'react';
import { baseURL } from '../../settings';
import { Loader, Button, Box, Avatar, Buttongroup } from '@ies-ds/react-ui';
import StatusBadge from '../StatusBadge';
import { checkStatus, fetchJson } from '../../actions/higherorder';
import { ModuleAdd, ModuleEdit, ModuleDelete } from './';
import { Link, useParams, useRouteMatch, Switch, Route } from 'react-router-dom';
import { getImageURL } from '../../utils';

import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  CustomMarker,
  TodayMarker,
  CursorMarker,
} from 'react-calendar-timeline/lib';
//import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { refeshVault } from '../actions';

import styled from 'styled-components';

const StyledTimeline = styled.div`
  .react-calendar-timeline {
    background: #fff;
  }
  .react-calendar-timeline * {
    box-sizing: border-box;
  }

  .react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .react-calendar-timeline .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }

  .react-calendar-timeline .rct-item:hover {
    z-index: 88;
    z-index: 0;
  }

  .react-calendar-timeline .rct-item .rct-item-content {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    overflow: hidden;
    display: inline-block;
    border-radius: 2px;
    padding: 0 6px;
    height: 100%;
  }

  .react-calendar-timeline .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: 1px solid #bbb;
  }
  .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
    border-right: 0;
    border-left: 1px solid #bbb;
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin: 0;
    border-bottom: 1px solid #bbb;
    font-weight: 600;
    font-size: 15px;
    padding: 0 12px;
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: rgba(0, 0, 0, 0.03);
  }
  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: transparent;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 1px solid #bbb;
    z-index: 30;
    z-index: 0;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 2px;
  }
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(250, 246, 225, 0.2);
  }

  .react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid #d6d6d6;
    box-sizing: border-box;
    z-index: 40;
    z-index: 0;
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: rgba(0, 0, 0, 0.05);
  }
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: transparent;
  }

  .react-calendar-timeline .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #2196f3;
    z-index: 51;
    z-index: 0;
  }

  .react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #d8d5d5;
    cursor: pointer;
    font-size: 14px;
    background-color: #fff;
    border-left: 1px solid #d8d5d5;
  }

  .react-calendar-timeline .rct-dateHeader-primary {
    background-color: initial;
    border-left: 1px solid #d8d5d5;
    border-right: 1px solid #d8d5d5;
    color: #fff;
    background: #5b6a84;
    font-weight: 700;
  }

  .react-calendar-timeline .rct-header-root {
    background: #c0c4ce;
    border-bottom: 1px solid #d8d5d5;
  }

  .react-calendar-timeline .rct-calendar-header {
    border: 1px solid #d6d6d6;
  }

  .rct-item {
    z-index: 0 !important;
    height: 25px !important;
    font-size: 14px !important;
    padding: 1px 5px !important;
    margin-top: 3px !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-color: #ccc !important;
  }
`;

const dataz2 = [
  {
    deliverable_id: 484,
    module_id: 895,
    module_name: 'BREXIT MAN',
    status: 'NOT_STARTED',
    status_label: 'Not Started',
    reference_date: '2020-05-12',
    start_date: moment('2020-04-18'),
    deadline: moment('2020-04-26'),
    commenced_date: '',
  },
  {
    deliverable_id: 303,
    module_id: 539,
    module_name: 's dsad',
    status: 'IN_PROGRESS',
    status_label: 'In Progress',
    reference_date: '2020-05-12',
    start_date: moment('2018-10-10'),
    deadline: moment('2018-10-16'),
    commenced_date: '2018-10-10',
    completion_date: '',
  },
  {
    deliverable_id: 141,
    module_id: 358,
    module_name: 'rty rtyrty',
    status: 'IN_PROGRESS',
    status_label: 'In Progress',
    reference_date: '2020-05-12',
    start_date: moment('2018-09-19'),
    deadline: moment('2018-09-26'),
    commenced_date: '',
    completion_date: '',
  },
  {
    deliverable_id: 141,
    module_id: 358,
    module_name: 'rty rtyrty',
    status: 'IN_PROGRESS',
    status_label: 'In Progress',
    reference_date: '2020-05-12',
    start_date: moment('2018-09-19'),
    deadline: moment('2018-09-26'),
    commenced_date: '',
    completion_date: '',
  },
];

const GetAvatar = ({ src }) => {
  return <Avatar size="xsmall" type="round" src={`${getImageURL(src)}`} />;
};

const GanttChartTable = ({ data, entities }) => {
  const dataArry = [];
  const groupArray = [];
  let { path, url } = useRouteMatch();

  //const dateTest = data.deliverable.startDate;

  const ownerId = id => {
    return typeof entities.modules[id] == 'undefined' ? null : entities.modules[id].data.deliverable.ownerId;
  };

  const startDate = id => {
    return typeof entities.modules[id] == 'undefined' ? moment() : entities.modules[id].data.deliverable.startDate;
  };

  const endDate = id => {
    return typeof entities.modules[id] == 'undefined' ? moment() : entities.modules[id].data.deliverable.deadline;
  };

  const statusColor = id => {
    const status =
      typeof entities.modules[id] == 'undefined' ? 'purple' : entities.modules[id].data.deliverable.status.name;

    switch (status) {
      case 'IN_PROGRESS':
        return '#13bee6';
      case 'COMPLETED':
        return '#22c704';
      case 'NOT_STARTED':
        return '#ffa446';
      case 'BLOCKED':
        return '#ff4141';
      case 'VERIFIED_COMPLETE':
        return '#027951';
      default:
        return '#999';
    }

    return 'red';
  };

  const statusTitle = id => {
    const status =
      typeof entities.modules[id] == 'undefined' ? 'purple' : entities.modules[id].data.deliverable.status.name;

    switch (status) {
      case 'IN_PROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'NOT_STARTED':
        return 'Not Started';
      case 'BLOCKED':
        return 'Blocked';
      case 'VERIFIED_COMPLETE':
        return 'Verified Complete';
      default:
        return 'no status';
    }

    return 'red';
  };

  data.children.map(data => {
    return dataArry.push({
      id: data.id,
      group: data.id,
      title: statusTitle(data.id),
      tip: 'additional information',
      bgColor: statusColor(data.id),
      color: statusColor(data.id),
      selectedBgColor: '#666',
      //start_time: moment(),
      //      //start_time: module[data.id].data.deliverable.startDate  moment(),
      owner_id: ownerId(data.id),
      start_time: moment(startDate(data.id)),
      end_time: moment(endDate(data.id)),
    });
  });

  data.children.map(data => {
    return groupArray.push({
      id: data.id,
      title: data.name,
      height: 40,
    });
  });

  const defaultTimeStart = moment()
    .startOf('day')
    .add(-6, 'day')
    .toDate();
  const defaultTimeEnd = moment()
    .startOf('day')
    .add(25, 'day')
    .toDate();

  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected ? (itemContext.dragging ? 'red' : item.selectedBgColor) : item.bgColor;
    const borderColor = itemContext.resizing ? 'red' : item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            //backgroundColor: 'green',

            color: '#fff',
            borderColor,
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: 2,
            borderLeftWidth: itemContext.selected ? 8 : 0,
            borderRightWidth: itemContext.selected ? 8 : 0,
          },
          onMouseDown: () => {
            console.log('on item click', item);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Box>
            <GetAvatar src={`${baseURL}/api/avatar/view/${itemContext.owner_id}`} size={'xsmall'} />
            {itemContext.title}
          </Box>
          <p className="tip">{itemContext.tip}</p>
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  const handleItemResize = (itemId, time, edge) => {
    /*const { items } = this.state;
    
    this.setState({
      items: items.map(item =>
        item.id === itemId
          ? Object.assign({}, item, {
              start: edge === "left" ? time : item.start,
              end: edge === "left" ? item.end : time
            })
          : item
      ),
      draggedItem: undefined
    });*/

    console.log('Resized', itemId, time, edge);
  };

  return (
    <>
      <Box justifyContent={'space-between'} style={{ marginBottom: '12px' }}>
        <div>{createLinks(data)}</div>
        <Box style={{ fontSize: '12px', alignItems: 'center' }} justifyContent={'flex-end'}>
          <div>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                background: 'blue',
                width: '4px',
                margin: '0 4px 0 8px',
              }}
            ></span>
            Start Date
          </div>
          <div>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                background: 'orange',
                width: '4px',
                margin: '0 4px 0 8px',
              }}
            ></span>
            Commenced
          </div>

          <div>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                background: 'deeppink',
                width: '4px',
                margin: '0 4px 0 8px',
              }}
            ></span>
            Today
          </div>
          <div>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                background: 'green',
                width: '4px',
                margin: '0 4px 0 8px',
              }}
            ></span>
            Deadline
          </div>

          <div>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                background: 'purple',
                width: '4px',
                margin: '0 4px 0 8px',
              }}
            ></span>
            Completion
          </div>
        </Box>
        <Box justifyContent={'flex-end'}>
          <Buttongroup>
            <Button size={'small'} as={Link} to={`${url.substr(0, url.lastIndexOf('/'))}`}>
              List
            </Button>
            <Button color={'primary'} size={'small'} as={Link} to={url.substr(0, url.lastIndexOf('/'))}>
              Gantt
            </Button>
          </Buttongroup>
        </Box>
      </Box>
      <StyledTimeline style={{ border: '1px solid #ddd', position: 'absolute', left: '0', right: '0' }}>
        <Timeline
          groups={groupArray}
          items={dataArry}
          //items={items}
          //keys={keys}
          itemRenderer={itemRenderer}
          onMouseDown={() => alert('')}
          // onItemClick={() => alert('')}
          sidebarContent={<div>Above The Left</div>}
          itemsSorted
          itemTouchSendsClick={false}
          stackItems
          itemHeightRatio={0.75}
          showCursorLine
          canMove={true}
          canResize={true}
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
          onItemResize={handleItemResize}
        >
          <TimelineMarkers>
            <TodayMarker />
            <CustomMarker date={moment(data.deliverable.startDate)}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'blue',
                  width: '4px',
                };
                return <div style={customStyles} /*onClick={someCustomHandler}*/ />;
              }}
            </CustomMarker>
            <CustomMarker date={moment()}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'deeppink',
                  width: '4px',
                };
                return <div style={customStyles} /*onClick={someCustomHandler}*/ />;
              }}
            </CustomMarker>
            <CustomMarker date={moment(moment(data.deliverable.deadline))}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'green',
                  width: '4px',
                };
                return <div style={customStyles} /*onClick={someCustomHandler}*/ />;
              }}
            </CustomMarker>

            <CustomMarker date={moment(moment(data.deliverable.commencedDate))}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'orange',
                  width: '4px',
                };
                return <div style={customStyles} /*onClick={someCustomHandler}*/ />;
              }}
            </CustomMarker>

            <CustomMarker date={moment(moment(data.deliverable.completionDate))}>
              {({ styles, date }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: 'purple',
                  width: '4px',
                };
                return <div style={customStyles} /*onClick={someCustomHandler}*/ />;
              }}
            </CustomMarker>
          </TimelineMarkers>
          <TimelineHeaders className="sticky">
            <SidebarHeader>
              {({ getRootProps }) => {
                return <div {...getRootProps()}></div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      </StyledTimeline>
    </>
  );
};

const GanttChart = ({ module, moduleID, ...props }) => {
  return (
    <div style={{ position: 'relative' }}>
      {props.isFetching ? 'coming' : <GanttChartTable data={props.data} entities={props.entities} />}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    module: { id },
  } = ownProps;

  const { selectedSubreddit, entities, entities: modules } = state;
  const { isFetching, data } = entities.modules[id] || {
    isFetching: true,
    items: [],
  };

  return {
    entities,
    modules,
    data,
    selectedSubreddit,
    isFetching,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GanttChart);

const createLinks = data => {
  {
    return typeof data.links == 'undefined'
      ? null
      : Object.keys(data.links).map(function(key) {
          switch (data.links[key].access) {
            case 'create':
              return (
                <ModuleAdd
                  module={data}
                  type={key}
                  trigger={<Button size={'small'}>Add {data.links[key].label}</Button>}
                />
              );
          }
        });
  }
};
